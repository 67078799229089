import React, { useContext, useEffect, useState } from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import classNames from 'classnames';
import AppContext from 'context/Context';
import Logo from 'components/common/Logo';
import { navbarBreakPoint, topNavbarBreakpoint } from 'config';
import TopNavRightSideNavItem from './TopNavRightSideNavItem';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { menuHeaderTreeAPI } from 'actions/SecurityMenuTree/SecurityMenuTreeAction';
import { getMenuTree, isLoggedIn } from 'helpers/utils';

const NavbarTop = () => {
  const dispatch = useDispatch();
  const [menuHeaderList, setMenuHeaderList] = useState("");
  const EncryptedSecurityUserId = localStorage.getItem('EncryptedSecurityUserId');
  const {
    config: { showBurgerMenu, navbarPosition, navbarCollapsed },
    setConfig
  } = useContext(AppContext);

  const [showDropShadow, setShowDropShadow] = useState(false);
  let token = localStorage.getItem('Token') || sessionStorage.getItem('Token');

  const handleBurgerMenu = () => {
    navbarPosition === 'top' && setConfig('navbarCollapsed', !navbarCollapsed);
    (navbarPosition === 'vertical' || navbarPosition === 'combo') &&
      setConfig('showBurgerMenu', !showBurgerMenu);
  };

  const setDropShadow = () => {
    const el = document.documentElement;
    if (el.scrollTop > 0) {
      setShowDropShadow(true);
    } else {
      setShowDropShadow(false);
    }
  };

  useEffect(() => {
    isLoggedIn();
    if(window.href ==="/"){
      localStorage.setItem('MenuHeaderId',0)
    }
    MenuHeaderTreeAPI()
    window.addEventListener('scroll', setDropShadow);
    return () => window.removeEventListener('scroll', setDropShadow);
  }, []);

  const MenuHeaderTreeAPI = () => {
    const headers = {
      Authorization: `Bearer ${JSON.parse(token).value}`
    };
    dispatch(menuHeaderTreeAPI(EncryptedSecurityUserId,
      (res) => {
        setMenuHeaderList(res.data.data)
      },
      (err) => {
        toast.error(err?.data.message, {
          theme: 'colored',
          autoClose: 10000
        });
        setMenuHeaderList([])
      },
      headers
    ))
  }

  return (
    <Navbar
      className={classNames('  fs--1 navbar-top sticky-kit default-navbar', {
        // 'navbar-glass-shadow': showDropShadow
        'navbar-glass-shadow': showDropShadow
      })}
      expand={
        navbarPosition === 'top' || navbarPosition === 'combo'
          ? topNavbarBreakpoint
          : true
      }
      // style={{ 'display': 'none' }}
    >
      <Navbar.Toggle
        className={classNames('toggle-icon-wrapper me-md-3 me-2', {
          'd-lg-none': navbarPosition === 'top',
          [`d-${navbarBreakPoint}-none`]:
            navbarPosition === 'vertical' || navbarPosition === 'combo'
        })}
        as="div"
      >
        <button
          className="navbar-toggler-humburger-icon btn btn-link d-flex flex-center"
          onClick={handleBurgerMenu}
          id="burgerMenu"
        >
          <span className="navbar-toggle-icon">
            <span className="toggle-line" />
          </span>
        </button>
      </Navbar.Toggle>

      <Logo at="navbar-top" width={40} />

      {navbarPosition === 'top' || navbarPosition === 'combo' ? (
        <Navbar.Collapse
          in={navbarCollapsed}
          className="scrollbar pb-3 pb-lg-0"
        >
        </Navbar.Collapse>
      ) : (
        <Nav
        navbar
        className={`align-items-center d-${topNavbarBreakpoint}-block`}
        as="ul"
      >
        <div className='d-flex flex-wrap text-aligns-center menu-header-bg '>
          {menuHeaderList.length > 0 && menuHeaderList.map((data, index) => {
            return (
              <div key={index} className='menu-header'>
                <Link
                  variant=""
                  size="sm"
                  className="menu-header-link text-center"
                  type="button"
                  to={data.menuItemPageURL}
                  onClick={() => getMenuTree(data.childId)}
                >
                  {data.menuHeaderName}
                </Link>
              </div>
            )
          })}
        </div>
      </Nav>
      
      )}
      {/* <TopNavRightSideNavItem /> */}
    </Navbar>
  );
};

export default NavbarTop;
