import { DELETE_ADVOCATECLIENTCASE_FAILURE } from "actions/AdvocateClientCase/AdvocateClientCaseAction";
import {
    ADD_COUNTRY_MASTER_REQUEST,
    ADD_COUNTRY_MASTER_SUCCESS,
    ADD_COUNTRY_MASTER_FAILURE,
    COUNTRY_MASTER_LIST_REQUEST,
    COUNTRY_MASTER_LIST_SUCCESS,
    COUNTRY_MASTER_LIST_FAILURE,
    UPDATE_COUNTRY_MASTER_REQUEST,
    UPDATE_COUNTRY_MASTER_SUCCESS,
    UPDATE_COUNTRY_MASTER_FAILURE,
    DELETE_COUNTRY_MASTER_REQUEST,
    DELETE_COUNTRY_MASTER_SUCCESS,
    DELETE_COUNTRY_MASTER_FAILURE
} from "../../actions/CountryMaster/countryMasterAction";
const initialState = {
    countryList: [],
    countryDetail: {},
    countryData: {},
    countryDeleted:false
};

export default function countryMasterReducer(state = initialState, action) {
    switch (action.type) {

        case COUNTRY_MASTER_LIST_REQUEST:
            return {
                ...state,
            };
        case COUNTRY_MASTER_LIST_SUCCESS:
            return {
                ...state,
                countryList: action.payload
            };
        case COUNTRY_MASTER_LIST_FAILURE:
            return {
                ...state,
                error: action.payload
            };
            case ADD_COUNTRY_MASTER_REQUEST:
                return {
                    ...state,
                };
            case ADD_COUNTRY_MASTER_SUCCESS:
                return {
                    ...state,
                    countryData: action.payload
                };
            case ADD_COUNTRY_MASTER_FAILURE:
                return {
                    ...state,
                    error: action.payload
                };
                // case UPDATE_COUNTRY_MASTER_REQUEST:
                //     return {
                //         ...state,
                //     };
                // case UPDATE_COUNTRY_MASTER_SUCCESS:
                //     return {
                //         ...state,
                //         countryDetail: action.payload
                //     };
                // case UPDATE_COUNTRY_MASTER_FAILURE:
                //     return {
                //         ...state,
                //         error: action.payload
                //     };
                    case DELETE_COUNTRY_MASTER_REQUEST:
                        return {
                          ...state
                        };
                      case DELETE_COUNTRY_MASTER_SUCCESS:
                        return {
                          ...state        
                        };
                      case DELETE_COUNTRY_MASTER_FAILURE:
                        return {
                          ...state,
                          error: action.payload
                        };
                    case 'DELETE_COUNTRY_SUCCESS':
                        return {
                          ...state,
                          countryDeleted: true
                        };
                      case 'RESET_COUNTRY_DELETED':
                        return {
                          ...state,
                          countryDeleted: false
                        };
        case 'COUNTRYDETAIL':
            if (!action.payload) {
                return initialState;
            } else {
                return {
                    ...state,
                    countryDetail: action.payload
                };
            }
        case 'COUNTRYDATA':
            if (!action.payload) {
                return initialState;
            } else {
                return {
                    ...state,
                    countryData: action.payload
                };
            }
        default:
            return state;
    }
}