import request from "components/Utils/Request";
const { REACT_APP_API_URL } = process.env;
export const ADVOCATE_DEALING_REQUEST = "ADVOCATE_DEALING_REQUEST";
export const ADVOCATE_DEALING_SUCCESS = "ADVOCATE_DEALING_SUCCESS";
export const ADVOCATE_DEALING_FAILURE = "ADVOCATE_DEALING_FAILURE";
export const ADVOCATE_DEALING_LIST_REQUEST = "ADVOCATE_DEALING_LIST__REQUEST";
export const ADVOCATE_DEALING_LIST_SUCCESS = "ADVOCATE_DEALING_LIST__SUCCESS";
export const ADVOCATE_DEALING_LIST_FAILURE = "ADVOCATE_DEALING_LIST__FAILURE";
export const DELETE_ADVOCATE_DEALING_REQUEST = "DELETE_ADVOCATE_DEALING_REQUEST";
export const DELETE_ADVOCATE_DEALING_SUCCESS = "DELETE_ADVOCATE_DEALING_SUCCESS";
export const DELETE_ADVOCATE_DEALING_FAILURE = "DELETE_ADVOCATE_DEALING_FAILURE";
export const UPDATE_ADVOCATE_DEALING_REQUEST = "UPDATE_ADVOCATE_DEALING_REQUEST";
export const UPDATE_ADVOCATE_DEALING_SUCCESS = "UPDATE_ADVOCATE_DEALING_SUCCESS";
export const UPDATE_ADVOCATE_DEALING_FAILURE = "UPDATE_ADVOCATE_DEALING_FAILURE";

export const advocateDealingRequest = () => ({
    type: ADVOCATE_DEALING_REQUEST
});
export const advocateDealingSuccess = (payload) => ({
    type: ADVOCATE_DEALING_SUCCESS,
    payload
});
export const advocateDealingFailure = (payload) => ({
    type: ADVOCATE_DEALING_FAILURE,
    payload
})

// update
export const updateAdvocateDealingRequest = () => ({
    type: UPDATE_ADVOCATE_DEALING_REQUEST
});
export const updateAdvocateDealingSuccess = (payload) => ({
    type: UPDATE_ADVOCATE_DEALING_SUCCESS,
    payload
});
export const updateAdvocateDealingFailure = (payload) => ({
    type: UPDATE_ADVOCATE_DEALING_FAILURE,
    payload
})


// advocate-dealing-list
export const advocateDealingListRequest = () => ({
    type: ADVOCATE_DEALING_LIST_REQUEST
});
export const advocateDealingListSuccess = (payload) => ({
    type: ADVOCATE_DEALING_LIST_SUCCESS,
    payload
});
export const advocateDealingListFailure = (payload) => ({
    type: ADVOCATE_DEALING_LIST_FAILURE,
    payload
})

// delete api
export const deleteAdvocateDealingRequest = () => ({
    type: DELETE_ADVOCATE_DEALING_REQUEST
});
export const deleteAdvocateDealingSuccess = (payload) => ({
    type: DELETE_ADVOCATE_DEALING_SUCCESS,
    payload
});
export const deleteAdvocateDealingFailure = (payload) => ({
    type: DELETE_ADVOCATE_DEALING_FAILURE,
    payload
})

export const advocateDealingDetailAction = payload => {
    return {
      type: 'ADVOCATEDEALINGDATA',
      payload
    };
  };
  
  export const advocateDealingDetailsErrorAction = payload => {
    return {
      type: 'ADVOCATEDEALINGDETAILSERROR',
      payload
    };
  };


export const AddAdvocateDealingAPI = (payload, successData, errorMsg,headers = {}) => {
    return (dispatch) => {
        dispatch(advocateDealingRequest());
        return request("POST", `${REACT_APP_API_URL + "/add-advocate-dealing"}`, payload,"",
            headers)
            .then((response) => {
                if (response?.data?.status === 200) {
                    successData(response);
                    dispatch(advocateDealingSuccess(response));
                } else {
                    errorMsg(response);
                }

            }).catch((error) => {
                errorMsg(error?.response);
                dispatch(advocateDealingFailure("Something Went Wrong"));
                throw error;
            });
    };
};


export const updateAdvocateDealingAPI = (payload, successData, errorMsg,headers = {}) => {
    return (dispatch) => {
        dispatch(updateAdvocateDealingRequest());
        return request("POST", `${REACT_APP_API_URL + "/update-advocate-dealing"}`, payload,"",
            headers)
            .then((response) => {
                if (response?.data?.status === 200) {
                    successData(response);
                    dispatch(updateAdvocateDealingSuccess(response));
                } else {
                    errorMsg(response);
                }

            }).catch((error) => {
                errorMsg(error?.response);
                dispatch(updateAdvocateDealingFailure("Something Went Wrong"));
                throw error;
            });
    };
};


export const advocateDealingListAPI = (payload, successData, errorMSg,headers = {}) => {
    return (dispatch) => {
        dispatch(advocateDealingListRequest());
        const url = `${REACT_APP_API_URL}/advocate-dealing-list`;
        return request("POST", url, payload,"",headers)
            .then((res) => {
                if (res?.data.status === 200) {
                    var data = res?.data.data.advocateDealingList;
                    successData(res)
                    dispatch(advocateDealingListSuccess(data));
                } else {
                    errorMSg(res)
                }
            }).catch((err) => {
                errorMSg(err?.res);
                dispatch(advocateDealingListFailure("Something went wrong"));
                throw err;
            })
    }
}

export const deleteAdvocateDealingAPI = (payload, successData, errorMsg, headers = {}) => {
    return dispatch => {
      dispatch(deleteAdvocateDealingRequest());
      return request(
        'DELETE',
        `${REACT_APP_API_URL + '/delete-advocate-dealing'}`,
        payload,
        "",
        headers
      )
        .then(res => {
          if (res?.data.status === 200) {
            successData(res);
            dispatch(deleteAdvocateDealingSuccess(res));
          } else {
            errorMsg(res);
          }
        })
        .catch(err => {
          errorMsg(err?.res);
          dispatch(deleteAdvocateDealingFailure('Something went wrong'));
          throw err;
        });
    };
  };