import {
  MENU_HEADER_TREE_REQUEST,
  MENU_HEADER_TREE_SUCCESS,
  MENU_HEADER_TREE_FAILURE,
  ADVOCATE_USER_MENU_TREE_REQUEST,
  ADVOCATE_USER_MENU_TREE_SUCCESS,
  ADVOCATE_USER_MENU_TREE_FAILURE,
  ADD_MENU_TREE_DETAILS_REQUEST,
  ADD_MENU_TREE_DETAILS_SUCCESS,
  ADD_MENU_TREE_DETAILS_FAILURE,
  GET_COMMAND_KEY_COMBINATION_LIST_FAILURE,
  GET_COMMAND_KEY_COMBINATION_LIST_REQUEST,
  GET_COMMAND_KEY_COMBINATION_LIST_SUCCESS
} from '../../actions/SecurityMenuTree/SecurityMenuTreeAction';
const initialState = {
  headerMenu: [],
  advocateUserMenuTreeList: [],
  menuTreeList:[],
  menuDetails:{},
  keyCombinationList:[]
};

export default function SecurityMenuMenuTreeReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case MENU_HEADER_TREE_REQUEST:
      return {
        ...state
      };
    case MENU_HEADER_TREE_SUCCESS:
      return {
        ...state,
        headerMenu: action.payload
      };
    case MENU_HEADER_TREE_FAILURE:
      return {
        ...state,
        error: action.payload
      };
    case ADVOCATE_USER_MENU_TREE_REQUEST:
      return {
        ...state
      };
    case ADVOCATE_USER_MENU_TREE_SUCCESS:
      return {
        ...state,
        menuTreeList: action.payload
      };
    case ADVOCATE_USER_MENU_TREE_FAILURE:
      return {
        ...state,
        error: action.payload
      };
      case ADD_MENU_TREE_DETAILS_REQUEST:
        return {
          ...state
        };
      case ADD_MENU_TREE_DETAILS_SUCCESS:
        return {
          ...state,
          menuDetails: action.payload
        };
      case ADD_MENU_TREE_DETAILS_FAILURE:
        return {
          ...state,
          error: action.payload
        };
        case GET_COMMAND_KEY_COMBINATION_LIST_REQUEST:
          return {
            ...state
          };
        case GET_COMMAND_KEY_COMBINATION_LIST_SUCCESS:
          return {
            ...state,
            keyCombinationList: action.payload
          };
        case GET_COMMAND_KEY_COMBINATION_LIST_FAILURE:
          return {
            ...state,
            error: action.payload
          };
      case 'MENUDETAILS':
        if(!action.payload) {
          return initialState
        }
        else{
          return{
            ...state,
            menuDetails : action.payload
          }
        }
        case 'SECURITYMENUTREEMASTERLIST':
          if(!action.payload) {
            return initialState
          }
          else{
            return{
              ...state,
              menuTreeList : action.payload
            }
          }
    default:
      return state;
  }
}
