
import {
    ADD_COURT_TYPE_MASTER_REQUEST,
    ADD_COURT_TYPE_MASTER_SUCCESS,
    ADD_COURT_TYPE_MASTER_FAILURE,
    UPDATE_COURT_TYPE_MASTER_REQUEST,
    UPDATE_COURT_TYPE_MASTER_SUCCESS,
    UPDATE_COURT_TYPE_MASTER_FAILURE,
    GET_COURT_TYPE_MASTER_LIST_REQUEST,
    GET_COURT_TYPE_MASTER_LIST_SUCCESS,
    GET_COURT_TYPE_MASTER_LIST_FAILURE,

} from "../../actions/CourtTypeMaster/courtTypeMasterAction";
const initialState = {
    courtTypeList: [],
    courtTypeDetail: {},
    courtTypeData: {},
};

export default function courtTypeMasterReducer(state = initialState, action) {
    switch (action.type) {

        case GET_COURT_TYPE_MASTER_LIST_REQUEST:
            return {
                ...state,
            };
        case GET_COURT_TYPE_MASTER_LIST_SUCCESS:
            return {
                ...state,
                courtTypeList: action.payload
            };
        case GET_COURT_TYPE_MASTER_LIST_FAILURE:
            return {
                ...state,
                error: action.payload
            };

            case ADD_COURT_TYPE_MASTER_REQUEST:
                return {
                    ...state,
                };
            case ADD_COURT_TYPE_MASTER_SUCCESS:
                return {
                    ...state,
                    courtTypeData: action.payload
                };
            case ADD_COURT_TYPE_MASTER_FAILURE:
                return {
                    ...state,
                    error: action.payload
                };
                case UPDATE_COURT_TYPE_MASTER_REQUEST:
                    return {
                        ...state,
                    };
                case UPDATE_COURT_TYPE_MASTER_SUCCESS:
                    return {
                        ...state,
                        courtTypeData: action.payload
                    };
                case UPDATE_COURT_TYPE_MASTER_FAILURE:
                    return {
                        ...state,
                        error: action.payload
                    };
        case 'COURTTYPEMASTERDETAIL':
            if (!action.payload) {
                return initialState;
            } else {
                return {
                    ...state,
                    courtTypeDetail: action.payload
                };
            }
        case 'COURTTYPEDATA':
            if (!action.payload) {
                return initialState;
            } else {
                return {
                    ...state,
                    courtTypeData: action.payload
                };
            }
        default:
            return state;
    }
}