import React from 'react';
import { useEffect, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import EnlargableTextbox from 'components/common/EnlargableTextbox';
import { toast } from 'react-toastify';
import { clientDetailAPI, clientListAPI } from 'actions/ClientRegistration/ClientRegistrationAction';
import FalconComponentCard from 'components/common/FalconComponentCard';
import { advocateClientCaseDataAction } from 'actions/AdvocateClientCase/AdvocateClientCaseAction';
import { caseTypeMasterListAPI } from 'actions/CaseTypeMaster/caseTypeMasterAction';
import { courtMasterListAPI } from 'actions/CourtMaster/courtMasterAction';
import { stateListAPI } from 'actions/StateMaster/stateMasterAction';
import { countryListAPI } from 'actions/CountryMaster/countryMasterAction';
import { formChangedAction } from 'actions';

export const AdvocateClientCaseDetail = () => {
  const dispatch = useDispatch();
  const [formHasError, setFormError] = useState(false);
  const [clientList, setClientList] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [caseTypeList, setCaseTypeList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [stateList, setStateList] = useState([]);
  const [courtMasterList, setCourtMasterList] = useState([]);
  const selectedClientCode = localStorage.getItem('ClientCode')
  const selectedCaseId = localStorage.getItem('EncryptedResponseCaseId')
  const [perPage, setPerPage] = useState(15);

  let token = localStorage.getItem('Token') || sessionStorage.getItem('Token');
  let oldCaseStatus = localStorage.getItem("OldCaseStatus");

  const headers = {
    Authorization: `Bearer ${JSON.parse(token).value}`
  };

  const resetAdvocateClientCaseData = () => {
    dispatch(advocateClientCaseDataAction({
      'encryptedCaseId': '',
      'clientName': '',
      'clientCode': '',
      'clientType': '',
      'mobileNo': '',
      'contactPerson': '',
      'emailId': '',
      'clientCaseNo': '',
      'countryCode': '',
      'country': '',
      'stateCode': '',
      'state': '',
      'courtName': '',
      'judgeName': '',
      'consultingFees': '',
      'oppAdvName': '',
      'caseTitle': '',
      'policeStation': '',
      'firNo': '',
      'firDate': '',
      'caseNature': '',
      'caseTypeCode': '',
      'oppAdvMob': '',
      'party': '',
      'caseRegisterDate': '',
      'nextHearingDate': '',
      'caseStatus': '',
      'attendantAdvocateName': '',
      'underSection': '',
      'fileDescription': '',
      'purpose': '',
      'fileNo': '',
      'roomNo': '',
      'almirah': '',
      'rack': ''
    })
    );
    setStateList([])
    setCourtMasterList([])
  };

  const advocateClientCaseReducer = useSelector(
    state => state.rootReducer.advocateClientCaseReducer
  );
  var advocateClientCaseData = advocateClientCaseReducer.advocateClientCaseData

  if (!advocateClientCaseReducer.advocateClientCaseData ||
    Object.keys(advocateClientCaseReducer.advocateClientCaseData).length <= 0) {
    resetAdvocateClientCaseData();
  }

  const advocateClientCaseDetailsErrorReducer = useSelector(
    state => state.rootReducer.advocateClientCaseDetailsErrorReducer
  );

  const caseDetailError = advocateClientCaseDetailsErrorReducer.advocateClientCaseDetailsError;

  const formChangedReducer = useSelector((state) => state.rootReducer.formChangedReducer)
  var formChangedData = formChangedReducer.formChanged;
  useEffect(() => {
    if (advocateClientCaseData.clientCode || selectedClientCode) {
      getClientDetail(advocateClientCaseData.clientCode || selectedClientCode)
      $('#txtClientCode').val(advocateClientCaseData.clientCode || selectedClientCode)
    }
  }, [advocateClientCaseData.clientCode])

  useEffect(() => {
    getCountries()
    getClientList(1)
    getCaseTypeMaster()
  }, []);

  useEffect(() => {
    if (advocateClientCaseData && advocateClientCaseData.stateCode && !$('#txtState').val()) {
      getStates(advocateClientCaseData.countryCode)
    }

    if (advocateClientCaseData.stateCode &&
      !$('#txtCourtCode').val()) {
      getCourtMasterList(advocateClientCaseData.stateCode);
    }
  }, [advocateClientCaseData]);

  const getCountries = async (page, size = perPage) => {
    const data = {
      pageNumber: page,
      pageSize: size,
      SearchText: '',
      isDropDown: true,
    };
    dispatch(
      countryListAPI(
        data,
        res => {
          let countryData = [];
          if (res.data.data.length > 0)
            res.data.data.forEach(country => {
              countryData.push({
                key: country.countryName,
                value: country.countryCode
              });
            });
          setCountryList(countryData);
        },
        err => {
          toast.error(err?.data?.message || 'Error fetching countries', {
            theme: 'colored',
            autoClose: 10000
          });
          setCountryList([]);
        },
        headers
      )
    );
  };

  const getStates = async (selectCountryCode) => {
    const data = {
      countryCode: selectCountryCode,
      isDropDown: true,
      isList: false
    }
  await dispatch(stateListAPI(
      data,
      res => {

        let stateData = [];
        if (res && res.length > 0) {
          res.forEach(state => {
            stateData.push({
              key: state.stateName,
              value: state.stateCode
            });
          });
          setStateList(stateData);
        } else {
          setStateList([]);
        }
      },
      (err) => {
        setStateList([]);
      }
    ));
  };

  const getCourtMasterList = async (selectStateCode) => {
    const data = {
      stateCode: selectStateCode
    }
  await dispatch(courtMasterListAPI(
      data,
      res => {
        let courtData = [];
        if (res.data && res.data.data.courtList.length > 0) {
          res.data.data.courtList.forEach(data => {
            courtData.push({
              key: data.courtName,
              value: data.courtCode
            });
          });
          setCourtMasterList(courtData);
        } else {
          setCourtMasterList([]);
        }
      },
      (err) => {
        setCourtMasterList([]);
      },
      headers
    ));
  };


  const getClientList = async () => {
    const data = {
      pageNumber: 1,
      pageSize: 1,
      SearchText: '',
      EncryptedAdvocateCode: localStorage.getItem('EncryptedAdvocateCode'),
      isDropDown: true
    };
   await dispatch(
      clientListAPI(
        data,
        res => {
          let clientData = [];

          if (res.data.data.clientList.length > 0)
            res.data.data.clientList.forEach(client => {
              clientData.push({
                key: client.clientName,
                value: client.clientCode
              });
            });
          setClientList(clientData);
        },
        err => {
          setClientList([]);
          console.log(err?.data.message);
        },
        headers
      )
    );
  };

  const getClientDetail = async (ClientCode) => {
    var request = {
      ClientCode: ClientCode
    }
    setIsLoading(true);
    await dispatch(
      clientDetailAPI(
        request,
        res => {
          setIsLoading(false)
          var clientDetail = res.data.data.clientDetail
          dispatch(advocateClientCaseDataAction({
            ...advocateClientCaseData,
            clientName: clientDetail.client_Name,
            emailId: clientDetail.eMail_Id,
            contactPerson: clientDetail.contact_Person,
            mobileNo: clientDetail.mobile_No,
            clientType: clientDetail.client_Type,
            ...clientDetail,
            clientCode: clientDetail.client_Code,

          }));
        },
        err => {
          setIsLoading(false)
          toast.error(err?.data?.message || 'Error fetching client', {
            theme: 'colored',
            autoClose: 10000
          });
          dispatch(advocateClientCaseDataAction(undefined));
        },
        headers
      )
    );
  };


  const getCaseTypeMaster = async () => {
    await dispatch(
      caseTypeMasterListAPI(
        null,
        res => {
          let listData = [];
          if (res.data.data.caseTypeList.length > 0)
            res.data.data.caseTypeList.forEach(data => {
              listData.push({
                key: data.caseType,
                value: data.caseTypeMasterCode
              });
            });
          setCaseTypeList(listData);
        },
        err => {
          toast.error(err?.data?.message || 'Error fetching case type list', {
            theme: 'colored',
            autoClose: 10000
          });
          setCaseTypeList([]);
        },
        headers
      )
    );
  };

  const handleFieldChange = (e) => {
    if (oldCaseStatus === "DISPOSED") {
      return;
    }

    if (e.target.name == 'clientCode') {
      dispatch(
        advocateClientCaseDataAction({
          ...advocateClientCaseData,
          clientCode: e.target.value
        })
      );
      getClientDetail(e.target.value)
    }
    if (e.target.name == 'caseTypeCode') {
      dispatch(
        advocateClientCaseDataAction({
          ...advocateClientCaseData,
          caseTypeCode: e.target.value,
        }))
    }
    if (e.target.name == "countryCode") {
      dispatch(
        advocateClientCaseDataAction({
          ...advocateClientCaseData,
          countryCode: e.target.value,
          stateCode: null,
          courtCode: null
        }))
      setStateList([]);
      setCourtMasterList([])
      e.target.value && getStates(e.target.value);
    }
    else if (e.target.name == "stateCode") {
      dispatch(
        advocateClientCaseDataAction({
          ...advocateClientCaseData,
          stateCode: e.target.value,
          courtCode: null
        }))
      setCourtMasterList([])
      e.target.value && getCourtMasterList(e.target.value);
    }
    else {
      dispatch(
        advocateClientCaseDataAction({
          ...advocateClientCaseData,
          [e.target.name]: e.target.value
        })
      );
    }
    if (advocateClientCaseData.encryptedCaseId || selectedCaseId) {
      dispatch(
        formChangedAction({
          ...formChangedData,
          AdvocateClientCaseUpdate: true
        })
      );
    } else {
      dispatch(
        formChangedAction({
          ...formChangedData,
          AdvocateClientCaseAdd: true
        })
      );
    }
  };

  return (
    <>
      {advocateClientCaseData &&
        <Form noValidate validated={formHasError} className="details-form micro-form" id='addAdvocateClientCaseDetailsForm'>
          <Row className="g-3 mb-2 row-content-pt5px">
            <Col sm={12} lg={12} className="no-pd-card">
              <FalconComponentCard className="farmer-card-row1">
                <FalconComponentCard.Header title="Client Information" light={false} />
                <FalconComponentCard.Body language="jsx">
                  <Row>
                    <Col className="me-3 ms-3">
                      {localStorage.getItem('EncryptedResponseCaseId') ? (
                        <Row className="mb-3">
                          <Form.Label>
                            Client Name
                          </Form.Label>
                          <EnlargableTextbox
                            id="txtClientName"
                            value={advocateClientCaseData.clientName}
                            name="clientName"
                            placeholder="Client Name"
                            disabled
                          />
                        </Row>)
                        : (
                          <Row className="mb-3">
                            <Form.Label>
                              Client Name<span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Select
                              id="txtClientCode"
                              name="clientCode"
                              maxLength={15}
                              value={advocateClientCaseData.clientCode}
                              onChange={handleFieldChange}
                            >
                              <option value="">Select </option>
                              {clientList.map((option, index) => (
                                <option key={index} value={option.value}>
                                  {option.key}
                                </option>
                              ))}
                            </Form.Select>
                            {Object.keys(caseDetailError.clientNameErr).map(key => {
                              return (
                                <span className="error-message">
                                  {caseDetailError.clientNameErr[key]}
                                </span>
                              );
                            })}
                          </Row>
                        )
                      }
                      <Row className="mb-3">
                        <Form.Label>
                          Contact Person
                        </Form.Label>
                        <EnlargableTextbox
                          id="contactPersonDetail"
                          maxLength={50}
                          value={advocateClientCaseData.contactPerson}
                          name="contactPerson"
                          placeholder="Contact Person"
                          disabled
                        />
                      </Row>
                    </Col>
                    <Col className="me-3 ms-3">
                      <Row className="mb-3">
                        <Form.Label>
                          Email ID
                        </Form.Label>
                        <EnlargableTextbox
                          id="txtEmailDetail"
                          name="emailId"
                          maxLength={100}
                          value={advocateClientCaseData.emailId}
                          placeholder="Email ID"
                          required
                          disabled
                        />
                      </Row>
                      <Row className="mb-3">
                        <Form.Label>
                          Mobile No.
                        </Form.Label>
                        <EnlargableTextbox
                          id="txtMobileNoDetail"
                          name="mobileNo"
                          maxLength={10}
                          value={advocateClientCaseData.mobileNo}
                          placeholder="Mobile No."
                          disabled
                        />
                      </Row>
                    </Col>
                    <Col className="me-3 ms-3">
                      <Row className="mb-3">
                        <Form.Label>
                          Client Type
                        </Form.Label>
                        <EnlargableTextbox
                          id="clientTypeDetail"
                          maxLength={50}
                          value={advocateClientCaseData.clientType}
                          name="clientType"
                          placeholder="Client Type"
                          disabled
                        />
                      </Row>
                    </Col>
                  </Row>
                </FalconComponentCard.Body>
              </FalconComponentCard>
            </Col>
          </Row>
          <Row className="g-3 mb-2 row-content-pt5px">
            <Col sm={12} lg={12} className="no-pd-card">
              <FalconComponentCard className="farmer-card-row1">
                <FalconComponentCard.Header title="Add Case Detail" light={false} />
                <FalconComponentCard.Body language="jsx">
                  <Row>
                    <Col className="me-3 ms-3">
                      <Row className="mb-3">
                        <Form.Label>
                          Case No.<span className="text-danger">*</span>
                        </Form.Label>
                        <EnlargableTextbox
                          id="txtCaseNo"
                          maxLength={30}
                          name="clientCaseNo"
                          placeholder="Case No"
                          value={advocateClientCaseData.clientCaseNo}
                          onChange={handleFieldChange}
                          required
                        />
                        {Object.keys(caseDetailError.caseNoErr).map(key => {
                          return (
                            <span className="error-message">
                              {caseDetailError.caseNoErr[key]}
                            </span>
                          );
                        })}
                      </Row>
                      <Row className="mb-3">
                        <Form.Label>
                          Country<span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Select
                          id="txtCountry"
                          name="countryCode"
                          value={advocateClientCaseData.countryCode}
                          onChange={handleFieldChange}
                          required >
                          <option value="">Select Country</option>
                          {countryList.map((option, index) => (
                            <option key={index} value={option.value}>
                              {option.key}
                            </option>
                          ))}
                        </Form.Select>
                        {Object.keys(caseDetailError.countryErr).map(key => {
                          return (
                            <span className="error-message">
                              {caseDetailError.countryErr[key]}
                            </span>
                          );
                        })}
                      </Row>
                      <Row className="mb-3">
                        <Form.Label>
                          State<span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Select
                          id="txtState"
                          name="stateCode"
                          value={advocateClientCaseData.stateCode}
                          onChange={handleFieldChange}
                        >
                          <option value="">Select State</option>
                          {stateList.map((option, index) => (
                            <option key={index} value={option.value}>
                              {option.key}
                            </option>
                          ))}
                        </Form.Select>
                        {Object.keys(caseDetailError.stateErr).map(key => {
                          return (
                            <span className="error-message">
                              {caseDetailError.stateErr[key]}
                            </span>
                          );
                        })}
                      </Row>
                      <Row className="mb-3">
                        <Form.Label>
                          Court Name<span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Select
                          id="txtCourtCode"
                          name="courtCode"
                          value={advocateClientCaseData.courtCode}
                          onChange={handleFieldChange}
                          required
                        >
                          <option value="">Select Court Name</option>
                          {courtMasterList.map((option, index) => (
                            <option key={index} value={option.value}>
                              {option.key}
                            </option>
                          ))}
                        </Form.Select>
                        {Object.keys(caseDetailError.courtNameErr).map(key => {
                          return (
                            <span className="error-message">
                              {caseDetailError.courtNameErr[key]}
                            </span>
                          );
                        })}
                      </Row>
                      <Row className="mb-3">
                        <Form.Label>
                          Court No.
                        </Form.Label>
                        <EnlargableTextbox
                          id="courtNo"
                          maxLength={10}
                          name="courtNo"
                          placeholder="Court No"
                          value={advocateClientCaseData.courtNo}
                          onChange={handleFieldChange}
                        />
                      </Row>
                      <Row className="mb-3">
                        <Form.Label>
                          Judge Name
                        </Form.Label>
                        <EnlargableTextbox
                          id="judgeName"
                          maxLength={50}
                          name="judgeName"
                          placeholder="Judge Name"
                          value={advocateClientCaseData.judgeName}
                          onChange={handleFieldChange}
                        />
                      </Row>
                      <Row className="mb-3">
                        <Form.Label>
                          Consulting Fees
                        </Form.Label>
                        <EnlargableTextbox
                          id="txtConsultingFees"
                          name="consultingFees"
                          value={advocateClientCaseData.consultingFees}
                          onChange={handleFieldChange}
                          placeholder="0.00"
                          onKeyPress={(e) => {
                            const key = String.fromCharCode(e.charCode);
                            const currentValue = e.target.value;
                            if (key === '.' && currentValue.includes('.')) {
                              e.preventDefault();
                            }
                            const regex = /^[0-9\.]+$/;
                            if (!regex.test(key)) {
                              e.preventDefault();
                            }
                          }}
                        />
                      </Row>
                      <Row className="mb-3">
                        <Form.Label>
                          Opp Adv Name
                        </Form.Label>
                        <EnlargableTextbox
                          id="txtOppAdvName"
                          maxLength={50}
                          name="oppAdvName"
                          placeholder="Opp Adv Name"
                          value={advocateClientCaseData.oppAdvName}
                          onChange={handleFieldChange}
                        />
                      </Row>
                    </Col>
                    <Col className="me-3 ms-3">
                      <Row className="mb-3">
                        <Form.Label>
                          Case Title<span className="text-danger">*</span>
                        </Form.Label>
                        <EnlargableTextbox
                          id="txtCaseTitle"
                          maxLength={100}
                          name="caseTitle"
                          placeholder="Case Title"
                          value={advocateClientCaseData.caseTitle}
                          onChange={handleFieldChange}
                        />
                        {Object.keys(caseDetailError.caseTitleErr).map(key => {
                          return (
                            <span className="error-message">
                              {caseDetailError.caseTitleErr[key]}
                            </span>
                          );
                        })}
                      </Row>
                      <Row className="mb-3">
                        <Form.Label>
                          Police Station
                        </Form.Label>
                        <EnlargableTextbox
                          id="txtPoliceStation"
                          maxLength={30}
                          name="policeStation"
                          placeholder="Police Station"
                          value={advocateClientCaseData.policeStation}
                          onChange={handleFieldChange}
                        />
                      </Row>
                      <Row className="mb-3">
                        <Form.Label>
                          FIR No.
                        </Form.Label>
                        <EnlargableTextbox
                          id="txtFirNo"
                          maxLength={30}
                          name="firNo"
                          placeholder="FIR No"
                          value={advocateClientCaseData.firNo}
                          onChange={handleFieldChange}
                        />
                      </Row>
                      <Row className="mb-3">
                        <Form.Label>FIR Date</Form.Label>
                        <Form.Control type='date' id="dtFIRDate" name="firDate"
                          value={advocateClientCaseData.firDate}
                          onChange={handleFieldChange} />
                      </Row>
                      <Row className="mb-3">
                        <Form.Label>
                          Case Nature
                        </Form.Label>
                        <Form.Select
                          id="txtCaseNature"
                          name="caseNature"
                          value={advocateClientCaseData.caseNature}
                          onChange={handleFieldChange}
                        >
                          <option value="">Select Case Nature</option>
                          <option value="CRIMINAL" selected>CRIMINAL</option>
                          <option value="CIVIL">CIVIL</option>
                          <option value="COMPANY MATTER">COMPANY MATTER</option>
                          <option value="CONSUMER COMPLAINT">CONSUMER COMPLAINT</option>
                          <option value="REVENUE">REVENUE</option>
                          <option value="SERVICE">SERVICE</option>
                          <option value="TAXATION">TAXATION</option>
                        </Form.Select>
                      </Row>
                      <Row className="mb-3">
                        <Form.Label>
                          Case Type<span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Select
                          id="txtCaseType"
                          name="caseTypeCode"
                          value={advocateClientCaseData.caseTypeCode}
                          onChange={handleFieldChange}
                          required
                        >
                          <option value="">Select Case Type</option>
                          {caseTypeList.map((option, index) => (
                            <option key={index} value={option.value}>
                              {option.key}
                            </option>
                          ))}
                        </Form.Select>
                        {Object.keys(caseDetailError.caseTypeErr).map(key => {
                          return (
                            <span className="error-message">
                              {caseDetailError.caseTypeErr[key]}
                            </span>
                          );
                        })}
                      </Row>
                      <Row className="mb-3">
                        <Form.Label>
                          Opp Adv Mob
                        </Form.Label>
                        <EnlargableTextbox
                          id="txtOppAdvMob"
                          name="oppAdvMob"
                          maxLength={10}
                          placeholder="Opp Adv Mob"
                          value={advocateClientCaseData.oppAdvMob}
                          onChange={handleFieldChange}
                        />
                        {Object.keys(caseDetailError.addvocateMobileNoErr).map(key => {
                          return (
                            <span className="error-message">
                              {caseDetailError.addvocateMobileNoErr[key]}
                            </span>
                          );
                        })}
                      </Row>
                    </Col>
                    <Col className="me-3 ms-3">
                      <Row className="mb-3">
                        <Form.Label>
                          Client
                        </Form.Label>
                        <Form.Select
                          id="txtClient"
                          name="party"
                          value={advocateClientCaseData.party}
                          onChange={handleFieldChange}>
                          <option value="">Select </option>
                          <option value="Accused">Accused </option>
                          <option value="Defendant">Defendant </option>
                          <option value="Petitioner">Petitioner </option>
                          <option value="Plaintiff">Plaintiff </option>
                          <option value="Respondents">Respondents </option>
                        </Form.Select>
                      </Row>
                      <Row className="mb-3">
                        <Form.Label>Register Date<span className="text-danger">*</span></Form.Label>
                        <Form.Control type='date' id="txtRegisterDate" name="caseRegisterDate"
                          value={advocateClientCaseData.caseRegisterDate}
                          onChange={handleFieldChange}
                          required
                        />
                        {Object.keys(caseDetailError.registerDateErr).map((key) => {
                          return <span className="error-message">{caseDetailError.registerDateErr[key]}</span>
                        })}
                      </Row>
                      <Row className="mb-3">
                        <Form.Label> Next Hearing Date<span className="text-danger">*</span></Form.Label>
                        <Form.Control type='date' id="txtNextHearingDate" name="nextHearingDate"
                          value={advocateClientCaseData.nextHearingDate}
                          onChange={handleFieldChange}
                          required
                        />
                        {Object.keys(caseDetailError.nextHearingDateErr).map((key) => {
                          return <span className="error-message">{caseDetailError.nextHearingDateErr[key]}</span>
                        })}
                      </Row>
                      <Row className="mb-3">
                        <Form.Label>
                          Case Status
                        </Form.Label>
                        <Form.Select
                          id="txtCaseStatus"
                          name="caseStatus"
                          value={advocateClientCaseData.caseStatus}
                          onChange={handleFieldChange}
                        >
                          <option value="ONGOING" selected>ONGOING </option>
                          <option value="WON">WON </option>
                          <option value="LOST">LOST </option>
                          <option value="CLOSED">CLOSED </option>
                          <option value="DISPOSED">DISPOSED </option>
                        </Form.Select>
                      </Row>
                      <Row className="mb-3">
                        <Form.Label>
                          Attendant Advocate</Form.Label>
                        <EnlargableTextbox
                          id="txtAttendantAdvocate"
                          name="attendantAdvocateName"
                          maxLength={50}
                          placeholder="Attendant Advocate"
                          value={advocateClientCaseData.attendantAdvocateName}
                          onChange={handleFieldChange}
                        />
                      </Row>
                      <Row className="mb-3">
                        <Form.Label>
                          Under Section</Form.Label>
                        <EnlargableTextbox
                          id="txtUnderSection"
                          name="underSection"
                          maxLength={30}
                          placeholder="Under Section"
                          value={advocateClientCaseData.underSection}
                          onChange={handleFieldChange}
                        />
                      </Row>
                      <Row className="mb-3">
                        <Form.Label>
                          File Desc   </Form.Label>
                        <EnlargableTextbox
                          id="txtFileDesc"
                          name="fileDescription"
                          maxLength={50}
                          placeholder="File Desc"
                          value={advocateClientCaseData.fileDescription}
                          onChange={handleFieldChange}
                        />
                      </Row>
                    </Col>
                    <Col className="me-3 ms-3">
                      <Row className="mb-3">
                        <Form.Label>
                          Purpose
                        </Form.Label>
                        <Form.Select
                          id="txtPurpose"
                          name="purpose"
                          value={advocateClientCaseData.purpose}
                          onChange={handleFieldChange}
                        >
                          <option value="">Select </option>
                          <option value="Admission">Admission</option>
                          <option value="Appearance">Appearance </option>
                          <option value="Argument">Argument</option>
                          <option value="Charge/Issue">Charge/Issue </option>
                          <option value="Evidence">Evidence </option>
                          <option value="Misc">Misc</option>
                          <option value="Rejoinder">Rejoinder </option>
                          <option value="Reply">Reply </option>
                        </Form.Select>
                      </Row>
                      <Row className="mb-3">
                        <Form.Label>
                          File No.</Form.Label>
                        <EnlargableTextbox
                          id="txtFileNo"
                          name="fileNo"
                          maxLength={20}
                          placeholder="File No"
                          value={advocateClientCaseData.fileNo}
                          onChange={handleFieldChange}
                        />
                      </Row>

                      <Row className="mb-3">
                        <Form.Label>
                          Room No.
                        </Form.Label>
                        <EnlargableTextbox
                          id="txtRoomNo"
                          maxLength={5}
                          name="roomNo"
                          placeholder="Room No"
                          value={advocateClientCaseData.roomNo}
                          onChange={handleFieldChange}
                        />
                      </Row>
                      <Row className="mb-3">
                        <Form.Label>
                          Almirah No.
                        </Form.Label>
                        <EnlargableTextbox
                          id="txtAlmirahNo"
                          maxLength={5}
                          name="almirah"
                          placeholder="Almirah No"
                          value={advocateClientCaseData.almirah}
                          onChange={handleFieldChange}
                        />
                      </Row>
                      <Row className="mb-3">
                        <Form.Label>
                          Rack No.
                        </Form.Label>
                        <EnlargableTextbox
                          id="txtRackNo"
                          maxLength={50}
                          name="rack"
                          placeholder="Rack No"
                          value={advocateClientCaseData.rack}
                          onChange={handleFieldChange}
                        />
                      </Row>
                    </Col>
                  </Row>
                </FalconComponentCard.Body>
              </FalconComponentCard>
            </Col>
          </Row>
        </Form>
      }
    </>
  );
};

export default AdvocateClientCaseDetail;