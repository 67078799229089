import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import TabPage from 'components/common/TabPage';
import { Spinner, Modal, Button } from 'react-bootstrap';
import { formChangedAction, shortcutKeyCombinationAction} from 'actions';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { addSecurityMenuTreeDetailAPI, getCommandKeyCombinationListAPI, menuDetailsErrorAction, securityMenuTreeMasterDetailAction, securityMenuTreeMasterListAction, securityMenuTreeMasterListAPI, updateSecurityMenuTreeDetailAPI } from 'actions/SecurityMenuTree/SecurityMenuTreeAction';

const tabArray = ['Add Menu'];

export const SecurityMenuTreeDetails = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [formHasError, setFormError] = useState();
  const [menuTreeList, setMenuTreeList] = useState([]);

  let token = localStorage.getItem('Token') || sessionStorage.getItem('Token');
  const headers = {
    Authorization: `Bearer ${JSON.parse(token).value}`
  };

  const getSecurityMenuTreeMasterList = async () => {
    const requestParams = {
      encryptedRoleId: localStorage.getItem("EncryptedResponseRoleId") ? localStorage.getItem("EncryptedResponseRoleId") : ''
    }

    setIsLoading(true);
    dispatch(securityMenuTreeMasterListAPI(requestParams,
      (res) => {
        setIsLoading(false)
        let treeMenuListData = [];
        treeMenuListData = res.data.data.length > 0 ? res.data.data : [];
         dispatch(securityMenuTreeMasterListAction(treeMenuListData));

      },
      (err) => {
        setIsLoading(false)
         dispatch(securityMenuTreeMasterListAction(undefined));
        toast.error(err?.data.message, {
          theme: 'colored',
          autoClose: 10000
        });
      },
      headers
    ))
  }


  useEffect(() => {
    getSecurityMenuTreeMasterList();
    $("#btnNew").hide();
    $("#btnSave").show();
    localStorage.removeItem("ParentId")
  }, []);

  const SecurityMenuMenuTreeReducer = useSelector((state) => state.rootReducer.securityMenuTree)
   var menuData = SecurityMenuMenuTreeReducer.menuDetails;

  const formChangedReducer = useSelector((state) => state.rootReducer.formChangedReducer)
  var formChangedData = formChangedReducer.formChanged;

  let isFormChanged = Object.values(formChangedData).some(value => value === true);

  $('[data-rr-ui-event-key*="Add Menu"]').off('click').on('click', function () {
    getSecurityMenuTreeMasterList();
    $("#btnSave").show();
    $("#btnSave").attr('disabled', true);
    $("#btnNew").hide();
  })

  const exitModule = () => {
    $('#btnExit').attr('isExit', 'true');
    if (isFormChanged) {
      setModalShow(true)
    } else {
      window.location.href = '/dashboard';
    }
  }

  const menuDetailValidation = () => {
    let menuNameErr = {};
    let controlKeyErr = {};
    let commandKeyErr = {};
    let isValid = true;

    if (!menuData.menuItemName) {
      menuNameErr.empty = "Enter menu name";
      isValid = false;
      setFormError(true);
    }

    if (menuData.commandKeys && !menuData.controls) {
      controlKeyErr.empty = "Select control";
      isValid = false;
      setFormError(true);
    }

    if (menuData.controls && !menuData.commandKeys) {
      commandKeyErr.empty = "Select command";
      isValid = false;
      setFormError(true);
    }

    if (menuData.controls && menuData.commandKeys) {
      const shortCutKey = ["CTRL+V", "CTRL+C", "CTRL+X"];
      const commandKeysAndControls = menuData.commandKeys + "+" + menuData.controls;
      if (shortCutKey.includes(commandKeysAndControls)) {
        commandKeyErr.invalid = "System commands are not allowed!";
        controlKeyErr.invalid = "System commands are not allowed!";
        isValid = false;
        setFormError(true);
      }
    }

    if (!isValid) {
      var errorObject = {
        menuNameErr,
        controlKeyErr,
        commandKeyErr
      }
       dispatch(menuDetailsErrorAction(errorObject));
    }
    return isValid;
  }

  const addMenuDetails = async () => {
    if (menuDetailValidation()) {
      const requestData = {
        parentId: localStorage.getItem("ParentId") ? localStorage.getItem("ParentId") : 0,
        menuItemName: menuData.menuItemName,
        menuLevel: menuData.menuLevel ? parseInt(menuData.menuLevel) : 0,
        menuItemPageURL: menuData.menuItemPageURL ? menuData.menuItemPageURL : "",
        description: menuData.description ? menuData.description : "",
        controls: menuData.controls ? menuData.controls : "",
        commandKeys: menuData.commandKeys ? menuData.commandKeys : "",
        addUser: localStorage.getItem("LoginUserName")
      }

      const keys = ['addUser']
      for (const key of Object.keys(requestData).filter((key) => keys.includes(key))) {
        requestData[key] = requestData[key] ? requestData[key].toUpperCase() : '';
      }
      setIsLoading(true);
   await dispatch(
        addSecurityMenuTreeDetailAPI(
          requestData,
          response => {
            setIsLoading(false);
            toast.success(` ${response.data.message}`, {
              theme: 'colored',
              autoClose: 10000
            });
            setTimeout(function () {
              dispatch(securityMenuTreeMasterDetailAction({
                ...menuData,
                encryptedTreeId: response.data.data.encryptedTreeId
              }))
            }, 50);
            setModalShow(false);
            localStorage.removeItem("ParentId")
            $('[data-rr-ui-event-key*="Add Menu"]').trigger('click');
             dispatch(menuDetailsErrorAction(undefined));
            dispatch(formChangedAction(undefined));
             getShortCutKeys();
          },
          err => {
            setIsLoading(false)
            toast.error(err?.data.message, {
              theme: 'colored',
              autoClose: 10000
            });
          },
          headers
        )
      );

      setIsLoading(false);
    }
  }

  const updateMenuDetails = async () => {  
    if (menuDetailValidation()) {
      const requestData = {
        encryptedTreeId: menuData.encryptedTreeId,
        parentId: menuData.parentId,
        menuItemName: menuData.menuItemName,
        menuLevel: menuData.menuLevel ? parseInt(menuData.menuLevel) : 0,
        menuItemPageURL: menuData.menuItemPageURL ? menuData.menuItemPageURL : "",
        description: menuData.description ? menuData.description : "",
        controls: menuData.controls ? menuData.controls : "",
        commandKeys: menuData.commandKeys ? menuData.commandKeys : "",
        modifyUser: localStorage.getItem("LoginUserName")
      }

      const keys = [ 'modifyUser']
      for (const key of Object.keys(requestData).filter((key) => keys.includes(key))) {
        requestData[key] = requestData[key] ? requestData[key].toUpperCase() : '';
      }
      
      if (formChangedData.menuDetailUpdate) {
        setIsLoading(true);
      await  dispatch(
          updateSecurityMenuTreeDetailAPI(
            requestData,
            response => {
              setIsLoading(false)
              toast.success(response.data.message, {
                theme: 'colored',
                autoClose: 10000
              })
               dispatch(menuDetailsErrorAction(undefined));
              dispatch(formChangedAction(undefined));
              $('[data-rr-ui-event-key*="Add Menu"]').trigger('click');              
              getShortCutKeys();
            },
            err => {
              setIsLoading(false)
              toast.error(err?.data.message, {
                theme: 'colored',
                autoClose: 10000
              });
            },
            headers
          )
        );
      }
    }
  }

  const discardChanges = () => {
    $('#btnDiscard').attr('isDiscard', 'true');
    if ($('#btnExit').attr('isExit') == 'true') {
      window.location.href = '/dashboard';
    }
    setModalShow(false);
  }

  const getShortCutKeys = async () => {
  await dispatch(
      getCommandKeyCombinationListAPI(
        "",
        response => {
          if (response.length > 0) {
            dispatch(shortcutKeyCombinationAction(response))
          }
        },
        err => {
          setIsLoading(false)
          toast.error(err?.data.message, {
            theme: 'colored',
            autoClose: 10000
          });
        },
        headers
      )
    );
  }

  return (
    <>
      {isLoading ? (
        <Spinner
          className="position-absolute start-50 loader-color"
          animation="border"
        />
      ) : null}

      {modalShow &&
        <Modal
          show={modalShow}
          onHide={() => setModalShow(false)}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          backdrop="static"
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">Confirmation</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h4>Do you want to save changes?</h4>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="success" onClick={menuData.encryptedTreeId ? updateMenuDetails : addMenuDetails}>Save</Button>
            <Button variant="danger" id="btnDiscard" onClick={() => discardChanges()}>Discard</Button>
          </Modal.Footer>
        </Modal>
      }

      <TabPage
        tabArray={tabArray}
        module='SecurityMenuTree'
        saveDetails={
          menuData.encryptedTreeId ? updateMenuDetails : 
          addMenuDetails}
        exitModule={exitModule}
      />
    </>
  )
}

export default SecurityMenuTreeDetails;