import request from 'components/Utils/Request';
const { REACT_APP_API_URL } = process.env;
export const CASE_TYPE_MASTER_LIST_REQUEST = 'CASE_TYPE_MASTER_LIST_REQUEST';
export const CASE_TYPE_MASTER_LIST_SUCCESS = 'CASE_TYPE_MASTER_LIST_SUCCESS';
export const CASE_TYPE_MASTER_LIST_FAILURE = 'CASE_TYPE_MASTER_LIST_FAILURE';

export const caseTypeMasterListRequest = () => ({
    type: CASE_TYPE_MASTER_LIST_REQUEST
  });
  export const caseTypeMasterListSuccess = payload => ({
    type: CASE_TYPE_MASTER_LIST_SUCCESS,
    payload
  });
  export const caseTypeMasterListFailure = payload => ({
    type: CASE_TYPE_MASTER_LIST_FAILURE,
    payload
  });

  
  export const caseTypeMasterListAPI = (payload, successData, errorMSg, headers = {}) => {
    return dispatch => {
      dispatch(caseTypeMasterListRequest());

      const url = `${REACT_APP_API_URL}/get-case-type-master-list`;
      return request("GET", url, "", "", headers)
        .then(res => {
          if (res?.data.status === 200) {
            successData(res);
            dispatch(caseTypeMasterListSuccess(res));
          } else {
            errorMSg(res);
          }
        })
        .catch(err => {
          errorMSg(err?.res);
          dispatch(caseTypeMasterListFailure('Something went wrong'));
          throw err;
        });
    };
  };