import React from 'react';
import { useEffect, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { formChangedAction } from 'actions';
import { toast } from 'react-toastify';
import Moment from 'moment';
import EnlargableTextbox from 'components/common/EnlargableTextbox';
import { securityRoleMasterListAPI } from 'actions/SecurityRoleMaster/securityRoleMasterAction';
import { transactionDetailsAction } from 'actions/ClientTransactionDetails/clientTransactionDetailsAction';

export const TransactionDetails = () => {
  var initialState = {
    roleName: '',
    startDate: '',
    expiryDate: '',
    paymentType: '',
    chequeNo: '',
    chequeDate: '',
    chequeBank: '',
    amount: 0,
    paymentNo: '',
    gstPercentage: 0
  };

  const clientTransactionDetailReducer = useSelector(
    state => state.rootReducer.clientTransactionDetailsReducer
  );
  const transactionDetailData =
    clientTransactionDetailReducer.transactionDetails;
  const transactionDetailUpdate =
    clientTransactionDetailReducer.transactionData;

  const formChangedReducer = useSelector(
    state => state.rootReducer.formChangedReducer
  );
  var formChangedData = formChangedReducer.formChanged;

  const [formData, setFormData] = useState(initialState);
  const dispatch = useDispatch();
  const [amountPayable, setAmountPayable] = useState();
  const [roleList, setRoleList] = useState([]);
  const [formHasError, setFormError] = useState(false);
  const [roleNameErr, setRoleNameErr] = useState({});
  const [startDateErr, setStartDateErr] = useState({});
  const [expiryDateErr, setExpiryDateErr] = useState({});
  const [amountErr, setAmountErr] = useState({});
  const [paymentTypeErr, setPaymentTypeErr] = useState({});
  const [paymentNoErr, setPaymentNoErr] = useState({});
  useEffect(() => {
    $('[data-rr-ui-event-key*="Advocate Dealing"]').attr('disabled', false);
    getRole();
    $('.payment-type-details').hide();
  }, []);

  let token = localStorage.getItem('Token') || sessionStorage.getItem('Token');
  const headers = {
    Authorization: `Bearer ${JSON.parse(token).value}`
  };

  const getRole = async () => {
    await dispatch(
      securityRoleMasterListAPI(
        res => {
          let roleData = [];
          if (res.data && res.data.data.roleMasterList.length > 0)
            res.data.data.roleMasterList.forEach(role => {
              roleData.push({
                key: role.roleName,
                value: role.encryptedRoleId
              });
            });
          setRoleList(roleData);
        },
        err => {
          toast.error(err?.data.message, {
            theme: 'colored',
            autoClose: 10000
          });
          setRoleList([]);
        },
        headers
      )
    );
  };

  const validateTransactionDetails = () => {
    const roleNameErr = {};
    const startDateErr = {};
    const expiryDateErr = {};
    const amountErr = {};
    const paymentTypeErr = {};
    const paymentNoErr = {};

    let isValid = true;

    if (!formData.roleName) {
      roleNameErr.roleNameEmpty = 'Select role name';
      isValid = false;
      setFormError(true);
    }

    if (!formData.startDate) {
      startDateErr.startDateEmpty = 'Select start date';
      isValid = false;
      setFormError(true);
    }

    if (!formData.expiryDate) {
      expiryDateErr.expiryDateEmpty = 'Select expiry date';
      isValid = false;
      setFormError(true);
    } else if (formData.expiryDate <= formData.startDate) {
      expiryDateErr.expiryDateInvalid =
        'Expiry date cannot be same or less than Start Date';
      isValid = false;
      setFormError(true);
    }

    if (formData.amount <= 0) {
      amountErr.amountEmpty = 'Billing amount should be greater than zero';
      isValid = false;
      setFormError(true);
    }

    if (!formData.paymentType) {
      paymentTypeErr.paymentTypeEmpty = 'Select payment type';
      isValid = false;
      setFormError(true);
    }

    if (formData.paymentType != 'Cash') {
      if (!formData.paymentNo) {
        paymentNoErr.paymentNoEmpty = 'Enter payment no.';
        isValid = false;
        setFormError(true);
      }
    }

    if (!isValid) {
      setRoleNameErr(roleNameErr);
      setStartDateErr(startDateErr);
      setExpiryDateErr(expiryDateErr);
      setAmountErr(amountErr);
      setPaymentTypeErr(paymentTypeErr);
      setPaymentNoErr(paymentNoErr);
    }

    return isValid;
  };

  const clearStates = () => {
    setFormError(false);
    setRoleNameErr({});
    setStartDateErr({});
    setExpiryDateErr({});
    setAmountErr({});
    setPaymentTypeErr({});
    setPaymentNoErr({});
  };

  const submitTransactionDetails = e => {
    e.preventDefault();
    const form = e.currentTarget;

    if (validateTransactionDetails()) {
      const transactionData = {
        encryptedAdvocateCode: localStorage.getItem(
          'EncryptedResponseAdvocateCode'
        ),
        encryptedRoleId: formData.roleName,
        roleName: $('#setRoleName').find('option:selected').text(),
        startDate: formData.startDate,
        expiryDate: formData.expiryDate,
        paymentType: formData.paymentType,
        paymentNo: formData.paymentNo ? formData.paymentNo : '',
        gstPercent: !isNaN(parseFloat(formData.gstPercent))
          ? parseFloat(formData.gstPercent)
          : 0,
        billingAmount: parseFloat(formData.amount),
        totalAmount: amountPayable,
        addUser: localStorage.getItem('LoginUserName')
      };

      var loopBreaked = false;
      transactionDetailData.forEach(transactionDetail => {
        if (!loopBreaked) {
          if (transactionDetail.roleName == transactionData.roleName) {
            if (
              (Moment(transactionDetail.startDate).format('YYYY-MM-DD') <=
                formData.startDate &&
                Moment(transactionDetail.expiryDate).format('YYYY-MM-DD') >=
                  formData.startDate) ||
              (Moment(transactionDetail.startDate).format('YYYY-MM-DD') <=
                formData.expiryDate &&
                Moment(transactionDetail.expiryDate).format('YYYY-MM-DD') >=
                  formData.expiryDate) ||
              Moment(transactionDetail.startDate).format('YYYY-MM-DD') >=
                formData.startDate
            ) {
              toast.error(
                `For this date range ${transactionData.roleName} already exists, please select other date range`,
                {
                  theme: 'colored'
                }
              );

              loopBreaked = true;
            }
          }
        }
      });

      if (!loopBreaked) {
        dispatch(transactionDetailsAction(transactionData));

        dispatch(
          formChangedAction({
            ...formChangedData,
            transactionDetailAdd: true
          })
        );

        toast.success('Transaction Added Successfully', {
          theme: 'colored'
        });

        $('#TransactionDetailsTable').show();
        $('#TransactionDetailsListCard').show();

        setAmountPayable('');
        $(form)[0].reset();
        setFormData(initialState);
        clearStates();
        $('.payment-type-details').hide();
      }
    }
  };

  const handleFieldChange = e => {
    const { name, value } = e.target;

    if (value === 'Cash') {
      setFormData(prevState => ({
        ...prevState,
        paymentNo: '',
        [name]: value
      }));
      $('#txtPaymentNo').attr('disabled', true);
      setPaymentNoErr({});
    } else if (value === 'Cheque' || value === 'NEFT') {
      $('#txtPaymentNo').removeAttr('disabled');
      setFormData(prevState => ({
        ...prevState,
        [name]: value
      }));
    } else {
      setFormData(prevState => ({
        ...prevState,
        [name]: value
      }));
    }
  };

  const handleAmountChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
    if (e.target.value > 0) {
      getTotalAmountWithGstPercent(e.target.value, formData.gstPercent);
    } else {
      setAmountPayable(0);
    }
  };

  const handleGstPercentChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
    if (e.target.value > 0) {
      getTotalAmountWithGstPercent(formData.amount, e.target.value);
    } else {
      getTotalAmountWithGstPercent(formData.amount);
    }
  };

  const getTotalAmountWithGstPercent = (amount, gstPercent = 0) => {
    var gstAmount =
      gstPercent > 0 ? parseFloat((amount * gstPercent) / 100) : 0;
    setAmountPayable(parseFloat(amount) + gstAmount);
  };

  return (
    <>
      <Form
        noValidate
        validated={formHasError}
        className="details-form"
        onSubmit={e => {
          submitTransactionDetails(e);
        }}
        id="AddAdvocateTransactionDetailsForm"
      >
        <Row>
          <Col className="me-3 ms-3">
            <Row className="mb-3">
              <Form.Label>
                Role Name<span className="text-danger">*</span>
              </Form.Label>
              <Form.Select
                name="roleName"
                id="setRoleName"
                onChange={handleFieldChange}
                required
              >
                <option value="">Select Role</option>
                {roleList.map((option, index) => (
                  <option key={index} value={option.value}>
                    {option.key}
                  </option>
                ))}
              </Form.Select>
              {Object.keys(roleNameErr).map(key => {
                return (
                  <span className="error-message">{roleNameErr[key]}</span>
                );
              })}
            </Row>
            <Row className="mb-3">
              <Form.Label>
                Start Date<span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="date"
                id="dtStartDate"
                name="startDate"
                value={formData.startDate}
                onChange={handleFieldChange}
                required
              />
              {Object.keys(startDateErr).map(key => {
                return (
                  <span className="error-message">{startDateErr[key]}</span>
                );
              })}
            </Row>
            <Row className="mb-3">
              <Form.Label>
                Expiry Date<span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="date"
                id="dtExpiryDate"
                name="expiryDate"
                value={formData.expiryDate}
                onChange={handleFieldChange}
                required
              />
              {Object.keys(expiryDateErr).map(key => {
                return (
                  <span className="error-message">{expiryDateErr[key]}</span>
                );
              })}
            </Row>
          </Col>

          <Col className="me-3 ms-3">
            <Row className="mb-3">
              <Form.Label>
                Payment Type<span className="text-danger">*</span>
              </Form.Label>
              <Form.Select
                id="txtPaymentType"
                name="paymentType"
                onChange={handleFieldChange}
                required
              >
                <option value="">Select Payment Type</option>
                <option value="Cheque">Cheque</option>
                <option value="Cash">Cash</option>
                <option value="NEFT">NEFT</option>
              </Form.Select>
              {Object.keys(paymentTypeErr).map(key => {
                return (
                  <span className="error-message">{paymentTypeErr[key]}</span>
                );
              })}
            </Row>
            <Row className="mb-3">
              <Form.Label>Payment No.</Form.Label>
              <EnlargableTextbox
                id="txtPaymentNo"
                name="paymentNo"
                type="text"
                maxLength={30}
                value={formData.paymentNo}
                onChange={handleFieldChange}
                disabled
                placeholder="Enter payment no"
              />
              {Object.keys(paymentNoErr).map(key => {
                return (
                  <span className="error-message">{paymentNoErr[key]}</span>
                );
              })}
            </Row>
          </Col>

          <Col className="me-3 ms-3">
            <Row className="mb-3">
              <Form.Label>
                Billing Amount<span className="text-danger">*</span>
              </Form.Label>
              <EnlargableTextbox
                id="txtAmount"
                name="amount"
                maxLength={13}
                min={0}
                onChange={handleAmountChange}
                placeholder="Enter billing amount"
                required={true}
                onKeyPress={e => {
                  const key = String.fromCharCode(e.charCode);
                  const currentValue = e.target.value;
                  if (key === '.' && currentValue.includes('.')) {
                    e.preventDefault();
                  }
                  const regex = /^[0-9\.]+$/;
                  if (!regex.test(key)) {
                    e.preventDefault();
                  }
                }}
              />
              {Object.keys(amountErr).map(key => {
                return <span className="error-message">{amountErr[key]}</span>;
              })}
            </Row>
            <Row className="mb-3">
              <Form.Label>GST Percentage</Form.Label>
              <EnlargableTextbox
                id="numGstPercent"
                name="gstPercent"
                maxLength={5}
                min={0}
                onChange={handleGstPercentChange}
                placeholder="Enter gst percentage"
                onKeyPress={e => {
                  const key = String.fromCharCode(e.charCode);
                  const currentValue = e.target.value;

                  if (key === '.' && currentValue.includes('.')) {
                    e.preventDefault();
                    return;
                  }
                  const regex = /^[0-9\.]+$/;
                  if (!regex.test(key)) {
                    e.preventDefault();
                    return;
                  }
                  const potentialValue = parseFloat(currentValue + key);
                  if (!isNaN(potentialValue) && potentialValue > 99.99) {
                    e.preventDefault();
                  }
                }}
              />
            </Row>
            <Row className="mb-3">
              <Form.Label>Total Amount</Form.Label>
              <Form.Control
                type="number"
                id="numAmountPayable"
                name="totalAmount"
                disabled
                maxLength={13}
                value={amountPayable}
                onChange={handleFieldChange}
                onKeyPress={e => {
                  const key = String.fromCharCode(e.charCode);
                  const currentValue = e.target.value;
                  if (key === '.' && currentValue.includes('.')) {
                    e.preventDefault();
                  }
                  const regex = /^[0-9\.]+$/;
                  if (!regex.test(key)) {
                    e.preventDefault();
                  }
                }}
                placeholder="Total amount"
              />
            </Row>
            <Row className="mb-3">
              <Button variant="primary" type="submit">
                Add
              </Button>
            </Row>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default TransactionDetails;
