import request from 'components/Utils/Request';
const { REACT_APP_API_URL } = process.env;
export const ADD_CASE_NEXT_DATE_REQUEST = 'ADD_CASE_NEXT_DATE_REQUEST';
export const ADD_CASE_NEXT_DATE_SUCCESS = 'ADD_CASE_NEXT_DATE_SUCCESS';
export const ADD_CASE_NEXT_DATE_FAILURE = 'ADD_CASE_NEXT_DATE_FAILURE';
export const GET_CLIENT_CASE_MASTER_LIST_REQUEST =
  'GET_CLIENT_CASE_MASTER_LIST_REQUEST';
export const GET_CLIENT_CASE_MASTER_LIST_SUCCESS =
  'GET_CLIENT_CASE_MASTER_LIST_SUCCESS';
export const GET_CLIENT_CASE_MASTER_LIST_FAILURE =
  'GET_CLIENT_CASE_MASTER_LIST_FAILURE';

export const addCaseNextDateRequest = () => ({
  type: ADD_CASE_NEXT_DATE_REQUEST
});
export const addCaseNextDateSuccess = payload => ({
  type: ADD_CASE_NEXT_DATE_SUCCESS,
  payload
});
export const addCaseNextDateFailure = payload => ({
  type: ADD_CASE_NEXT_DATE_FAILURE,
  payload
});

export const getClientCaseMasterListRequest = () => ({
  type: GET_CLIENT_CASE_MASTER_LIST_REQUEST
});
export const getClientCaseMasterListSuccess = payload => ({
  type: GET_CLIENT_CASE_MASTER_LIST_SUCCESS,
  payload
});
export const getClientCaseMasterListFailure = payload => ({
  type: GET_CLIENT_CASE_MASTER_LIST_FAILURE,
  payload
});
export const caseNextDateDataAction = payload => {
  return {
    type: 'CASENEXTDATEDATA',
    payload
  };
};

export const caseNextDateDetailsErrorAction = payload => {
  return {
    type: 'CASENEXTDATEDETAILSERROR',
    payload
  };
};

export const addCaseNextDateDetailAPI = (payload, successData, errorMsg, headers = {}) => {
  return dispatch => {
    dispatch(addCaseNextDateRequest());
    return request(
      'POST',
      `${REACT_APP_API_URL + '/add-case-next-date-detail'}`,
      payload,
      "",
      headers
    )
      .then(response => {
        if (response?.data?.status === 200) {
          successData(response);
          dispatch(addCaseNextDateSuccess(response));
        } else {
          errorMsg(response);
        }
      })
      .catch(error => {
        errorMsg(error?.response);
        dispatch(addCaseNextDateFailure('Something went wrong'));
        throw error;
      });
  };
};

export const getClientCaseMasterListAPI = (payload, successData, errorMSg, headers = {}) => {
  return dispatch => {
    dispatch(getClientCaseMasterListRequest());
    return request(
      'POST',
      `${REACT_APP_API_URL + '/get-client-case-master-list'}`,
      payload,
      "",
      headers
    )
      .then(res => {
        if (res?.data.status === 200) {
          successData(res);
          dispatch(getClientCaseMasterListSuccess(res));
        } else {
          errorMSg(res);
        }
      })
      .catch(err => {
        errorMSg(err?.res);
        dispatch(getClientCaseMasterListFailure('Something went wrong'));
        throw err;
      });
  };
};
