import React from 'react';
import { useEffect, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { formChangedAction } from '../../actions/index';
import EnlargableTextbox from 'components/common/EnlargableTextbox';
import { countryListAPI } from 'actions/CountryMaster/countryMasterAction';
import { toast } from 'react-toastify';
import { stateListAPI } from 'actions/StateMaster/stateMasterAction';
import {
  userDataAction,
  userDetailsAction
} from 'actions/SecurityUserAssignment/SecurityUserAssignmentAction';
import FalconComponentCard from 'components/common/FalconComponentCard';
import FalconCardBody from 'components/common/FalconCardBody';
import Treeview from 'components/common/Treeview';
import { advocateUserMenuTreeListAPI } from 'actions/SecurityMenuTree/SecurityMenuTreeAction';
import { advocateListAPI } from 'actions/AdvocateRegistration/advocateRegistrationAction';
import { selectedMenusAction } from 'actions/SecurityMenuRoleAsscociation/securityMenuRoleAssociation';

export const UserDetail = () => {
  const dispatch = useDispatch();
  const [treeViewItems, setTreeViewItems] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [formHasError, setFormError] = useState(false);
  const [advocateList, setAdvocateList] = useState([]);
  const [isAdmin, setIsAdmin] = useState(false);
  const [perPage, setPerPage] = useState(15);

  let token = localStorage.getItem('Token') || sessionStorage.getItem('Token');
  const headers = {
    Authorization: `Bearer ${JSON.parse(token).value}`
  };

  useEffect(() => {
    dispatch(selectedMenusAction(selectedItems));
  }, [selectedItems]);

  const resetUserData = () => {
    dispatch(
      userDetailsAction({
        userName: '',
        encryptedUserId: '',
        userAddress1: '',
        userAddress2: '',
        userAddress3: '',
        loginId: '',
        mobileNo: '',
        email: '',
        countryCode: '',
        country: '',
        stateCode: '',
        state: '',
        status: 'Active',
        encryptedAdvocateCode: ''
      })
    );
    setSelectedItems(undefined);
  };

  const userDetailsReducer = useSelector(
    state => state.rootReducer.securityUserAssignment
  );
  var userData = userDetailsReducer.userDetails;

  const userDetailsErrorReducer = useSelector(
    state => state.rootReducer.userDetailsErrorReducer
  );
  const userError = userDetailsErrorReducer.userDetailsError;

  const formChangedReducer = useSelector(
    state => state.rootReducer.formChangedReducer
  );
  var formChangedData = formChangedReducer.formChanged;

  useEffect(() => {
    getAdvocateList();
    var roles = JSON.parse(localStorage.getItem('UserRoles'));
    if (roles.includes('ADMIN')) {
      setIsAdmin(true);
    }

    // if (!roles.includes('ADMIN')) {
    //   fetchMenuTree();
    // }
    fetchMenuTree();
    getCountries();
  }, []);

  const handleSelectedItems = () => {
    if (
      userDetailsReducer.userDetails &&
      userData.treeIds &&
      userData.treeIds.length > 0
    ) {
      setSelectedItems(userData.treeIds);
    }
  };

  if (
    !userDetailsReducer.userDetails ||
    Object.keys(userDetailsReducer.userDetails).length <= 0
  ) {
    resetUserData();
  } else if (
    userDetailsReducer.userDetails.encryptedUserId &&
    (!selectedItems || selectedItems.length <= 0) &&
    !formChangedData.menuItemAdd &&
    !formChangedData.menuItemDelete
  ) {
    handleSelectedItems();
  }

  useEffect(() => {
    if (userData && userData.stateCode && !$('#txtState').val()) {
      getStates(userData.countryCode);
    }
  }, [userData]);

  const getCountries = async (page, size = perPage) => {
    const data = {
      pageNumber: page,
      pageSize: size,
      SearchText: '',
      isDropDown: true,
    };
    await dispatch(
      countryListAPI(
        data,
        res => {
          let countryData = [];
          if (res.data.data.length > 0)
            res.data.data.forEach(country => {
              countryData.push({
                key: country.countryName,
                value: country.countryCode
              });
            });
          setCountryList(countryData);
        },
        err => {
          toast.error(err?.data?.message || 'Error fetching countries', {
            theme: 'colored',
            autoClose: 10000
          });
          setCountryList([]);
        },
        headers
      )
    );
  };

  const getStates = async selectCountryCode => {
    const data = {
      countryCode: selectCountryCode,
      isDropDown: true,
      isList: false
    };
    await dispatch(
      stateListAPI(
        data,
        res => {
          let stateData = [];
          if (res && res.length > 0) {
            res.forEach(state => {
              stateData.push({
                key: state.stateName,
                value: state.stateCode
              });
            });
            setStateList(stateData);
          } else {
            setStateList([]);
          }
        },
        err => {
          setStateList([]);
        }
      )
    );
  };

  if (userData.advocateName && !$('#txtAdvocate').val()) {
    $('#txtAdvocate option:contains(' + userData.advocateName + ')').prop(
      'selected',
      true
    );
  }

  const handleFieldChange = async (e) => {
    if (e.target.name == 'encryptedAdvocateCode') {
      setSelectedItems([]);
      const requestData = {
        encryptedAdvocateCode: e.target.value
      };
      await dispatch(
        advocateUserMenuTreeListAPI(
          requestData,
          response => {
            if (response.data && response.data.data.length > 0) {
              setTreeViewItems(response.data.data);
            }
          },
          err => {
            setTreeViewItems([]);
            toast.error(err?.data.message);
          },
          headers
        )
      );
      dispatch(
        userDetailsAction({
          ...userData,
          [e.target.name]: e.target.value
        })
      );
    } else {
      dispatch(
        userDetailsAction({
          ...userData,
          [e.target.name]: e.target.value
        })
      );
    }

    if (e.target.name == 'countryCode') {
      if (e.target.value == '') setStateList([]);
      else getStates(e.target.value);
    }
    if (userData.encryptedUserId) {
      dispatch(
        formChangedAction({
          ...formChangedData,
          userUpdate: true
        })
      );
    } else {
      dispatch(
        formChangedAction({
          ...formChangedData,
          userAdd: true
        })
      );
    }
  };

  const fetchMenuTree = async () => {
    const requestData = {
      encryptedSecurityUserId: localStorage.getItem('EncryptedSecurityUserId')
    };

    await dispatch(
      advocateUserMenuTreeListAPI(
        requestData,
        response => {
          if (response.data && response.data.data.length > 0) {
            setTreeViewItems(response.data.data);
          }
        },
        err => {
          setTreeViewItems([]);
          toast.error(err?.data.message);
        },
        headers
      )
    );
  };

  const getAdvocateList = async () => {
    const requestData = {
      isDropdown: true
    };

    await dispatch(
      advocateListAPI(
        requestData,
        response => {
          let advocateListData = [];
          response.data.data.advocateList.forEach(item => {
            advocateListData.push({
              key: item.advocateName,
              value: item.encryptedAdvocateCode
            });
          });
          setAdvocateList(advocateListData);
        },
        err => {
          setAdvocateList([]);
          toast.error(err?.data.message, {
            theme: 'colored',
            autoClose: 10000
          });
        },
        headers
      )
    );
  };

  // if (userData && userData.stateCode && !$('#txtState').val()) {
  //   getStates(userData.countryCode);
  // }

  return (
    <>
      {userData && (
        <Row>
          <Col lg={6} className="no-pd-card no-right-pad">
            <FalconComponentCard className="farmer-card-row1">
              <FalconCardBody className="full-tab-page-card-body">
                <Form
                  noValidate
                  validated={formHasError}
                  className="details-form"
                  id="addUserDetailsForm"
                >
                  <Row>
                    <Col className="me-3 ms-3">
                      {isAdmin === true && (
                        <Row className="mb-3">
                          <Form.Label>
                            Advocate<span className="text-danger">*</span>
                          </Form.Label>
                          <Form.Select
                            id="txtAdvocate"
                            name="encryptedAdvocateCode"
                            onChange={handleFieldChange}
                            required
                            disabled={userData.encryptedUserId}
                          >
                            <option value="">Select Advocate</option>
                            {advocateList.map((option, index) => (
                              <option key={index} value={option.value}>
                                {option.key}
                              </option>
                            ))}
                          </Form.Select>
                        </Row>
                      )}
                      <Row className="mb-3">
                        <Form.Label>
                          Login Id<span className="text-danger">*</span>
                        </Form.Label>
                        <EnlargableTextbox
                          id="txtLoginId"
                          name="loginId"
                          maxLength={50}
                          disabled={userData.encryptedUserId}
                          value={userData.loginId}
                          onChange={handleFieldChange}
                          placeholder="Login Id"
                        />
                        {Object.keys(userError.loginIdErr).map(key => {
                          return (
                            <span className="error-message">
                              {userError.loginIdErr[key]}
                            </span>
                          );
                        })}
                      </Row>
                      <Row className="mb-3">
                        <Form.Label>
                          User Name<span className="text-danger">*</span>
                        </Form.Label>
                        <EnlargableTextbox
                          id="txtUserName"
                          name="userName"
                          maxLength={200}
                          value={userData.userName}
                          onChange={handleFieldChange}
                          placeholder="User Name"
                          required={true}
                        />
                        {Object.keys(userError.userNameErr).map(key => {
                          return (
                            <span className="error-message">
                              {userError.userNameErr[key]}
                            </span>
                          );
                        })}
                      </Row>

                      <Row className="mb-3">
                        <Form.Label>
                          Email ID<span className="text-danger">*</span>
                        </Form.Label>
                        <EnlargableTextbox
                          id="txtEmail"
                          name="email"
                          maxLength={50}
                          value={userData.email}
                          onChange={handleFieldChange}
                          placeholder="Email ID"
                          required
                        />
                        {Object.keys(userError.emailErr).map(key => {
                          return (
                            <span className="error-message">
                              {userError.emailErr[key]}
                            </span>
                          );
                        })}
                      </Row>
                      <Row className="mb-3">
                        <Form.Label>
                          Mobile No.<span className="text-danger">*</span>
                        </Form.Label>
                        <EnlargableTextbox
                          id="txtMobileNo"
                          name="mobileNo"
                          maxLength={10}
                          value={userData.mobileNo}
                          onChange={handleFieldChange}
                          placeholder="Mobile No."
                          onKeyPress={e => {
                            const regex = /[0-9]|\./;
                            const key = String.fromCharCode(e.charCode);
                            if (!regex.test(key)) {
                              e.preventDefault();
                            }
                          }}
                          required
                        />
                        {Object.keys(userError.mobileNoErr).map(key => {
                          return (
                            <span className="error-message">
                              {userError.mobileNoErr[key]}
                            </span>
                          );
                        })}
                      </Row>

                      <Row className="mb-3">
                        <Form.Label>User Address</Form.Label>
                        <EnlargableTextbox
                          id="txtUserAddress1"
                          name="userAddress1"
                          maxLength={200}
                          value={userData.userAddress1}
                          onChange={handleFieldChange}
                          className="mb-1"
                          placeholder="Address"
                        />
                        <EnlargableTextbox
                          id="txtUserAddress2"
                          name="userAddress2"
                          maxLength={200}
                          value={userData.userAddress2}
                          onChange={handleFieldChange}
                          className="mb-1"
                          placeholder="Address 2"
                        />
                      </Row>
                    </Col>

                    <Col className="me-3 ms-3">
                      <Row className="mb-3">
                        <Form.Label>
                          Country<span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Select
                          id="txtCountry"
                          name="countryCode"
                          value={userData.countryCode}
                          onChange={handleFieldChange}
                          required
                        >
                          <option value="">Select country</option>
                          {countryList.map((option, index) => (
                            <option key={index} value={option.value}>
                              {option.key}
                            </option>
                          ))}
                        </Form.Select>
                        {Object.keys(userError.countryErr).map(key => {
                          return (
                            <span className="error-message">
                              {userError.countryErr[key]}
                            </span>
                          );
                        })}
                      </Row>
                      <Row className="mb-3">
                        <Form.Label>
                          State<span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Select
                          id="txtState"
                          name="stateCode"
                          value={userData.stateCode}
                          onChange={handleFieldChange}
                          required
                        >
                          <option value="">Select state</option>
                          {stateList.map((option, index) => (
                            <option key={index} value={option.value}>
                              {option.key}
                            </option>
                          ))}
                        </Form.Select>
                        {Object.keys(userError.stateErr).map(key => {
                          return (
                            <span className="error-message">
                              {userError.stateErr[key]}
                            </span>
                          );
                        })}
                      </Row>
                      <Row className="mb-3">
                        <Form.Label>
                          Status<span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Select
                          id="txtStatus"
                          name="status"
                          value={userData.status}
                          onChange={handleFieldChange}
                        >
                          <option value="Active">Active</option>
                          <option value="Suspended">Suspended</option>
                        </Form.Select>
                      </Row>
                    </Col>
                  </Row>
                </Form>
              </FalconCardBody>
            </FalconComponentCard>
          </Col>

          <Col lg={6} className="no-pd-card col-left-pad">
            <FalconComponentCard className="farmer-card-row1">
              <FalconCardBody
                className="full-tab-page-card-body"
                language="jsx"
              >
                <Col className="me-3 ms-3">
                  <Treeview
                    data={treeViewItems}
                    selection
                    defaultSelected={[]}
                    selectedItems={selectedItems}
                    setSelectedItems={setSelectedItems}
                  />
                </Col>
              </FalconCardBody>
            </FalconComponentCard>
          </Col>
        </Row>
      )}
    </>
  );
};

export default UserDetail;
