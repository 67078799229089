import axios from 'axios';
const request = (method = 'get', url = '/', payload, params, headers) => {  
  const options = {
    method,
    url,
    data: payload !== undefined && payload,
    params: params,
    ...(headers && { headers }),
  };

  return new Promise((resolve, reject) => {
    axios(options, payload)
      .then(res => resolve(res))
      .catch(error => reject(error));
  });
};

export default request;