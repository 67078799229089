import request from 'components/Utils/Request';
const { REACT_APP_API_URL } = process.env;
export const UPDATE_FILE_PLACEMENT_REQUEST = 'UPDATE_FILE_PLACEMENT_REQUEST';
export const UPDATE_FILE_PLACEMENT_SUCCESS = 'UPDATE_FILE_PLACEMENT_SUCCESS';
export const UPDATE_FILE_PLACEMENT_FAILURE = 'UPDATE_FILE_PLACEMENT_FAILURE';
export const GET_FILE_PLACEMENT_LIST_REQUEST = 'GET_FILE_PLACEMENT_LIST_REQUEST';
export const GET_FILE_PLACEMENT_LIST_SUCCESS = 'GET_FILE_PLACEMENT_LIST_SUCCESS';
export const GET_FILE_PLACEMENT_LIST_FAILURE = 'GET_FILE_PLACEMENT_LIST_FAILURE';

export const getFilePlacementListRequest = () => ({
    type: GET_FILE_PLACEMENT_LIST_REQUEST
  });
  export const getFilePlacementListSuccess = payload => ({
    type: GET_FILE_PLACEMENT_LIST_SUCCESS,
    payload
  });
  export const getFilePlacementListFailure = payload => ({
    type: GET_FILE_PLACEMENT_LIST_FAILURE,
    payload
  });

  export const updateFilePlacementCaseRequest = () => ({
    type: UPDATE_FILE_PLACEMENT_REQUEST
  });
  export const updateFilePlacementSuccess = payload => ({
    type: UPDATE_FILE_PLACEMENT_SUCCESS,
    payload
  });
  export const updateFilePlacementFailure = payload => ({
    type: UPDATE_FILE_PLACEMENT_FAILURE,
    payload
  });

  export const filePlacementDataAction = payload => {
    return {
      type: 'FILEPLACEMENTDATA',
      payload
    };
  };

  export const getFilePlacementListAPI = (payload, successData, errorMSg, headers = {}) => {
    return dispatch => {
      dispatch(getFilePlacementListRequest());
      return request(
        'POST',
        `${REACT_APP_API_URL + '/get-file-placement-list'}`,
        payload,
        "",
        headers
      )
        .then(res => {
          if (res?.data.status === 200) {
            successData(res);
            dispatch(getFilePlacementListSuccess(res));
          } else {
            errorMSg(res);
          }
        })
        .catch(err => {
          errorMSg(err?.res);
          dispatch(getFilePlacementListFailure('Something went wrong'));
          throw err;
        });
    };
  };

  export const updateFilePlacementAPI = (payload, successData, errorMSg, headers = {}) => {
    return dispatch => {
      dispatch(updateFilePlacementCaseRequest());
      return request(
        'POST',
        `${REACT_APP_API_URL + '/update-file-placement-detail'}`,
        payload,
        "",
        headers
      )
        .then(res => {
          if (res?.data.status === 200) {
            successData(res);
            dispatch(updateFilePlacementSuccess(res));
          } else {
            errorMSg(res);
          }
        })
        .catch(err => {
          errorMSg(err?.res);
          dispatch(updateFilePlacementFailure('Something went wrong'));
          throw err;
        });
    };
  };