import React, { useEffect, useState } from 'react';
import TabPage from 'components/common/TabPage';
import { useSelector, useDispatch } from 'react-redux';
import $ from 'jquery';
import { Button, Modal, Spinner } from 'react-bootstrap';
import { formChangedAction } from 'actions';
import {
  getCourtMasterListAPI,
} from 'actions/CourtMaster/courtMasterAction';

const tabArray = ['Court List', 'Add Court'];

const listColumnArray = [
  { accessor: 'sl', Header: 'S. No' },
  { accessor: 'courtName', Header: 'Court Name' },
  { accessor: 'courtLocation', Header: 'Court Location' },
  { accessor: 'courtAddress', Header: 'Court Address' },
  { accessor: 'courtTypeName', Header: 'Court Type' },
  { accessor: 'stateName', Header: 'State Name' }
];

export const CourtMaster = () => {
  const dispatch = useDispatch();
  const [perPage, setPerPage] = useState(15);
  const [isLoading, setIsLoading] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [courtMasterList, setCourtMasterList] = useState([]);

  let token = localStorage.getItem('Token') || sessionStorage.getItem('Token');
  const headers = {
    Authorization: `Bearer ${JSON.parse(token).value}`
  };

  const getCourtMasterList = (page, size = perPage) => {
    const data = {
      pageNumber: page,
      pageSize: size,
      SearchText: ''
    };
    setIsLoading(true);
    dispatch(
      getCourtMasterListAPI(
        data,
        response => {
          setIsLoading(false);
          setCourtMasterList(response.data.data);
        },
        err => {
          setIsLoading(false);
          setCourtMasterList([]);
          console.log(err?.data.message);
        },
        headers
      )
    );
  };

  useEffect(() => {
    getCourtMasterList(1, perPage);
    $('[data-rr-ui-event-key*="Add Court"]').attr('disabled', true);
  }, []);

  const formChangedReducer = useSelector(
    state => state.rootReducer.formChangedReducer
  );
  var formChangedData = formChangedReducer.formChanged;

  let isFormChanged = Object.values(formChangedData).some(
    value => value === true
  );

  const [activeTabName, setActiveTabName] = useState();

  const clearCourtMasterReducer = () => {
    dispatch(formChangedAction(undefined));
  };

  $('[data-rr-ui-event-key*="Court List"]')
    .off('click')
    .on('click', function () {
      let isDiscard = $('#btnDiscard').attr('isDiscard');
      if (isDiscard != 'true' && isFormChanged) {
        setModalShow(true);
        setTimeout(function () {
          $('[data-rr-ui-event-key*="' + activeTabName + '"]').trigger('click');
        }, 50);
      } else {
        $('#btnNew').show();
        $('#btnSave').hide();
        $('#btnCancel').hide();
        $('[data-rr-ui-event-key*="Add Court"]').attr('disabled', true);
        $('[data-rr-ui-event-key*="Court List"]').attr('disabled', false);
        $('#addCourtMasterDetailsForm').get(0).reset();
        localStorage.removeItem('EncryptedResponseCourtCode');
        $('#btnDiscard').attr('isDiscard', false);
        clearCourtMasterReducer();
      }
    });

  $('[data-rr-ui-event-key*="Add Court"]')
    .off('click')
    .on('click', function () {
      setActiveTabName('Add Court');
      $('#btnNew').hide();
      $('#btnSave').show();
      $('#btnCancel').show();
    });

  const newDetails = () => {
    $('[data-rr-ui-event-key*="Add Court"]').attr('disabled', false);
    $('[data-rr-ui-event-key="Add Court"]').trigger('click');
    $('#btnSave').attr('disabled', false);
    clearCourtMasterReducer();
  };

  const cancelClick = () => {
    $('#btnExit').attr('isExit', 'false');
    if (isFormChanged) {
      setModalShow(true);
    } else {
      $('[data-rr-ui-event-key*="Court List"]').trigger('click');
    }
  };

  const exitModule = () => {
    $('#btnExit').attr('isExit', 'true');
    if (isFormChanged) {
      setModalShow(true);
    } else {
      window.location.href = '/dashboard';
    }
  };

  const discardChanges = () => {
    $('#btnDiscard').attr('isDiscard', 'true');
    if ($('#btnExit').attr('isExit') == 'true') {
      window.location.href = '/dashboard';
    } else {
      $('[data-rr-ui-event-key*="List"]').trigger('click');
      setModalShow(false);
    }
  };

return (
    <>
      {isLoading ? (
        <Spinner
          className="position-absolute start-50 loader-color"
          animation="border"
        />
      ) : null}

      {modalShow && (
        <Modal
          show={modalShow}
          onHide={() => setModalShow(false)}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          backdrop="static"
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Confirmation
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h4>Do you want to save changes?</h4>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="success"
              // onClick={
              //   !courtMasterData.encryptedCourtCode
              //     ? addCourtMasterDetails
              //     : updateCourtMasterDetails
              // }
            >
              Save
            </Button>
            <Button
              variant="danger"
              id="btnDiscard"
              onClick={() => discardChanges()}
            >
              Discard
            </Button>
          </Modal.Footer>
        </Modal>
      )}

      <TabPage
        listData={courtMasterList}
        listColumnArray={listColumnArray}
        tabArray={tabArray}
        module="CourtMaster"
        // saveDetails={
        //   courtMasterData.encryptedCourtCode
        //     ? updateCourtMasterDetails
        //     : addCourtMasterDetails
        // }
        newDetails={newDetails}
        cancelClick={cancelClick}
        exitModule={exitModule}
      />
    </>
  );
};

export default CourtMaster;