import request from 'components/Utils/Request';
const { REACT_APP_API_URL } = process.env;
export const ADVOCATE_REGISTRATION_REQUEST = 'ADVOCATE_REGISTRATION_REQUEST';
export const ADVOCATE_REGISTRATION_SUCCESS = 'ADVOCATE_REGISTRATION_SUCCESS';
export const ADVOCATE_REGISTRATION_FAILURE = 'ADVOCATE_REGISTRATION_FAILURE';
export const UPDATE_ADVOCATE_DETAIL_REQUEST = 'UPDATE_ADVOCATE_DETAIL_REQUEST';
export const UPDATE_ADVOCATE_DETAIL_SUCCESS = 'UPDATE_ADVOCATE_DETAIL_SUCCESS';
export const UPDATE_ADVOCATE_DETAIL_FAILURE = 'UPDATE_ADVOCATE_DETAIL_FAILURE';
export const ADVOCATE_LIST_REQUEST = 'ADVOCATE_LIST_REQUEST';
export const ADVOCATE_LIST_SUCCESS = 'ADVOCATE_LIST_SUCCESS';
export const ADVOCATE_LIST_FAILURE = 'ADVOCATE_LIST_FAILURE';
export const ADVOCATE_EMAIL_MOBILE_VALIDATION_REQUEST =
  'ADVOCATE_EMAIL_MOBILE_VALIDATION_REQUEST';
export const ADVOCATE_EMAIL_MOBILE_VALIDATION_SUCCESS =
  'ADVOCATE_EMAIL_MOBILE_VALIDATION_SUCCESS';
export const ADVOCATE_EMAIL_MOBILE_VALIDATION_FAILURE =
  'ADVOCATE_EMAIL_MOBILE_VALIDATION_FAILURE';

// add- advocate-registration
export const updateAdvocateDetailsRequest = () => ({
  type: UPDATE_ADVOCATE_DETAIL_REQUEST
});
export const updateAdvocateDetailsSuccess = payload => ({
  type: UPDATE_ADVOCATE_DETAIL_SUCCESS,
  payload
});
export const updateAdvocateDetailsFailure = payload => ({
  type: UPDATE_ADVOCATE_DETAIL_FAILURE,
  payload
});
// -update-advocate-details
export const advocateRegistrationRequest = () => ({
  type: ADVOCATE_REGISTRATION_REQUEST
});
export const advocateRegistrationSuccess = payload => ({
  type: ADVOCATE_REGISTRATION_SUCCESS,
  payload
});
export const advocateRegistrationFailure = payload => ({
  type: ADVOCATE_REGISTRATION_FAILURE,
  payload
});

// advocate-list
export const advocateListRequest = () => ({
  type: ADVOCATE_LIST_REQUEST
});
export const advocateListSuccess = payload => ({
  type: ADVOCATE_LIST_SUCCESS,
  payload
});
export const advocateListFailure = payload => ({
  type: ADVOCATE_LIST_FAILURE,
  payload
});

//email/mobile validation
export const advocateEmailMobileValidationRequest = () => ({
  type: ADVOCATE_EMAIL_MOBILE_VALIDATION_REQUEST
});
export const advocateEmailMobileValidationSuccess = payload => ({
  type: ADVOCATE_EMAIL_MOBILE_VALIDATION_SUCCESS,
  payload
});
export const advocateEmailMobileValidationFailure = payload => ({
  type: ADVOCATE_EMAIL_MOBILE_VALIDATION_FAILURE,
  payload
});

export const advocateRegistrationAPI = (
  payload,
  successData,
  errorMsg,
  header = {}
) => {
  return dispatch => {
    dispatch(advocateRegistrationRequest());
    return request(
      'POST',
      `${REACT_APP_API_URL + '/advocate-registration'}`,
      payload,
      '',
      header
    )
      .then(response => {
        if (response?.data?.status === 200) {
          successData(response);
          dispatch(advocateRegistrationSuccess(response));
        } else {
          errorMsg(response);
        }
      })
      .catch(error => {
        errorMsg(error?.response);
        dispatch(advocateRegistrationFailure('Something Went Wrong'));
        throw error;
      });
  };
};

export const advocateUpdateDetailsAPI = (
  payload,
  successData,
  errorMsg,
  headers = {}
) => {
  return dispatch => {
    dispatch(updateAdvocateDetailsRequest());
    return request(
      'POST',
      `${REACT_APP_API_URL + '/update-advocate-detail'}`,
      payload,
      '',
      headers
    )
      .then(response => {
        if (response?.data?.status === 200) {
          successData(response);
          dispatch(updateAdvocateDetailsSuccess(response));
        } else {
          errorMsg(response);
        }
      })
      .catch(error => {
        errorMsg(error?.response);
        dispatch(updateAdvocateDetailsFailure('Something Went Wrong'));
        throw error;
      });
  };
};

export const advocateListAPI = (
  payload,
  successData,
  errorMSg,
  headers = {}
) => {
  return dispatch => {
    dispatch(advocateListRequest());
    const url = `${REACT_APP_API_URL}/advocate-list`;
    return request('POST', url, payload, '', headers)
      .then(res => {
        if (res?.data.status === 200) {
          successData(res);
          dispatch(advocateListSuccess(res));
        } else {
          errorMSg(res);
        }
      })
      .catch(err => {
        errorMSg(err?.res);
        dispatch(advocateListFailure('Something went wrong'));
        throw err;
      });
  };
};

export const advocateEmailMobileValidationAPI = (
  payload,
  successData,
  errorMSg,
  headers = {}
) => {
  return dispatch => {
    dispatch(advocateEmailMobileValidationRequest());
    const url = `${REACT_APP_API_URL}/check-email-phone-exists`;
    return request('POST', url, payload, '', headers)
      .then(res => {
        // if (res?.data.status === 200) {

        // }
        successData(res);
        dispatch(advocateEmailMobileValidationSuccess(res));
      })
      .catch(err => {
        errorMSg(err?.res);
        dispatch(advocateEmailMobileValidationFailure('Something went wrong'));
        throw err;
      });
  };
};
