import {
    ADD_STATE_MASTER_REQUEST,
    ADD_STATE_MASTER_SUCCESS,
    ADD_STATE_MASTER_FAILURE,
    STATE_MASTER_LIST_REQUEST,
    STATE_MASTER_LIST_SUCCESS,
    STATE_MASTER_LIST_FAILURE,
    UPDATE_STATE_MASTER_REQUEST,
    UPDATE_STATE_MASTER_SUCCESS,
    UPDATE_STATE_MASTER_FAILURE
} from "../../actions/StateMaster/stateMasterAction";
const initialState = {
    stateList: [],
    stateMasterDetail:[],
    stateMasterData:[],
};

export default function stateMasterReducer(state = initialState, action) {
    switch (action.type) {
        case STATE_MASTER_LIST_REQUEST:
            return {
                ...state,
            };
        case STATE_MASTER_LIST_SUCCESS:
            return {
                ...state,
                stateMasterDetail: action.payload
            };
        case STATE_MASTER_LIST_FAILURE:
            return {
                ...state,
                error: action.payload
            };
        case ADD_STATE_MASTER_REQUEST:
            return {
                ...state,
            };
        case ADD_STATE_MASTER_SUCCESS:
            return {
                ...state,
                stateMasterDetail: action.payload
            };
        case ADD_STATE_MASTER_FAILURE:
            return {
                ...state,
                error: action.payload
            };
            case UPDATE_STATE_MASTER_REQUEST:
                return {
                    ...state,
                };
            case UPDATE_STATE_MASTER_SUCCESS:
                return {
                    ...state,
                    stateMasterDetail: action.payload
                };
            case UPDATE_STATE_MASTER_FAILURE:
                return {
                    ...state,
                    error: action.payload
                };
            case 'STATEMASTERDATA':
                if (!action.payload) {
                    return initialState;
                } else {
                    return {
                        ...state,
                        stateMasterDetail: action.payload
                    };
                }
                case 'STATEDEATILS':
                    if (!action.payload) {
                        return initialState;
                    } else {
                        return {
                            ...state,
                            stateMasterDetail: action.payload
                        };
                    }
        default:
            return state;
    }
}