
const initialState = {
  advocateClientCaseDetailsError: {
    clientNameErr: {},
    countryErr: {},
    stateErr: {},
    caseNoErr: {},
    emailErr: {},
    caseTypeErr: {},
    courtNameErr:{},
    registerDateErr:{},
    nextHearingDateErr:{},
    addvocateMobileNoErr:{},
    caseTitleErr:{}
  }
};

const advocateClientCaseDetailsErrorReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'ADVOCATECLIENTCASEDETAILSERROR':
      if (!action.payload) {
        return initialState;
      } else {
        return {
          ...state,
          advocateClientCaseDetailsError: action.payload
        };
      }
    default:
      return state;
  }
};

export default advocateClientCaseDetailsErrorReducer;