import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { Button, Col, Form, Row, Spinner } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import ReCAPTCHA from "react-google-recaptcha";
import $ from 'jquery';
import { loginAPI } from 'actions/SecurityUserAssignment/SecurityUserAssignmentAction';

const LoginForm = ({ hasLabel }) => {
  const [formData, setFormData] = useState({
    userId: '',
    password: '',
    remember: false
  });
  const [validated, setValidated] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [captchaErr, setCaptchaErr] = useState('');
  const dispatch = useDispatch();

  useEffect(() => {
     localStorage.clear();
  }, []);

  const handleSubmit = e => {
    e.preventDefault();    
    const form = e.currentTarget;
    if (form.checkValidity() === false || 
        !handleCaptchaValidation()) {
      e.preventDefault();
      e.stopPropagation();
    } else {
    let requestData = {
      userId: formData.userId,
      loginPassword: formData.password,
    };
    setIsLoading(true);
    dispatch(
      loginAPI(
        requestData,
        (response) => {
          setIsLoading(false)
          toast.success(`Logged in as ${formData.userId}`, {
            theme: 'colored',
            autoClose: 10000
          });
          setLocalStorageData(response.data.data)
          setTimeout(() => {
            window.location.href = "/dashboard";
          }, 1000);
        },
        (err) => {
          setIsLoading(false)
          toast.error(err?.data.message, {
            theme: 'colored',
            autoClose: 10000
          });
        }
      )
    );
  }
    
  setValidated(true);    
  };

  const setLocalStorageData = (data) => {
    let isRememberMe = $('#rememberMe').prop('checked');
    let expiration = isRememberMe ? new Date().setFullYear(new Date().getFullYear() + 1) : new Date().getTime() + 30 * 60 * 1000;
    const config = {
      value: data.token,
      expiry: expiration,
    }

    if (isRememberMe) {
      localStorage.setItem('Token', JSON.stringify(config)); // Save in localStorage
    } else {
      sessionStorage.setItem('Token', JSON.stringify(config)); // Save in sessionStorage
    }
    
    localStorage.setItem('EncryptedSecurityUserId', data.encryptedSecurityUserId); 
    localStorage.setItem('LoginUserName', data.loginUserName);
    localStorage.setItem('LoginUserEmailId', data.loginUserEmailId);
    localStorage.setItem('LoginUserMobileNumber', data.loginUserMobileNumber);
    localStorage.setItem('EncryptedAdvocateCode', data.encryptedAdvocateCode);
    localStorage.setItem('UserRoles', JSON.stringify(data.roles))
  };

  const handleCaptchaValidation = () => {
    let isValid = true;
    var captchaResponse = $("form").find("[name='g-recaptcha-response']").first().val();

    if (!captchaResponse) {
      isValid = false;
      setValidated(true);
      setCaptchaErr("Please verify captcha");
    }
    else
      setCaptchaErr('');

    return isValid;
  }

  const handleFieldChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  return (

    <>
      {isLoading ? (
        <Spinner
          className="position-absolute start-50 loader-color"
          animation="border"
        />
      ) : null}

      <Form noValidate validated={validated} onSubmit={e => { handleSubmit(e) }}>
        <Form.Group className="mb-3">
          {hasLabel && <Form.Label>username, email or phone number</Form.Label>}
          <Form.Control
            placeholder={'Username, email or phone number'}
            value={formData.userId}
            name="userId"
            maxLength="50"
            onChange={handleFieldChange}
            type="text"
            required
          />
          <Form.Control.Feedback type="invalid">
            Please provide username, email or phone number
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="mb-3">
          {hasLabel && <Form.Label>Password</Form.Label>}
          <Form.Control
            placeholder={hasLabel ? 'Password' : ''}
            value={formData.password}
            name="password"
            onChange={handleFieldChange}
            type="password"
            required
          />

          <Form.Control.Feedback type="invalid">
            Please provide password
          </Form.Control.Feedback>
        </Form.Group>

        <Row className="justify-content-between align-items-center">
          <Col xs="auto">
            <Form.Check type="checkbox" id="rememberMe" className="mb-0">
              <Form.Check.Input
                type="checkbox"
                name="remember"
                checked={formData.remember}
                onChange={e =>
                  setFormData({
                    ...formData,
                    remember: e.target.checked
                  })
                }
              />
              <Form.Check.Label className="mb-0 text-700">
                Remember me
              </Form.Check.Label>
            </Form.Check>
          </Col>

          <Col xs="auto">
            <Link
              className="fs--1 mb-0"
              to={`/forgot-password`}
            >
              Forgot Password?
            </Link>
          </Col>
        </Row>

        <Form.Group className="mt-3">
          <ReCAPTCHA
             sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
          />
          { captchaErr ? <p className="form-error">{captchaErr} </p> : null }
        </Form.Group>
        
        <Form.Group>
        {/* <Link to={`/dashboard`}> */}
          <Button type="submit" color="primary" className="mt-3 w-100">
        
            Log in
     
          </Button>
          {/* </Link> */}
        </Form.Group>
      </Form>
    </>
  );
};

LoginForm.propTypes = {
  layout: PropTypes.string,
  hasLabel: PropTypes.bool
};

LoginForm.defaultProps = {
  layout: 'simple',
  hasLabel: false
};

export default LoginForm;