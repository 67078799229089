import request from "components/Utils/Request";
export const ADD_SECURITY_MENU_ROLE_ASSCOCIATION_REQUEST = "ADD_SECURITY_MENU_ROLE_ASSCOCIATION_REQUEST";
export const ADD_SECURITY_MENU_ROLE_ASSCOCIATION_SUCCESS = "ADD_SECURITY_MENU_ROLE_ASSCOCIATION_SUCCESS";
export const ADD_SECURITY_MENU_ROLE_ASSCOCIATION_FAILURE = "ADD_SECURITY_MENU_ROLE_ASSCOCIATION_FAILURE";
export const  DELETE_SECURITY_MENU_ROLE_ASSCOCIATION_REQUEST = " DELETE_SECURITY_MENU_ROLE_ASSCOCIATION_REQUEST";
export const  DELETE_SECURITY_MENU_ROLE_ASSCOCIATION_SUCCESS = " DELETE_SECURITY_MENU_ROLE_ASSCOCIATION_SUCCESS";
export const  DELETE_SECURITY_MENU_ROLE_ASSCOCIATION_FAILURE = " DELETE_SECURITY_MENU_ROLE_ASSCOCIATION_FAILURE";
const { REACT_APP_API_URL } = process.env;

export const addSecurityMenuRoleAsscociationDetailsRequest = () => ({
	type: ADD_SECURITY_MENU_ROLE_ASSCOCIATION_REQUEST
});
export const addSecurityMenuRoleAsscociationDetailsSuccess = (payload) => ({
	type: ADD_SECURITY_MENU_ROLE_ASSCOCIATION_SUCCESS,
	payload
});
export const addSecurityMenuRoleAsscociationDetailsFailure = (payload) => ({
	type: ADD_SECURITY_MENU_ROLE_ASSCOCIATION_FAILURE,
	payload
})

export const deleteSecurityMenuRoleAsscociationRequest = () => ({
    type: DELETE_SECURITY_MENU_ROLE_ASSCOCIATION_REQUEST
});
export const deleteSecurityMenuRoleAsscociationSuccess = (payload) => ({
    type: DELETE_SECURITY_MENU_ROLE_ASSCOCIATION_SUCCESS,
    payload,
});
export const deleteSecurityMenuRoleAsscociationFailure = (payload) => ({
    type: DELETE_SECURITY_MENU_ROLE_ASSCOCIATION_FAILURE,
    payload,
});

export const selectedMenusAction = (payload) => {
    return {
      type: 'SELECTEDMENUS',
      payload
    };
  };
  
export const addSecurityMenuRoleAsscociationAPI = (payload, successData, errorMsg, headers = {}) => {
	return (dispatch) => {
		dispatch(addSecurityMenuRoleAsscociationDetailsRequest());
		return request("POST", `${REACT_APP_API_URL + "/add-menu-role-asscociation"}`, payload, "", headers)
			.then((response) => {
				if (response?.data?.status === 200) {
					successData(response);
					dispatch(addSecurityMenuRoleAsscociationDetailsSuccess(response));
				} else {
					errorMsg(response);
				}

			}).catch((error) => {
				errorMsg(error?.response);
				dispatch(addSecurityMenuRoleAsscociationDetailsFailure("Something Went Wrong"));
				throw error;
			});
	};
};

export const deleteSecurityMenuRoleAsscociationAPI = (payload, successData, errorMsg, headers = {}) => {
    return (dispatch) => {
        const url = `${REACT_APP_API_URL}/delete-security-role-menu-asssciation-detail`;
        dispatch(deleteSecurityMenuRoleAsscociationRequest());
        return request("DELETE", url, payload, "", headers)
            .then((res) => {
                if (res?.data.status === 200) {
                    successData(res);
                    dispatch(deleteSecurityMenuRoleAsscociationSuccess(res));
                } else {
                    errorMsg(res);
                }
            }).catch((error => {
                errorMsg(error?.res);
                dispatch(deleteSecurityMenuRoleAsscociationFailure("Something went wrong"));
                throw error;
            }
        ));
    }
}