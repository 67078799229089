
import {
  SECURITY_ROLE_MASTER_REQUEST,
  SECURITY_ROLE_MASTER_SUCCESS,
  SECURITY_ROLE_MASTER_FAILURE,
  ADD_ROLE_MASTER_REQUEST,
  ADD_ROLE_MASTER_SUCCESS,
  ADD_ROLE_MASTER_FAILURE,
  UPDATE_ROLE_MASTER_REQUEST,
  UPDATE_ROLE_MASTER_SUCCESS,
  UPDATE_ROLE_MASTER_FAILURE
} from "../../actions/SecurityRoleMaster/securityRoleMasterAction";

const initialState = {
    roleList: [],
    roleData:{},
    roleDetail:{}
};

export default function securityRoleMasterReducer(state = initialState, action) {
    switch (action.type) {
        case SECURITY_ROLE_MASTER_REQUEST:
            return {
                ...state,
            };
        case SECURITY_ROLE_MASTER_SUCCESS:
            return {
                ...state,
                roleList: action.payload,
            };
        case SECURITY_ROLE_MASTER_FAILURE:
            return {
                ...state,
                error: action.payload,
            };
            case ADD_ROLE_MASTER_REQUEST:
                return {
                    ...state,
                };
            case ADD_ROLE_MASTER_SUCCESS:
                return {
                    ...state,
                    roleDetail: action.payload,
                };
            case ADD_ROLE_MASTER_FAILURE:
                return {
                    ...state,
                    error: action.payload,
                };
                case UPDATE_ROLE_MASTER_REQUEST:
                    return {
                        ...state,
                    };
                case UPDATE_ROLE_MASTER_SUCCESS:
                    return {
                        ...state,
                        roleDetail: action.payload,
                    };
                case UPDATE_ROLE_MASTER_FAILURE:
                    return {
                        ...state,
                        error: action.payload,
                    };
                case 'ROLEDATA':
                    if (!action.payload) {
                        return initialState;
                    } else {
                        return {
                            ...state,
                            roleData: action.payload
                        };
                    }
                case 'ROLEDETAIL':
                    if (!action.payload) {
                        return initialState;
                    } else {
                        return {
                            ...state,
                            roleDetail: action.payload
                        };
                    }
        default:
            return state;
    }
}