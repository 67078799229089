import request from "components/Utils/Request";
export const ADD_STATE_MASTER_REQUEST = "ADD_STATE_MASTER_REQUEST";
export const ADD_STATE_MASTER_SUCCESS = "ADD_STATE_MASTER_SUCCESS";
export const ADD_STATE_MASTER_FAILURE = "ADD_STATE_MASTER_FAILURE";
export const UPDATE_STATE_MASTER_REQUEST = "UPDATE_STATE_MASTER_REQUEST";
export const UPDATE_STATE_MASTER_SUCCESS = "UPDATE_STATE_MASTER_SUCCESS";
export const UPDATE_STATE_MASTER_FAILURE = "UPDATE_STATE_MASTER_FAILURE";
export const STATE_MASTER_LIST_REQUEST = "STATE_MASTER_LIST_REQUEST";
export const STATE_MASTER_LIST_SUCCESS = "STATE_MASTER_LIST_SUCCESS";
export const STATE_MASTER_LIST_FAILURE = "STATE_MASTER_LIST_FAILURE";
export const DELETE_STATE_MASTER_REQUEST = "DELETE_STATE_MASTER_REQUEST";
export const DELETE_STATE_MASTER_SUCCESS = "DELETE_STATE_MASTER_SUCCESS";
export const DELETE_STATE_MASTER_FAILURE = "DELETE_STATE_MASTER_FAILURE";

const { REACT_APP_API_URL } = process.env;

export const addStateMasterRequest = () => ({
    type: ADD_STATE_MASTER_REQUEST
});
export const addStateMasterSuccess = (payload) => ({
    type: ADD_STATE_MASTER_SUCCESS,
    payload
});
export const addStateMasterFailure = (payload) => ({
    type: ADD_STATE_MASTER_FAILURE,
    payload
})

export const updateStateMasterRequest = () => ({
    type: UPDATE_STATE_MASTER_REQUEST
});
export const updateStateMasterSuccess = (payload) => ({
    type: UPDATE_STATE_MASTER_SUCCESS,
    payload
});
export const updateStateMasterFailure = (payload) => ({
    type: UPDATE_STATE_MASTER_FAILURE,
    payload
})

export const deleteStateMasterRequest = () => ({
    type: DELETE_STATE_MASTER_REQUEST
});
export const deleteStateMasterSuccess = (payload) => ({
    type: DELETE_STATE_MASTER_SUCCESS,
    payload
});
export const deleteStateMasterFailure = (payload) => ({
    type: DELETE_STATE_MASTER_FAILURE,
    payload
})

export const stateMasterListRequest = () => ({
    type: STATE_MASTER_LIST_REQUEST
});
export const stateMasterListSuccess = (payload) => ({
    type: STATE_MASTER_LIST_SUCCESS,
    payload
});
export const stateMasterListFailure = (payload) => ({
    type: STATE_MASTER_LIST_FAILURE,
    payload
})


export const stateMasterDetailAction = payload => {
    return {
      type: 'STATEMASTERDATA',
      payload
    };
  };

  export const stateDetailsErrorAction = payload => {
    return {
      type: 'STATEDETALERROR',
      payload
    };
  };

export const stateListAPI = (payload, successData, errorMSg) => {
    return (dispatch) => {
        dispatch(stateMasterListRequest());
        const url = `${REACT_APP_API_URL}/state-list`;
        return request("POST", url, payload)
            .then((res) => {
                if (res?.data.status === 200) {
                    successData(res.data.data)
                    dispatch(stateMasterListSuccess(res.data.data));
                } else {
                    errorMSg(res)
                }
            }).catch((err) => {
                errorMSg(err?.res);
                dispatch(stateMasterListFailure("Something went wrong"));
                throw err;
            })
    }
}

export const addStateMasterAPI = (payload, successData, errorMsg, headers = {}) => {
    return (dispatch) => {
        const url = `${REACT_APP_API_URL}/add-state-master`;
        dispatch(addStateMasterRequest());
        return request("POST", url, payload, "", headers)
            .then((res) => {
                if (res?.data.status === 200) {
                    successData(res);
                    dispatch(addStateMasterSuccess(res));
                } else {
                    errorMsg(res);
                }
            }).catch((error => {
                errorMsg(error?.res);
                dispatch(addStateMasterFailure("Something went wrong"));
                throw error;
            }
        ));
    }
}

export const updateStateMasterAPI = (payload, successData, errorMsg, headers = {}) => {
    return (dispatch) => {
        const url = `${REACT_APP_API_URL}/update-state-master`;
        dispatch(updateStateMasterRequest());
        return request("POST", url, payload, "", headers)
            .then((res) => {
                if (res?.data.status === 200) {
                    successData(res);
                    dispatch(updateStateMasterSuccess(res));
                } else {
                    errorMsg(res);
                }
            }).catch((error => {
                errorMsg(error?.res);
                dispatch(updateStateMasterFailure("Something went wrong"));
                throw error;
            }
        ));
    }
}

export const deleteStateMasterDetailAPI = (payload, successData, errorMsg, headers = {}) => {
    return (dispatch) => {
        const url = `${REACT_APP_API_URL}/delete-state-master-detail`;
        dispatch(deleteStateMasterRequest());
        return request("DELETE", url, payload, "", headers)
            .then((res) => {
                if (res?.data.status === 200) {
                    successData(res);
                    dispatch(deleteStateMasterSuccess(res));
                } else {
                    errorMsg(res);
                }
            }).catch((error => {
                errorMsg(error?.res);
                dispatch(deleteStateMasterFailure("Something went wrong"));
                throw error;
            }
        ));
    }
}