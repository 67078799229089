import {
    ADD_CASE_NEXT_DATE_REQUEST,
    ADD_CASE_NEXT_DATE_SUCCESS,
    ADD_CASE_NEXT_DATE_FAILURE
} from 'actions/CaseNextDate/caseNextDateAction';
const initialState = {
    caseNextDateData: {},
    caseNextDateDetails: {}
};

export default function caseNextDateReducer(
    state = initialState,
    action
) {
    switch (action.type) {
        case ADD_CASE_NEXT_DATE_REQUEST:
            return {
                ...state
            };
        case ADD_CASE_NEXT_DATE_SUCCESS:
            return {
                ...state,
                caseNextDateDetails: action.payload
            };
        case ADD_CASE_NEXT_DATE_FAILURE:
            return {
                ...state,
                error: action.payload
            };
        case 'CASENEXTDATEDATA':
            if (!action.payload) {
                return initialState;
            } else {
                return {
                    ...state,
                    caseNextDateData: action.payload
                };
            }
        case 'CASENEXTDATEDETAILS':
            if (!action.payload) {
                return initialState;
            } else {
                return {
                    ...state,
                    caseNextDateDetails: action.payload
                };
            }
        default:
            return state;
    }
}