const initialState = {
    courtTypeDetailsError: {
      courtTypeNameErr: {},
    }
  };
  const courtTypeMasterErrorReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'COURTTYPEDETAILSERROR':
        if (!action.payload) {
          return initialState;
        } else {
          return {
            ...state,
            courtTypeDetailsError: action.payload
          };
        }
      default:
        return state;
    }
  };
  
  export default courtTypeMasterErrorReducer;