import request from 'components/Utils/Request';
const { REACT_APP_API_URL } = process.env;

export const GET_LITIGATION_TYPES_MASTER_LIST_REQUEST = 'GET_LITIGATION_TYPES_MASTER_LIST_REQUEST';
export const GET_LITIGATION_TYPES_MASTER_LIST_SUCCESS = 'GET_LITIGATION_TYPES_MASTER_LIST_SUCCESS';
export const GET_LITIGATION_TYPES_MASTER_LIST_FAILURE = 'GET_LITIGATION_TYPES_MASTER_LIST_FAILURE';

  export const getLitigationTypeMasterListRequest = () => ({
    type: GET_LITIGATION_TYPES_MASTER_LIST_REQUEST
  });
  export const getLitigationTypeMasterListSuccess = payload => ({
    type: GET_LITIGATION_TYPES_MASTER_LIST_SUCCESS,
    payload
  });
  export const getLitigationTypeMasterListFailure = payload => ({
    type: GET_LITIGATION_TYPES_MASTER_LIST_FAILURE,
    payload
  });

  export const getLitigationTypeMasterListAPI = (payload, successData, errorMSg, headers = {}) => {
    return dispatch => {
      dispatch(getLitigationTypeMasterListRequest());
      return request(
        'GET',
        `${REACT_APP_API_URL + '/litigation-list'}`,
        payload,
        "",
        headers
      )
        .then(res => {
          if (res?.data.status === 200) {
            successData(res);
            dispatch(getLitigationTypeMasterListSuccess(res.data.data.litigationList));
          } else {
            errorMSg(res);
          }
        })
        .catch(err => {
          errorMSg(err?.res);
          dispatch(getLitigationTypeMasterListFailure('Something went wrong'));
          throw err;
        });
    };
  };