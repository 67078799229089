import React, { useEffect, useState } from 'react';
import TabPage from 'components/common/TabPage';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import $ from 'jquery';
import { Spinner, Modal, Button } from 'react-bootstrap';
import { formChangedAction} from 'actions';
import { addRoleMasterAPI, roleDetailAction, roleDetailErrorAction, roleMasterListAPI, updateRoleMasterAPI } from 'actions/SecurityRoleMaster/securityRoleMasterAction';
import { addSecurityMenuRoleAsscociationAPI, deleteSecurityMenuRoleAsscociationAPI, selectedMenusAction } from 'actions/SecurityMenuRoleAsscociation/securityMenuRoleAssociation';

const tabArray = ['Role List', 'Add Role'];

const listColumnArray = [
  { accessor: 'sl', Header: 'S. No' },
  { accessor: 'roleId', Header: 'Role Id' },
  { accessor: 'roleName', Header: 'Role Name' },
  { accessor: 'status', Header: 'Active Status' },
];

export const SecurityRoleMaster = () => {
  const dispatch = useDispatch();
  const [roleMasterList, setRoleMasterList] = useState([]);
  const [perPage, setPerPage] = useState(15);
  const [isLoading, setIsLoading] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [formHasError, setFormError] = useState(false);
  const [activeTabName, setActiveTabName] = useState();

  const selectedMenusReducer = useSelector(
    state => state.rootReducer.selectedMenusReducer
  );
  var selectedMenuItems = selectedMenusReducer.selectedMenus;

  let token = localStorage.getItem('Token') || sessionStorage.getItem('Token');
  const headers = {
    Authorization: `Bearer ${JSON.parse(token).value}`
  };

  const getRoleMasterList = async (page, size = perPage) => {
    const data = {
      pageNumber: page,
      pageSize: size,
      SearchText: '',
    };
    setIsLoading(true);
   await dispatch(
      roleMasterListAPI(
        data,
        response => {
          setIsLoading(false);
          setRoleMasterList(response.data.data.roleList);
        },
        err => {
          setIsLoading(false)
          setRoleMasterList([]);
          console.log(err?.data.message);
        },
        headers
      )
    );
  };

  useEffect(() => {
    $('[data-rr-ui-event-key*="Add Role"]').attr('disabled', true);
    getRoleMasterList(1);
  }, []);

  const securityRoleMasterReducer = useSelector(
    state => state.rootReducer.securityRoleMasterReducer
  );
  const roleData = securityRoleMasterReducer.roleData;

  const formChangedReducer = useSelector(
    state => state.rootReducer.formChangedReducer
  );
  var formChangedData = formChangedReducer.formChanged;

  let isFormChanged = Object.values(formChangedData).some(
    value => value === true
  );

  const clearRoleReducers = () => {
    dispatch(roleDetailAction(undefined));
    dispatch(roleDetailErrorAction(undefined));
    dispatch(formChangedAction(undefined));
    dispatch(selectedMenusAction([]));
  };

  $('[data-rr-ui-event-key*="Role List"]')
    .off('click')
    .on('click', function () {
      let isDiscard = $('#btnDiscard').attr('isDiscard');
      if (isDiscard != 'true' && isFormChanged) {
        setModalShow(true);
        setTimeout(function () {
          $('[data-rr-ui-event-key*="' + activeTabName + '"]').trigger('click');
        }, 50);
      } else {
        $('#btnNew').show();
        $('#btnSave').hide();
        $('#btnCancel').hide();
        localStorage.removeItem('EncryptedResponseRoleId');
        $('[data-rr-ui-event-key*="Add Role"]').attr('disabled', true);
        $('#AddRoleDetailsForm').get(0).reset();
        getRoleMasterList(1)
        $('#btnDiscard').attr('isDiscard', false);
        clearRoleReducers();
      }
    });

  $('[data-rr-ui-event-key*="Add Role"]')
    .off('click')
    .on('click', function () {
      setActiveTabName('Add Role');
      $('#btnNew').hide();
      $('#btnSave').show();
      $('#btnCancel').show();
    });

  const newDetails = () => {
    $('[data-rr-ui-event-key*="Add Role"]').attr('disabled', false);
    $('[data-rr-ui-event-key="Add Role"]').trigger('click');
    $('#btnSave').attr('disabled', false);
    clearRoleReducers();
  };

  const cancelClick = () => {
    $('#btnExit').attr('isExit', 'false');
    if (isFormChanged) {
      setModalShow(true);
    } else {
      $('[data-rr-ui-event-key*="Role List"]').trigger('click');
    }
  };

  const exitModule = () => {
    $('#btnExit').attr('isExit', 'true');
    if (isFormChanged) {
      setModalShow(true);
    } else {
      window.location.href = '/dashboard';
    }
  };

  const discardChanges = () => {
    $('#btnDiscard').attr('isDiscard', 'true');
    if ($('#btnExit').attr('isExit') == 'true')
      window.location.href = '/dashboard';
    else $('[data-rr-ui-event-key*="List"]').trigger('click');

    setModalShow(false);
  };

  const roleValidation = () => {
    const roleNameErr = {};
    const selectedMenuItemsErr = {};

    let isValid = true;

    if (!roleData.roleName) {
      roleNameErr.empty = 'Enter role name';
      isValid = false;
      setFormError(true);
    }

    if (selectedMenuItems.length <= 0) {
      selectedMenuItemsErr.selectedMenuItemsEmpty =
        'Select atleast one item';
      isValid = false;
      setFormError(true);
      toast.error('Select atleast one menu tree item', {
        theme: 'colored',
        autoClose: 10000
      });
    }

    if (!isValid) {
      var errorObject = {
        roleNameErr,
      };
      dispatch(roleDetailErrorAction(errorObject));
    }

    return isValid;
  };

  const addRoleMasterDetails = async () => {
    if (roleValidation()) {

      let uniqueTreeIds = [...new Set(selectedMenuItems)]

      const requestData = {
        roleName: roleData.roleName,
        status: roleData.status == null || roleData.status == "Active" ? "A" : "S",
        addUser: localStorage.getItem("LoginUserName"),
        treeIds: uniqueTreeIds
      }

      const keys = ['roleName', 'addUser']
      for (const key of Object.keys(requestData).filter((key) => keys.includes(key))) {
        requestData[key] = requestData[key] ? requestData[key].toUpperCase() : '';
      }

      setIsLoading(true);
     await dispatch(
        addRoleMasterAPI(
          requestData,
          (response) => {
            setIsLoading(false);
            setTimeout(function () {
              dispatch(roleDetailAction({
                ...roleData,
                encryptedRoleId: response.data.data.encryptedRoleId,
                treeIds: uniqueTreeIds
              }))
            }, 50);
            toast.success(` ${response.data.message}`, {
              theme: 'colored',
              autoClose: 10000
            });
            updateCallback(true);
            setModalShow(false);
            localStorage.setItem("EncryptedResponseRoleId", response.data.data.encryptedRoleId)
          },
          (err) => {
            setIsLoading(false)
            toast.error(err?.data.message, {
              theme: 'colored',
              autoClose: 10000
            });
          },
          headers
        )
      );
    }
  }
  const updateCallback = (isAddRole = false) => {
    setModalShow(false);
    dispatch(roleDetailErrorAction(undefined));
    dispatch(formChangedAction(undefined));

    if (!isAddRole) {
      toast.success('Role updated successfully!', {
        theme: 'colored'
      });
    } 

    $('#btnSave').attr('disabled', true);
    getRoleMasterList(1);

    $('[data-rr-ui-event-key*="' + activeTabName + '"]').trigger('click');
  };


  const updateRoleDetails = async () => {
    let addRoleModelDetailIds = [];
    let deleteRoleMenuDetailIds = [];

    if (roleValidation()) {

      let uniqueTreeIds = [...new Set(selectedMenuItems)]

      if (roleData.treeIds && roleData.treeIds.length > 0) {
        for (let i = 0; i < uniqueTreeIds.length; i++) {
          if (!roleData.treeIds.includes(uniqueTreeIds[i])) {
            addRoleModelDetailIds.push(uniqueTreeIds[i]);
          }
        }
        for (let i = 0; i < roleData.treeIds.length; i++) {
          if (!uniqueTreeIds.includes(roleData.treeIds[i])) {
            deleteRoleMenuDetailIds.push(roleData.treeIds[i]);
          }
        }

      } else if (uniqueTreeIds.length > 0) {
        addRoleModelDetailIds = uniqueTreeIds;
      }

      const updatedRoleData = {
        encryptedRoleId: roleData.encryptedRoleId,
        roleName: roleData.roleName,
        status: !roleData.status || roleData.status == "Active" ? "A" : "S",
        ModifyUser: localStorage.getItem("LoginUserName")
      }

      const keys = ['roleName', 'ModifyUser']
      for (const key of Object.keys(updatedRoleData).filter((key) => keys.includes(key))) {
        updatedRoleData[key] = updatedRoleData[key] ? updatedRoleData[key].toUpperCase() : '';
      }

      var hasError = false;

      if (formChangedData.roleUpdate) {
        setIsLoading(true);
      await  dispatch(
          updateRoleMasterAPI(
            updatedRoleData,
            (response) => {
              setIsLoading(false)
              setModalShow(false);
            },
            (err) => {
              setIsLoading(false)
              toast.error(err?.data.message, {
                theme: 'colored',
                autoClose: 10000
              });
              hasError = true;
            },
            headers
          )
        );

      }
      if (!hasError && (formChangedData.menuItemAdd || formChangedData.menuItemDelete)) {
        if (!hasError && formChangedData.menuItemDelete) {
          if (deleteRoleMenuDetailIds) {
            var deleteRoleMenuDetailIndex = 1;
            for (let i = 0; i < deleteRoleMenuDetailIds.length; i++) {
              const deleteRoleMenuDetailId = deleteRoleMenuDetailIds[i]
              const data = {
                encryptedRoleId: localStorage.getItem("EncryptedResponseRoleId"),
                treeId: deleteRoleMenuDetailId
              }
            await  dispatch(
                deleteSecurityMenuRoleAsscociationAPI(
                  data,
                  response => {
                    console.log(response.data.message);
                  },
                  err => {
                    toast.error(err?.data.message, {
                      theme: 'colored',
                      autoClose: 10000
                    });
                    hasError = true;
                  },
                  headers
                )
              );
              if (hasError) break;
            }
            deleteRoleMenuDetailIndex++
          }
        }

        var roleDetailIndex = 1;
        for (let i = 0; i < addRoleModelDetailIds.length; i++) {
          const treeId = addRoleModelDetailIds[i];

          if (formChangedData.menuItemAdd) {
            const requestData = {
              encryptedRoleId: localStorage.getItem("EncryptedResponseRoleId"),
              treeId: treeId,
              addUser: localStorage.getItem("LoginUserName").toUpperCase()
            }
            setIsLoading(true);

            await dispatch(
              addSecurityMenuRoleAsscociationAPI(
                requestData,
                res => {
                  setIsLoading(false)
                  setModalShow(false);
                },
                err => {
                  setIsLoading(false)
                  toast.error(err?.data.message, {
                    theme: 'colored',
                    autoClose: 10000
                  });
                  hasError = true;
                },
                headers
              )
            );
            if (hasError) break;
          }
          roleDetailIndex++
        }
      }

      if (!hasError) {
        updateCallback(false);
        dispatch(roleDetailAction({
          ...roleData,
          treeIds: uniqueTreeIds
        }))
      }
    }
  }

  return (
    <>
      {isLoading ? (
        <Spinner
          className="position-absolute start-50 loader-color"
          animation="border"
        />
      ) : null}

      {modalShow && (
        <Modal
          show={modalShow}
          onHide={() => setModalShow(false)}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          backdrop="static"
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Confirmation
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h4>Do you want to save changes?</h4>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="success"
              onClick={
                !roleData.encryptedRoleId
                  ? addRoleMasterDetails
                  : updateRoleDetails
              }
            >
              Save
            </Button>
            <Button
              variant="danger"
              id="btnDiscard"
              onClick={() => discardChanges()}
            >
              Discard
            </Button>
          </Modal.Footer>
        </Modal>
      )}

      <TabPage
        listData={roleMasterList}
        listColumnArray={listColumnArray}
        tabArray={tabArray}
        module="SecurityRoleMaster"
        saveDetails={
          roleData.encryptedRoleId
            ? updateRoleDetails
            : addRoleMasterDetails
        }
        newDetails={newDetails}
        cancelClick={cancelClick}
        exitModule={exitModule}
      />
    </>
  );
};

export default SecurityRoleMaster;
