
const initialState = {
    stateDetailsError: {
      stateMasterDetailErr: {},
    }
  };
  
  const stateMasterErrorReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'STATEDETALERROR':
        if (!action.payload) {
          return initialState;
        } else {
          return {
            ...state,
            stateDetailsError: action.payload
          };
        }
      default:
        return state;
    }
  };
  
  export default stateMasterErrorReducer;