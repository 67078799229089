import React, { useEffect, useState } from 'react';
import TabPage from 'components/common/TabPage';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import $ from 'jquery';
import { Spinner, Modal, Button } from 'react-bootstrap';
import { formChangedAction } from 'actions';
import { advocateUpdateDetailsAPI } from 'actions/AdvocateRegistration/advocateRegistrationAction';
import {
  clientDetailsAction,
  clientDetailsErrorAction,
  clientListAPI,
  clientRegistrationAPI,
  clientUpdateDetailsAPI
} from 'actions/ClientRegistration/ClientRegistrationAction.js';

const tabArray = ['Client List', 'Client Details'];

const listColumnArray = [
  { accessor: 'sl', Header: 'S. No' },
  { accessor: 'clientCode', Header: 'Client Code' },
  { accessor: 'clientName', Header: 'Client Name' },
  { accessor: 'contactPerson', Header: 'Contact Person' },
  { accessor: 'mobileNo', Header: 'Mobile No' },
  { accessor: 'stateName', Header: 'State Name' }
];

export const Client = () => {
  const dispatch = useDispatch();
  const [clientList, setClientList] = useState([]);
  const [perPage, setPerPage] = useState(15);
  const [isLoading, setIsLoading] = useState(false);
  const [modalShow, setModalShow] = useState(false);

  let token = localStorage.getItem('Token') || sessionStorage.getItem('Token');
  const headers = {
    Authorization: `Bearer ${JSON.parse(token).value}`
  };

  const getClientList = (page, size = perPage) => {
    const data = {
      pageNumber: page,
      pageSize: size,
      SearchText: '',
      EncryptedAdvocateCode: localStorage.getItem('EncryptedAdvocateCode')
    };
    setIsLoading(true);
    dispatch(
      clientListAPI(
        data,
        response => {
          setIsLoading(false);
          setClientList(response.data.data.clientList);
        },
        err => {
          setIsLoading(false)
          setClientList([]);
          console.log(err?.data.message);
        },
        headers
      )
    );
  };

  useEffect(() => {
    getClientList(1);
  }, []);

  const clientDetailReducer = useSelector(
    state => state.rootReducer.clientRegistrationReducer
  );
  var clientData = clientDetailReducer.clientData;

  const formChangedReducer = useSelector(
    state => state.rootReducer.formChangedReducer
  );
  var formChangedData = formChangedReducer.formChanged;

  let isFormChanged = Object.values(formChangedData).some(
    value => value === true
  );

  const [formHasError, setFormError] = useState(false);
  const [activeTabName, setActiveTabName] = useState();

  const clearClientReducers = () => {
    dispatch(clientDetailsAction(undefined));
    dispatch(clientDetailsErrorAction(undefined));
    dispatch(formChangedAction(undefined));
  };

  $('[data-rr-ui-event-key*="Client List"]')
    .off('click')
    .on('click', function () {
      let isDiscard = $('#btnDiscard').attr('isDiscard');
      if (isDiscard != 'true' && isFormChanged) {
        setModalShow(true);
        setTimeout(function () {
          $('[data-rr-ui-event-key*="' + activeTabName + '"]').trigger('click');
        }, 50);
      } else {
        $('#btnNew').show();
        $('#btnSave').hide();
        $('#btnCancel').hide();
        $('[data-rr-ui-event-key*="Details"]').attr('disabled', true);
        $('[data-rr-ui-event-key*="Client List"]').attr('disabled', false);
        $('#addClientDetailsForm').get(0).reset();
        getClientList(1)
        localStorage.removeItem('EncryptedResponseClientCode');
        $('#btnDiscard').attr('isDiscard', false);
        clearClientReducers();
      }
    });

  $('[data-rr-ui-event-key*="Client Details"]')
    .off('click')
    .on('click', function () {
      setActiveTabName('Client Details');
      $('#btnNew').hide();
      $('#btnSave').show();
      $('#btnCancel').show();
    });

  const newDetails = () => {
    $('[data-rr-ui-event-key*="Details"]').attr('disabled', false);
    $('[data-rr-ui-event-key="Client Details"]').trigger('click');
    $('#btnSave').attr('disabled', false);
    clearClientReducers();
  };

  const cancelClick = () => {
    $('#btnExit').attr('isExit', 'false');
    if (isFormChanged) {
      setModalShow(true);
    } else {
      $('[data-rr-ui-event-key*="Client List"]').trigger('click');
    }
  };

  const exitModule = () => {
    $('#btnExit').attr('isExit', 'true');
    if (isFormChanged) {
      setModalShow(true);
    } else {
      window.location.href = '/dashboard';
    }
  };

  const discardChanges = () => {
    $('#btnDiscard').attr('isDiscard', 'true');
    if ($('#btnExit').attr('isExit') == 'true')
      window.location.href = '/dashboard';
    else $('[data-rr-ui-event-key*="List"]').trigger('click');

    setModalShow(false);
  };

  const clientValidation = () => {
    const clientNameErr = {};
    const emailErr = {};
    const countryErr = {};
    const stateErr = {};
    const mobileNoErr = {};
    const contactPersonErr = {};
    const clientTypeErr = {};
    const genderErr = {};

    let isValid = true;

    if (!clientData.clientName) {
      clientNameErr.nameEmpty = 'Enter client name';
      isValid = false;
      setFormError(true);
    }

    if (!clientData.contactPerson) {
      contactPersonErr.Empty = 'Enter contact person';
      isValid = false;
      setFormError(true);
    }

    if (!clientData.countryCode) {
      countryErr.empty = 'Select country';
      isValid = false;
      setFormError(true);
    }

    if (!clientData.stateCode) {
      stateErr.empty = 'Select state';
      isValid = false;
      setFormError(true);
    }

    if (!clientData.mobileNo) {
      mobileNoErr.mobileNoErr = 'Enter mobile no';
      isValid = false;
      setFormError(true);
    } else if (!/^(?!0)[0-9]{10}$/.test(clientData.mobileNo)) {
      mobileNoErr.phoneInvalid =
        'Invalid mobile number, number should be of 10 digits should not start with 0';
      isValid = false;
      setFormError(true);
    }

    if (!clientData.emailId) {
      emailErr.empty = 'Enter email address';
      isValid = false;
      setFormError(true);
    } else if (
      !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(clientData.emailId)
    ) {
      emailErr.invalid = 'Please enter valid email address';
      isValid = false;
      setFormError(true);
    }
    if (!clientData.clientType) {
      clientTypeErr.empty = 'Select client type';
      isValid = false;
      setFormError(true);
    } else if (clientData.clientType == 'INDIVIDUAL' && !clientData.gender) {
      genderErr.empty = 'Select gender';
      isValid = false;
      setFormError(true);
    }

    if (!isValid) {
      var errorObject = {
        clientNameErr,
        emailErr,
        countryErr,
        stateErr,
        mobileNoErr,
        clientTypeErr,
        genderErr,
        contactPersonErr
      };
      dispatch(clientDetailsErrorAction(errorObject));
    }

    return isValid;
  };

  const addClientDetails = async () => {
    if (clientValidation()) {
      const Data = {
        EncryptedAdvocateCode: localStorage.getItem('EncryptedAdvocateCode'),
        ClientName: clientData.clientName,
        ClientAddress1: clientData.clientAddress1
          ? clientData.clientAddress1
          : '',
        ClientAddress2: clientData.clientAddress2
          ? clientData.clientAddress2
          : '',
        ClientAddress3: clientData.clientAddress3
          ? clientData.clientAddress3
          : '',
        PINCode: clientData.pinCode ? clientData.pinCode : '',
        CountryCode: clientData.countryCode,
        StateCode: clientData.stateCode,
        EmailId: clientData.emailId,
        MobileNo: clientData.mobileNo,
        Gender: clientData.gender ? clientData.gender : '',
        landlineNo: clientData.landLineNo ? clientData.landLineNo : '',
        landlineOffice: clientData.landLineOffice
          ? clientData.landLineOffice
          : '',
        ClientType: clientData.clientType,
        ContactPerson: clientData.contactPerson,
        Occupation: clientData.occupation ? clientData.occupation : '',
        SendSMS: clientData.sendSMS == true ? 'Yes' : '',
        SendMail: clientData.sendMail == true ? 'Yes' : '',
        SendWhatsapp: clientData.sendWhatsapp == true ? 'Yes' : '',
        AddUser: localStorage.getItem('LoginUserName')
      };

      const keys = [
        'ClientName',
        'ClientAddress1',
        'ClientAddress2',
        'ClientAddress3',
        'EmailId',
        'Gender',
        'ClientType',
        'Occupation',
        'ContactPerson',
        'AddUser'
      ];
      for (const key of Object.keys(Data).filter(key => keys.includes(key))) {
        Data[key] = Data[key] ? Data[key].toUpperCase() : '';
      }
      setIsLoading(true);
    await dispatch(
        clientRegistrationAPI(
          Data,
          response => {
            setIsLoading(false);
            toast.success(` ${response.data.message}`, {
              theme: 'colored',
              autoClose: 10000
            });
            updateCallback(true, response.data.data.encryptedClientCode);
            setModalShow(false);
            localStorage.setItem(
              'EncryptedResponseClientCode',
              response.data.data.encryptedClientCode
            );
          },
          err => {
            setIsLoading(false)
            toast.error(err?.data.message, {
              theme: 'colored',
              autoClose: 10000
            });
          },
          headers
        )
      );
    }
  };

  const updateCallback = (isAddClient = false, encryptedClientCode) => {
    setModalShow(false);
    dispatch(clientDetailsErrorAction(undefined));
    dispatch(formChangedAction(undefined));

    if (!isAddClient) {
      toast.success('Client Details updated successfully!', {
        theme: 'colored'
      });
    } else {
      dispatch(
        clientDetailsAction({
          ...clientData,
          encryptedClientCode: encryptedClientCode
        })
      );
    }

    $('#btnSave').attr('disabled', true);
    getClientList(1);

    $('[data-rr-ui-event-key*="' + activeTabName + '"]').trigger('click');
  };

  const updateClientDetails = async () => {
    if (clientValidation()) {
      const request = {
        EncryptedClientCode: localStorage.getItem(
          'EncryptedResponseClientCode'
        ),
        ClientName: clientData.clientName,
        ClientAddress1: clientData.clientAddress1
          ? clientData.clientAddress1
          : '',
        ClientAddress2: clientData.clientAddress2
          ? clientData.clientAddress2
          : '',
        ClientAddress3: clientData.clientAddress3
          ? clientData.clientAddress3
          : '',
        PINCode: clientData.pinCode ? clientData.pinCode : '',
        CountryCode: clientData.countryCode,
        StateCode: clientData.stateCode,
        EmailId: clientData.emailId,
        MobileNo: clientData.mobileNo,
        Gender: clientData.gender ? clientData.gender : '',
        LandLineNo: clientData.landLineNo ? clientData.landLineNo : '',
        LandLineOffice: clientData.landLineOffice
          ? clientData.landLineOffice
          : '',
        ClientType: clientData.clientType,
        ContactPerson: clientData.contactPerson,
        Occupation: clientData.occupation ? clientData.occupation : '',
        SendSMS: clientData.sendSMS == true ? 'Yes' : '',
        SendMail: clientData.sendMail == true ? 'Yes' : '',
        SendWhatsapp: clientData.sendWhatsapp == true ? 'Yes' : '',
        modifyUser: localStorage.getItem('LoginUserName')
      };

      const keys = [
        'ClientName',
        'ClientAddress1',
        'ClientAddress2',
        'ClientAddress3',
        'EmailId',
        'Gender',
        'ClientType',
        'Occupation',
        'ContactPerson',
        'AddUser'
      ];
      for (const key of Object.keys(request).filter(key =>
        keys.includes(key)
      )) {
        request[key] = request[key] ? request[key].toUpperCase() : '';
      }
      var hasError = false;
      setIsLoading(true);
      if (formChangedData.clientUpdate) {
       await dispatch(
          clientUpdateDetailsAPI(
            request,
            res => {
              setIsLoading(false);
              setModalShow(false);
            },
            err => {
              setIsLoading(false)
              toast.error(err?.data.message, {
                theme: 'colored',
                autoClose: 10000
              });
              hasError = true;
            },
            headers
          )
        );
      }

      if (!hasError) {
        updateCallback();
      }
    }
  };

  return (
    <>
      {isLoading ? (
        <Spinner
          className="position-absolute start-50 loader-color"
          animation="border"
        />
      ) : null}

      {modalShow && (
        <Modal
          show={modalShow}
          onHide={() => setModalShow(false)}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          backdrop="static"
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Confirmation
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h4>Do you want to save changes?</h4>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="success"
              onClick={
                !clientData.encryptedClientCode
                  ? addClientDetails
                  : updateClientDetails
              }
            >
              Save
            </Button>
            <Button
              variant="danger"
              id="btnDiscard"
              onClick={() => discardChanges()}
            >
              Discard
            </Button>
          </Modal.Footer>
        </Modal>
      )}

      <TabPage
        listData={clientList}
        listColumnArray={listColumnArray}
        tabArray={tabArray}
        module="Client"
        saveDetails={
          clientData.encryptedClientCode
            ? updateClientDetails
            : addClientDetails
        }
        newDetails={newDetails}
        cancelClick={cancelClick}
        exitModule={exitModule}
      />
    </>
  );
};

export default Client;
