

const initialState = {
    clientTransactionDetailsError: {
      transactionDetailErr:{}
    }
  };
  
  const clientTransactionDetailsErrorReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'CLIENTTRANSACTIONDETAILSERROR':
        if (!action.payload) {
          return initialState;
        } else {
          return {
            ...state,
            clientTransactionDetailsError: action.payload
          };
        }
      default:
        return state;
    }
  };
  
  export default clientTransactionDetailsErrorReducer;