
const initialState = {
    actionDetailsError: {
      nextActionDateErr: {},
      reminderBeforeDaysErr: {},
      actionItemErr: {},
    }
  };
  
  const actionDetailsErrorReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'ACTIONDETAILSERROR':
        if (!action.payload) {
          return initialState;
        } else {
          return {
            ...state,
            actionDetailsError: action.payload
          };
        }
      default:
        return state;
    }
  };
  
  export default actionDetailsErrorReducer;