
const initialState = {
    caseNextDateDetailsError: {
       clientNameErr: {},
       caseNoErr: {},
       newNextHearingDateErr: {},
       newCourtNameErr: {},
       newAdvocateMobileNoErr:{}
    }
  };
  
  const caseNextDateDetailsErrorReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'CASENEXTDATEDETAILSERROR':
        if (!action.payload) {
          return initialState;
        } else {
          return {
            ...state,
            caseNextDateDetailsError: action.payload
          };
        }
      default:
        return state;
    }
  };
  
  export default caseNextDateDetailsErrorReducer;