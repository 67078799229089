import {
    ADD_ACTION_DETAILS_REQUEST,
    ADD_ACTION_DETAILS_SUCCESS,
    ADD_ACTION_DETAILS_FAILURE,
    GET_ACTION_DETAILS_LIST_REQUEST,
    GET_ACTION_DETAILS_LIST_SUCCESS,
    GET_ACTION_DETAILS_LIST_FAILURE
} from 'actions/ActionDetails/actionDetailsAction';
const initialState = {
    actionDetailsData: {},
    actionsDetails: {},
    actionDetailList:[]
};

export default function actionDetailsReducer(
    state = initialState,action
) {
    switch (action.type) {
        case ADD_ACTION_DETAILS_REQUEST:
            return {
                ...state
            };
        case ADD_ACTION_DETAILS_SUCCESS:
            return {
                ...state,
                actionsDetails: action.payload
            };
        case ADD_ACTION_DETAILS_FAILURE:
            return {
                ...state,
                error: action.payload
            };
            case GET_ACTION_DETAILS_LIST_REQUEST:
                return {
                    ...state
                };
            case GET_ACTION_DETAILS_LIST_SUCCESS:
                return {
                    ...state,
                    actionDetailList: action.payload
                };
            case GET_ACTION_DETAILS_LIST_FAILURE:
                return {
                    ...state,
                    error: action.payload
                };
        case 'ACTIONDETAILSDATA':
            if (!action.payload) {
                return initialState;
            } else {
                return {
                    ...state,
                    actionDetailsData: action.payload
                };
            }
        case 'ACTIONSDETAILS':
            if (!action.payload) {
                return initialState;
            } else {
                return {
                    ...state,
                    actionsDetails: action.payload
                };
            }
        default:
            return state;
    }
}