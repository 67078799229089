import React, { useEffect, useState } from 'react';
import TabPage from 'components/common/TabPage';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import $ from 'jquery';
import { Spinner, Modal, Button } from 'react-bootstrap';
import { formChangedAction } from 'actions';
import { addCountryAPI, countryDetailAction, countryDetailsErrorAction, countryListAPI, resetCountryDeletedAction, updateCountryAPI } from 'actions/CountryMaster/countryMasterAction';
import { addStateMasterAPI, deleteStateMasterDetailAPI, stateDetailsErrorAction, stateListAPI, stateMasterDetailAction, updateStateMasterAPI } from 'actions/StateMaster/stateMasterAction';

const tabArray = ['Country List', 'Add Country'];

const listColumnArray = [
  { accessor: 'sl', Header: 'S. No' },
  { accessor: 'countryCode', Header: 'Country Code' },
  { accessor: 'countryName', Header: 'Country Name' },
  { accessor: 'deleteCountry', Header: 'Delete' },
];

export const CountryMaster = () => {
  const dispatch = useDispatch();
  const [perPage, setPerPage] = useState(15);
  const [isLoading, setIsLoading] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [countryList, setCountryList] = useState([]);
  var responseCountryCode = localStorage.getItem("EncryptedResponseCountryCode")

  let token = localStorage.getItem('Token') || sessionStorage.getItem('Token');
  const headers = {
    Authorization: `Bearer ${JSON.parse(token).value}`
  };

  const getCountryList = (page, size = perPage) => {
    const data = {
      pageNumber: page,
      pageSize: size,
      SearchText: '',
      isDropDown: false,
    };
    setIsLoading(true);
    dispatch(
      countryListAPI(
        data,
        response => {
          setIsLoading(false);
          setCountryList(response.data.data);
        },
        err => {
          setIsLoading(false)
          setCountryList([]);
          console.log(err?.data.message);
        },
        headers
      )
    );
  };

  const getStates = async (selectCountryCode) => {
    const data = {
      countryCode: selectCountryCode ? selectCountryCode :"",
      isList: true
    }
   await dispatch(stateListAPI(
      data,
      (res) => {
        if (res && res.length > 0) {
          dispatch(stateMasterDetailAction(res))

        } else {
          dispatch(stateMasterDetailAction([]))
        }
      },
      (err) => {
        console.log("something went wrong")
        dispatch(stateMasterDetailAction([]))
      }
    ));
  };

  const countryMasterReducer = useSelector((state) => state.rootReducer.countryMasterReducer)
  var countryMasterData = countryMasterReducer.countryDetail;

  const stateMasterReducer = useSelector((state) => state.rootReducer.stateMasterReducer)
  const stateMasterData = stateMasterReducer.stateMasterDetail;

  const formChangedReducer = useSelector(
    state => state.rootReducer.formChangedReducer
  );
  var formChangedData = formChangedReducer.formChanged;

  let isFormChanged = Object.values(formChangedData).some(
    value => value === true
  );

  const [formHasError, setFormError] = useState(false);
  const [activeTabName, setActiveTabName] = useState();

  const countryDeleted = useSelector(state => state.rootReducer.countryMasterReducer.countryDeleted);
  
  useEffect(() => {
    $('[data-rr-ui-event-key*="Add Country"]').attr('disabled', true);
  }, []);

  useEffect(() => {
    if (countryDeleted) {
      getCountryList(1, perPage)      
      dispatch(resetCountryDeletedAction(false));
    }
  }, [countryDeleted, dispatch]);

  useEffect(() => {
    localStorage.removeItem('DeleteStateCodes');
    getCountryList(1, perPage)
  }, [responseCountryCode]);

  const clearCountryReducers = () => {
    dispatch(countryDetailAction(undefined));
    dispatch(countryDetailsErrorAction(undefined));
    dispatch(stateMasterDetailAction([]));
    dispatch(stateDetailsErrorAction(undefined));
    dispatch(formChangedAction(undefined));
  };

  $('[data-rr-ui-event-key*="Country List"]')
    .off('click')
    .on('click', function () {
      let isDiscard = $('#btnDiscard').attr('isDiscard');
      if (isDiscard != 'true' && isFormChanged) {
        setModalShow(true);
        localStorage.removeItem('EncryptedResponseCountryCode');
        localStorage.removeItem('DeleteStateCodes');
        setTimeout(function () {
          $('[data-rr-ui-event-key*="' + activeTabName + '"]').trigger('click');
        }, 50);
      } else {
        $('#btnNew').show();
        $('#btnSave').hide();
        $('#btnCancel').hide();
        $('[data-rr-ui-event-key*="Add Country"]').attr('disabled', true);
        $('[data-rr-ui-event-key*="Country List"]').attr('disabled', false);
         getCountryList(1, perPage)
        localStorage.removeItem('EncryptedResponseCountryCode');
         localStorage.removeItem('DeleteStateCodes');
        $('#btnDiscard').attr('isDiscard', false);
         clearCountryReducers();
      }
    });

  $('[data-rr-ui-event-key*="Add Country"]')
    .off('click')
    .on('click', function () {
      setActiveTabName('Add Country');
      $('#btnNew').hide();
      $('#btnSave').show();
      $('#btnCancel').show();
    });

  const newDetails = () => {
    $('[data-rr-ui-event-key*="Add Country"]').attr('disabled', false);
    $('[data-rr-ui-event-key="Add Country"]').trigger('click');
    $('#btnSave').attr('disabled', false);
     clearCountryReducers();
  };

  const cancelClick = () => {
    $('#btnExit').attr('isExit', 'false');
    if (isFormChanged) {
      setModalShow(true);
    } else {
      localStorage.removeItem('DeleteStateCodes');
      $('[data-rr-ui-event-key*="Country List"]').trigger('click');
    }
  };

  const exitModule = () => {
    $('#btnExit').attr('isExit', 'true');
    if (isFormChanged) {
      setModalShow(true);
    } else {
      localStorage.removeItem('DeleteStateCodes');
      window.location.href = '/dashboard';
    }
  };

  const discardChanges = () => {
    $('#btnDiscard').attr('isDiscard', 'true');
    if ($('#btnExit').attr('isExit') == 'true'){
      localStorage.removeItem('DeleteStateCodes');
      window.location.href = '/dashboard';
    }
    else {$('[data-rr-ui-event-key*="List"]').trigger('click');
    setModalShow(false);
    }
  };

  const countryValidation = () => {
    const countryNameErr = {};
    const stateNameErr = {};
    let isValid = true;

    if (!countryMasterData.countryName) {
      countryNameErr.nameEmpty = 'Enter country name';
      isValid = false;
      setFormError(true);
    }

    if (stateMasterData.length < 1) {
      stateNameErr.stateEmpty = "At least one state detail required";
      setTimeout(() => {
        toast.error(stateNameErr.stateEmpty, {
          theme: 'colored'
        });
      }, 1000);
      isValid = false;
      setFormError(true);
    } else if (stateMasterData && stateMasterData.length > 0) {
      stateMasterData.forEach((row, index) => {
        if (!row.stateName) {
          stateNameErr.nameEmpty = "State name is required";
          setTimeout(() => {
            toast.error(stateNameErr.nameEmpty, {
              theme: 'colored'
            });
          }, 1000);
          isValid = false;
          setFormError(true);
        }
      });
    }

    if (!isValid) {
      var errorObject = {
        countryNameErr,
      };
      var errorStateObject = {
        stateNameErr
      };
      dispatch(countryDetailsErrorAction(errorObject));
      dispatch(stateDetailsErrorAction(errorStateObject))
    }

    return isValid;
  };

  const addCountryDetails = async () => {
    if (countryValidation()) {
      const requestData = {
        countryName: countryMasterData.countryName,
        AddStateDetails: stateMasterData,
        AddUser: localStorage.getItem('LoginUserName')
      };

      const keys = [
        'countryName',
        'AddUser'
      ];
      for (const key of Object.keys(requestData).filter(key => keys.includes(key))) {
        requestData[key] = requestData[key] ? requestData[key].toUpperCase() : '';
      }
      const stateKeys = ['stateName', 'addUser']
      var index = 0;
      for (var obj in requestData.AddStateDetails) {
        var stateDetailObj = requestData.AddStateDetails[obj];

        for (const key of Object.keys(stateDetailObj).filter((key) => stateKeys.includes(key))) {
          stateDetailObj[key] = stateDetailObj[key] ? stateDetailObj[key].toUpperCase() : '';
        }
        requestData.AddStateDetails[index] = stateDetailObj;
        index++;
      }
      setIsLoading(true);
    await dispatch(
        addCountryAPI(
          requestData,
          response => {
            setIsLoading(false);
            toast.success(` ${response.data.message}`, {
              theme: 'colored',
              autoClose: 10000
            });

            dispatch(
              countryDetailAction({
                 ...countryMasterData,
                  encryptedCountryCode: response.data.data.encryptedCountryCode,
                 countryName: response.data.data.countryName,
                 countryCode: response.data.data.countryCode,
              })
            );
            updateCallback(true, response.data.data.encryptedCountryCode);
            setModalShow(false);
            localStorage.setItem(
              'EncryptedResponseCountryCode',
              response.data.data.encryptedCountryCode
            );
          },
          err => {
            setIsLoading(false)
            toast.error(err?.data.message, {
              theme: 'colored',
              autoClose: 10000
            });
          },
          headers
        )
      );
    }
  };

  const updateCallback = (isAddState = false, ) => {
    setModalShow(false);
    dispatch(formChangedAction(undefined));
      getStates(responseCountryCode)
    if (!isAddState) {
      toast.success('Country detail updated successfully!', {
        theme: 'colored'
      });
    }

     localStorage.removeItem("DeleteStateCodes");
    $('#btnSave').attr('disabled', true);
    getCountryList(1, perPage);

    $('[data-rr-ui-event-key*="' + activeTabName + '"]').trigger('click');
  };

  const updateCountryDetails = async () => {
    if (countryValidation()) {
      var deleteStateDetails = localStorage.getItem("DeleteStateCodes");
      const updatedCountryData = {
        encryptedCountryCode: localStorage.getItem("EncryptedResponseCountryCode"),
        countryName: countryMasterData.countryName,
        ModifyUser: localStorage.getItem("LoginUserName")
      }
      const keys = ['countryName', 'ModifyUser']
      for (const key of Object.keys(updatedCountryData).filter((key) => keys.includes(key))) {
        updatedCountryData[key] = updatedCountryData[key] ? updatedCountryData[key].toUpperCase() : '';
      }
      const stateKeys = ['stateName', 'addUser']
      for (const key of Object.keys(stateMasterData).filter((key) => stateKeys.includes(key))) {
        stateMasterData[key] = stateMasterData[key] ? stateMasterData[key].toUpperCase() : '';
      }

      var hasError = false;
      if (formChangedData.countryUpdate) {
        setIsLoading(true);
      await dispatch(
          updateCountryAPI(
            updatedCountryData,
            (response) => {
              if(response.data.status == 200){
              setIsLoading(false)
              setModalShow(false);
              }else{
                hasError = true;
              }
            },
            (err) => {
              setIsLoading(false)
              toast.error(err?.data.message, {
                theme: 'colored',
                autoClose: 10000
              });
              hasError = true;
            },
            headers
          )
        );
      }

      var stateDetailIndex = 1;

      var deleteStateCode = localStorage.getItem('DeleteStateCodes');
      if (!hasError && formChangedData.stateMasterDelete && deleteStateCode) {
        var deleteStateDetailData = deleteStateCode ? deleteStateCode.split(',') : null;
        if (deleteStateDetailData) {
            var deleteStateIndex = 1;

            for (let i = 0; i < deleteStateDetailData.length; i++) {
                const deleteStateCode = deleteStateDetailData[i];
                const data = { encryptedStateCode: deleteStateCode }
               
                setIsLoading(true);
                await dispatch(
                  deleteStateMasterDetailAPI(
                    data,
                    (response) => {
                      setIsLoading(false)
                      setModalShow(false);
                    },
                    (err) => {
                      setIsLoading(false)
                      toast.error(err?.data.message, {
                        theme: 'colored',
                        autoClose: 10000
                      });
                      hasError = true;
                    },
                    headers
                  )
                );
            
            }
            deleteStateIndex++;
        }
    }

    if (!hasError && (formChangedData.stateDetailUpdate || formChangedData.stateDetailAdd || formChangedData.stateMasterDelete)) {
      for (let i = 0; i < stateMasterData.length; i++) {
        const stateData = stateMasterData[i];

        if (formChangedData.stateDetailUpdate && stateData.encryptedStateCode) {

          const updateStateData = {
            encryptedCountryCode: localStorage.getItem("EncryptedResponseCountryCode"),
            encryptedStateCode: stateData.encryptedStateCode,
            stateName: stateData.stateName,
            ModifyUser: localStorage.getItem("LoginUserName")
          }
            setIsLoading(true);
            await dispatch(
             updateStateMasterAPI(
                updateStateData,
                (response) => {
                  setIsLoading(false)
                  setModalShow(false);
                },
                (err) => {
                  setIsLoading(false)
                  toast.error(err?.data.message, {
                    theme: 'colored',
                    autoClose: 10000
                  });
                  hasError = true;
                },
                headers
              )
            );
            if (hasError) break;
        }
        else if (!hasError && (formChangedData.stateDetailAdd && !stateData.encryptedStateCode || formChangedData.deleteState)) {
            if (formChangedData.stateDetailAdd && stateData) {
              setIsLoading(true);
              await dispatch(
                addStateMasterAPI(
                  stateData,
                  res => {
                    setIsLoading(false);
                    setModalShow(false);
                     getStates(responseCountryCode)

                  },
                  err => {
                    setIsLoading(false)
                    toast.error(err?.data.message, {
                      theme: 'colored',
                      autoClose: 10000
                    });
                    hasError = true;
                  },
                  headers
                )
              );
              if (hasError) break;
            // }
          }
        }
    
      }
      stateDetailIndex++;
    }
      if (!hasError) {
        updateCallback(false);

      }
    }
  };

  return (
    <>
      {isLoading ? (
        <Spinner
          className="position-absolute start-50 loader-color"
          animation="border"
        />
      ) : null}

      {modalShow && (
        <Modal
          show={modalShow}
          onHide={() => setModalShow(false)}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          backdrop="static"
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Confirmation
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h4>Do you want to save changes?</h4>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="success"
              onClick={
                !countryMasterData.encryptedCountryCode
                  ? addCountryDetails
                  : updateCountryDetails
              }
            >
              Save
            </Button>
            <Button
              variant="danger"
              id="btnDiscard"
              onClick={() => discardChanges()}
            >
              Discard
            </Button>
          </Modal.Footer>
        </Modal>
      )}

      <TabPage
        listData={countryList}
        listColumnArray={listColumnArray}
        tabArray={tabArray}
        module="CountryMaster"
        saveDetails={
          countryMasterData.encryptedCountryCode
            ? updateCountryDetails
            : addCountryDetails
        }
        newDetails={newDetails}
        cancelClick={cancelClick}
        exitModule={exitModule}
      />
    </>
  );
};

export default CountryMaster;
