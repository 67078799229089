import request from "components/Utils/Request";
export const SECURITY_ROLE_MASTER_REQUEST = "SECURITY_ROLE_MASTER_REQUEST";
export const SECURITY_ROLE_MASTER_SUCCESS = "SECURITY_ROLE_MASTER_SUCCESS";
export const SECURITY_ROLE_MASTER_FAILURE = "SECURITY_ROLE_MASTER_FAILURE";
export const ROLE_MASTER_REQUEST = "ROLE_MASTER_REQUEST";
export const ROLE_MASTER_SUCCESS = "ROLE_MASTER_SUCCESS";
export const ROLE_MASTER_FAILURE = "ROLE_MASTER_FAILURE";
export const ADD_ROLE_MASTER_REQUEST = "ADD_ROLE_MASTER_REQUEST";
export const ADD_ROLE_MASTER_SUCCESS = "ADD_ROLE_MASTER_SUCCESS";
export const ADD_ROLE_MASTER_FAILURE = "ADD_ROLE_MASTER_FAILURE";
export const UPDATE_ROLE_MASTER_REQUEST = "UPDATE_ROLE_MASTER_REQUEST";
export const UPDATE_ROLE_MASTER_SUCCESS = "UPDATE_ROLE_MASTER_SUCCESS";
export const UPDATE_ROLE_MASTER_FAILURE = "UPDATE_ROLE_MASTER_FAILURE";

const { REACT_APP_API_URL } = process.env;

export const securityRoleMasterRequest = () => ({
    type: ROLE_MASTER_REQUEST
});
export const securityRoleMasterSuccess = (payload) => ({
    type: ROLE_MASTER_SUCCESS,
    payload
});
export const securityRoleMasterFailure = (payload) => ({
    type: ROLE_MASTER_FAILURE,
    payload
})


export const roleMasterRequest = () => ({
    type: SECURITY_ROLE_MASTER_REQUEST
});
export const roleMasterSuccess = (payload) => ({
    type: SECURITY_ROLE_MASTER_SUCCESS,
    payload
});
export const roleMasterFailure = (payload) => ({
    type: SECURITY_ROLE_MASTER_FAILURE,
    payload
})

export const addRoleMasterRequest = () => ({
    type: ADD_ROLE_MASTER_REQUEST
});
export const addRoleMasterSuccess = (payload) => ({
    type: ADD_ROLE_MASTER_REQUEST,
    payload
});
export const addRoleMasterFailure = (payload) => ({
    type: ADD_ROLE_MASTER_FAILURE,
    payload
})

export const updateRoleMasterRequest = () => ({
    type: UPDATE_ROLE_MASTER_REQUEST
});
export const updateRoleMasterSuccess = (payload) => ({
    type: UPDATE_ROLE_MASTER_SUCCESS,
    payload
});
export const updateRoleMasterFailure = (payload) => ({
    type: UPDATE_ROLE_MASTER_FAILURE,
    payload
})

export const roleDetailAction = (payload) => {
    return {
        type: 'ROLEDATA',
        payload
    };
};

export const roleDetailErrorAction = (payload) => {
    return {
        type: 'ROLEDETAILSERROR',
        payload
    };
};

export const securityRoleMasterListAPI = ( successData, errorMSg,headers = {}) => {
    return (dispatch) => {
        dispatch(securityRoleMasterRequest(null));
        const url = `${REACT_APP_API_URL}/security-role-master-list`;
        return request("GET",url,"","",headers)
            .then((res) => {

                if (res?.data?.status === 200) {
                    successData(res)
                    dispatch(securityRoleMasterSuccess(res));
                }
                else {
                    errorMSg(res)
                }
            }).catch((error) => {
                errorMSg(error?.res);
                dispatch(securityRoleMasterFailure("Something went wrong"));
                throw error;
            });
    };
};

export const roleMasterListAPI = (payload, successData, errorMSg, headers ={}) => {
    return (dispatch) => {
        dispatch(roleMasterRequest(null));
        const url = `${REACT_APP_API_URL}/role-master-list`;
        return request('POST', url, payload, '', headers)
            .then((res) => {

                if (res?.data?.status === 200) {
                    successData(res)
                    dispatch(roleMasterSuccess(res));
                }
                else {
                    errorMSg(res)
                }
            }).catch((error) => {
                errorMSg(error?.res);
                dispatch(roleMasterFailure("Something went wrong"));
                throw error;
            });
    };
};

export const addRoleMasterAPI = (payload, successData, errorMSg, headers ={}) => {
    return (dispatch) => {
        dispatch(addRoleMasterRequest(null));
        const url = `${REACT_APP_API_URL}/add-role-master`;
        return request('POST', url, payload, '', headers)
            .then((res) => {

                if (res?.data?.status === 200) {
                    successData(res)
                    dispatch(addRoleMasterSuccess(res));
                }
                else {
                    errorMSg(res)
                }
            }).catch((error) => {
                errorMSg(error?.res);
                dispatch(addRoleMasterFailure("Something went wrong"));
                throw error;
            });
    };
};

export const updateRoleMasterAPI = (payload, successData, errorMSg, headers ={}) => {
    return (dispatch) => {
        dispatch(updateRoleMasterRequest(null));
        const url = `${REACT_APP_API_URL}/update-role-master`;
        return request('POST', url, payload, '', headers)
            .then((res) => {

                if (res?.data?.status === 200) {
                    successData(res)
                    dispatch(updateRoleMasterSuccess(res));
                }
                else {
                    errorMSg(res)
                }
            }).catch((error) => {
                errorMSg(error?.res);
                dispatch(updateRoleMasterFailure("Something went wrong"));
                throw error;
            });
    };
};