import {
    UPDATE_FILE_PLACEMENT_REQUEST,
    UPDATE_FILE_PLACEMENT_SUCCESS,
    UPDATE_FILE_PLACEMENT_FAILURE,
    GET_FILE_PLACEMENT_LIST_REQUEST,
    GET_FILE_PLACEMENT_LIST_SUCCESS,
    GET_FILE_PLACEMENT_LIST_FAILURE
} from "actions/AdvocateClientCase/FilePlacement/filePlacementAction";
const initialState = {
    filePlacementData: {},
    filePlacementDetails: {},
    filePlacementList:[]
};
export default function filePlacementReducer(
    state = initialState,action
) {
    switch (action.type) {
    
            case GET_FILE_PLACEMENT_LIST_REQUEST:
                return {
                    ...state
                };
            case GET_FILE_PLACEMENT_LIST_SUCCESS:
                return {
                    ...state,
                    filePlacementList: action.payload
                };
            case GET_FILE_PLACEMENT_LIST_FAILURE:
                return {
                    ...state,
                    error: action.payload
                };
                
            case UPDATE_FILE_PLACEMENT_REQUEST:
                return {
                    ...state
                };
            case UPDATE_FILE_PLACEMENT_SUCCESS:
                return {
                    ...state,
                    filePlacementDetails: action.payload
                };
            case UPDATE_FILE_PLACEMENT_FAILURE:
                return {
                    ...state,
                    error: action.payload
                };
        case 'FILEPLACEMENTDATA':
            if (!action.payload) {
                return initialState;
            } else {
                return {
                    ...state,
                    filePlacementData: action.payload
                };
            }
        default:
            return state;
    }
}