import React, { useState } from 'react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import Moment from 'moment';
import { Button, Modal, Table } from 'react-bootstrap';
import { transactionDetailsAction } from 'actions/ClientTransactionDetails/clientTransactionDetailsAction';
import { useDispatch } from 'react-redux';
import IconButton from 'components/common/IconButton';
import { toast } from 'react-toastify';

const TransactionDetailList = () => {
  const dispatch = useDispatch();
  const [modalShow, setModalShow] = useState(false);
  const [paramsData, setParamsData] = useState({});

  const clientTransactionDetailReducer = useSelector(
    state => state.rootReducer.clientTransactionDetailsReducer
  );

  const clientTransactionDetailsErrorReducer = useSelector(
    state => state.rootReducer.clientTransactionDetailsErrorReducer
  );
  const transactionError =
    clientTransactionDetailsErrorReducer.clientTransactionDetailsError;

  useEffect(() => {
    const count = $('#TransactionDetailsTable tr').length;
    if (count > 1) {
      $('#TransactionDetailsTable').show();
    } else {
      $('#TransactionDetailsTable').hide();
    }

    if (transactionError.transactionDetailErr.Count > 0) {
      $('#TransactionDetailsListCard').show();
    } else {
      $('#TransactionDetailsListCard').hide();
    }
  }, []);

  const ModalPreview = Id => {
    setModalShow(true);
    setParamsData(Id);
  };

  const deleteRow = index => {
    const updatedTransactionDetails = [
      ...clientTransactionDetailReducer.transactionDetails.slice(0, index),
      ...clientTransactionDetailReducer.transactionDetails.slice(index + 1)
    ];
    dispatch(transactionDetailsAction(updatedTransactionDetails));
    toast.success('Transaction detail deleted successfully!', {
      theme: 'colored',
      autoClose: 10000
    });
    setModalShow(false);
  };

  return (
    <>
      {transactionError.transactionDetailErr &&
        transactionError.transactionDetailErr.transactionEmpty && (
          <div className="p-1">
            <span className="error-message">
              {transactionError.transactionDetailErr.transactionEmpty}
            </span>
          </div>
        )}
      {modalShow && (
        <Modal
          show={modalShow}
          onHide={() => setModalShow(false)}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          backdrop="static"
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Confirmation
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h4>Are you sure, you want to delete this transaction detail?</h4>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="success" onClick={() => setModalShow(false)}>
              Cancel
            </Button>
            <Button variant="danger" onClick={() => deleteRow(paramsData)}>
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
      )}

      {clientTransactionDetailReducer &&
        clientTransactionDetailReducer.transactionDetails &&
        clientTransactionDetailReducer.transactionDetails.length > 0 && (
          <Table
            striped
            bordered
            responsive
            id="TransactionDetailsTable"
            className="no-pb text-nowrap tab-page-table"
          >
            <thead className="custom-bg-200">
              <tr>
                <th>S. No</th>
                <th>Role Name</th>
                <th>Start Date</th>
                <th>Expiry Date</th>
                <th>Payment Type</th>
                <th>Payment No.</th>
                <th>Billing Amount</th>
                <th>GST %</th>
                <th>Total Amount</th>
                <th>Delete</th>
              </tr>
            </thead>
            <tbody>
              {clientTransactionDetailReducer.transactionDetails.map(
                (data, index) => (
                  <tr>
                    <td>{index + 1}</td>
                    <td>{data.roleName}</td>
                    <td>{Moment(data.startDate).format('DD/MM/YYYY')}</td>
                    <td>{Moment(data.expiryDate).format('DD/MM/YYYY')}</td>
                    <td>{data.paymentType}</td>
                    <td>{data.paymentNo}</td>
                    <td>{data.billingAmount}</td>
                    <td>{data.gstPercent}%</td>
                    <td>{data.totalAmount}</td>
                    <td>
                      {!data.encryptedAdvocateId ? (
                        <IconButton
                          variant="falcon-default"
                          size="sm"
                          icon="trash"
                          iconClassName="me-1"
                          onClick={() => ModalPreview(index)}
                          className="me-1 mb-2 mb-sm-0 hide-on-print"
                        ></IconButton>
                      ) : (
                        ''
                      )}
                    </td>
                  </tr>
                )
              )}
            </tbody>
          </Table>
        )}
    </>
  );
};

export default TransactionDetailList;
