/* eslint-disable react/prop-types */
import React from 'react';
import { Form } from 'react-bootstrap';

const AdvanceTableFilter = ({
  options,
  filterName,
  handleFilterChange,
  filterValue,
  module
}) => {

  return (
    <>
      <>
        {module == 'CaseRegistration' && options && options.length > 0 && (
          <Form.Select
            onChange={handleFilterChange}
            disabled={module === "CaseRegistration" && options.length === 1 ? false : options.length === 1 ? true : false}
            value={filterValue}
          >
            {((module !== "CaseRegistration" && options.length > 1) ||
              (module === "CaseRegistration" && options.length >= 0)) && (
                <option value=''>Select {filterName}</option>
              )}
            {options.map((option, index) => (
              <option key={index} value={option.value}>{option.key}</option>
            ))}
          </Form.Select>
        )}

        {module === 'FilePlacement' &&  (
          <Form.Select
            onChange={handleFilterChange}
            disabled={options.length === 1}
            value={filterValue}
          >
            { filterName=="Case No" && options.length == 0 && <option value=''>Select {filterName}</option> || options.length > 1 &&
              <option value=''>Select {filterName}</option>
            }
            {options.map((option, index) => (
              <option key={index} value={option.value}>{option.key}</option>
            ))}
          </Form.Select>
        )}
      </>

    </>
  );
};

export default AdvanceTableFilter;