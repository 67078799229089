const initialState = {
    roleDetailsError: {
      roleNameErr: {},
    }
  };
  
  const securityRoleMasterErrorReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'ROLEDETAILSERROR':
        if (!action.payload) {
          return initialState;
        } else {
          return {
            ...state,
            roleDetailsError: action.payload
          };
        }
      default:
        return state;
    }
  };
  
  export default securityRoleMasterErrorReducer;