import request from "components/Utils/Request";
export const MENU_HEADER_TREE_REQUEST = "MENU_HEADER_TREE_REQUEST";
export const MENU_HEADER_TREE_SUCCESS = "MENU_HEADER_TREE_SUCCESS";
export const MENU_HEADER_TREE_FAILURE = "MENU_HEADER_TREE_FAILURE";
export const MENU_TREE_REQUEST = "MENU_TREE_REQUEST";
export const MENU_TREE_SUCCESS = "MENU_TREE_SUCCESS";
export const MENU_TREE_FAILURE = "MENU_TREE_FAILURE";
export const ADVOCATE_USER_MENU_TREE_REQUEST = "ADVOCATE_USER_MENU_TREE_REQUEST";
export const ADVOCATE_USER_MENU_TREE_SUCCESS = "ADVOCATE_USER_MENU_TREE_SUCCESS";
export const ADVOCATE_USER_MENU_TREE_FAILURE = "ADVOCATE_USER_MENU_TREE_FAILURE"; 
export const SECURITY_MENU_TREE_MASTER_LIST_REQUEST = "SECURITY_MENU_TREE_MASTER_LIST_REQUEST";
export const SECURITY_MENU_TREE_MASTER_LIST_SUCCESS = "SECURITY_MENU_TREE_MASTER_LIST_SUCCESS";
export const SECURITY_MENU_TREE_MASTER_LIST_FAILURE = "SECURITY_MENU_TREE_MASTER_LIST_FAILURE";
export const ADD_MENU_TREE_DETAILS_REQUEST = "ADD_MENU_TREE_DETAILS_REQUEST";
export const ADD_MENU_TREE_DETAILS_SUCCESS = "ADD_MENU_TREE_DETAILS_SUCCESS";
export const ADD_MENU_TREE_DETAILS_FAILURE = "ADD_MENU_TREE_DETAILS_FAILURE";
export const VIEW_MENU_TREE_DETAILS_REQUEST = "VIEW_MENU_TREE_DETAILS_REQUEST";
export const VIEW_MENU_TREE_DETAILS_SUCCESS = "VIEW_MENU_TREE_DETAILS_SUCCESS";
export const VIEW_MENU_TREE_DETAILS_FAILURE = "VIEW_MENU_TREE_DETAILS_FAILURE";
export const UPDATE_MENU_TREE_DETAILS_REQUEST = "UPDATE_MENU_TREE_DETAILS_REQUEST";
export const UPDATE_MENU_TREE_DETAILS_SUCCESS = "UPDATE_MENU_TREE_DETAILS_SUCCESS";
export const UPDATE_MENU_TREE_DETAILS_FAILURE = "UPDATE_MENU_TREE_DETAILS_FAILURE";
export const DELETE_MENU_TREE_DETAILS_REQUEST = "DELETE_MENU_TREE_DETAILS_REQUEST";
export const DELETE_MENU_TREE_DETAILS_SUCCESS = "DELETE_MENU_TREE_DETAILS_SUCCESS";
export const DELETE_MENU_TREE_DETAILS_FAILURE = "DELETE_MENU_TREE_DETAILS_FAILURE";
export const GET_COMMAND_KEY_COMBINATION_LIST_REQUEST = "GET_COMMAND_KEY_COMBINATION_LIST_REQUEST";
export const GET_COMMAND_KEY_COMBINATION_LIST_SUCCESS = "GET_COMMAND_KEY_COMBINATION_LIST_SUCCESS";
export const GET_COMMAND_KEY_COMBINATION_LIST_FAILURE = "GET_COMMAND_KEY_COMBINATION_LIST_FAILURE";

const { REACT_APP_API_URL } = process.env;

export const menuHeaderTreeRequest = () => ({
    type: MENU_HEADER_TREE_REQUEST
});
export const menuHeaderTreeSuccess = (payload) => ({
    type: MENU_HEADER_TREE_SUCCESS,
    payload
});
export const menuHeaderTreeFailure = (payload) => ({
    type: MENU_HEADER_TREE_FAILURE,
    payload
})
export const menuTreeRequest = () => ({
    type: MENU_TREE_REQUEST
});
export const menuTreeSuccess = (payload) => ({
    type: MENU_TREE_SUCCESS,
    payload
});
export const menuTreeFailure = (payload) => ({
    type: MENU_TREE_FAILURE,
    payload
})

export const advocateUserMenuTreeRequest = () => ({
    type: ADVOCATE_USER_MENU_TREE_REQUEST
});
export const advocateUserMenuTreeSuccess = (payload) => ({
    type: ADVOCATE_USER_MENU_TREE_SUCCESS,
    payload
});
export const advocateUserMenuTreeFailure = (payload) => ({
    type: ADVOCATE_USER_MENU_TREE_FAILURE,
    payload
})

export const securityMenuTreeMasterListRequest = () => ({
    type: SECURITY_MENU_TREE_MASTER_LIST_REQUEST
});
export const securityMenuTreeMasterListSuccess = (payload) => ({
    type: SECURITY_MENU_TREE_MASTER_LIST_SUCCESS,
    payload
});
export const securityMenuTreeMasterListFailure = (payload) => ({
    type: SECURITY_MENU_TREE_MASTER_LIST_FAILURE,
    payload
})

export const addMenuTreeDetailRequest = () => ({
    type: ADD_MENU_TREE_DETAILS_REQUEST
});
export const addMenuTreeDetailSuccess = (payload) => ({
    type: ADD_MENU_TREE_DETAILS_SUCCESS,
    payload
});
export const addMenuTreeDetailFailure = (payload) => ({
    type: ADD_MENU_TREE_DETAILS_FAILURE,
    payload
})

export const viewMenuTreeDetailRequest = () => ({
    type: VIEW_MENU_TREE_DETAILS_REQUEST
});
export const viewMenuTreeDetailSuccess = (payload) => ({
    type: VIEW_MENU_TREE_DETAILS_SUCCESS,
    payload
});
export const viewMenuTreeDetailFailure = (payload) => ({
    type: VIEW_MENU_TREE_DETAILS_FAILURE,
    payload
})

export const updateMenuTreeDetailRequest = () => ({
    type: UPDATE_MENU_TREE_DETAILS_REQUEST
});
export const updateMenuTreeDetailSuccess = (payload) => ({
    type: UPDATE_MENU_TREE_DETAILS_SUCCESS,
    payload
});
export const updateMenuTreeDetailFailure = (payload) => ({
    type: UPDATE_MENU_TREE_DETAILS_FAILURE,
    payload
})


export const deleteMenuTreeDetailRequest = () => ({
    type: DELETE_MENU_TREE_DETAILS_REQUEST
});
export const deleteMenuTreeDetailSuccess = (payload) => ({
    type: DELETE_MENU_TREE_DETAILS_SUCCESS,
    payload
});
export const deleteMenuTreeDetailFailure = (payload) => ({
    type: DELETE_MENU_TREE_DETAILS_FAILURE,
    payload
})
export const securityMenuTreeMasterListAction = (payload) => {
    return {
        type: 'SECURITYMENUTREEMASTERLIST',
        payload
    };
};

export const getCommandKeyCombinationListRequest = () => ({
  type: GET_COMMAND_KEY_COMBINATION_LIST_REQUEST
});
export const getCommandKeyCombinationListSuccess = (payload) => ({
  type: GET_COMMAND_KEY_COMBINATION_LIST_SUCCESS,
  payload
});
export const getCommandKeyCombinationListFailure = (payload) => ({
  type: GET_COMMAND_KEY_COMBINATION_LIST_FAILURE,
  payload
})

export const securityMenuTreeMasterDetailAction = (payload) => {
    return {
        type: 'MENUDETAILS',
        payload
    };
};

export const menuDetailsErrorAction = (payload) => {
    return {
      type: 'MENUDETAILSERROR',
      payload
    };
  };

export const menuHeaderTreeAPI = (payload, successData, errorMSg, headers = {}) => {
    return (dispatch) => {
        dispatch(menuHeaderTreeRequest());
        const url = `${REACT_APP_API_URL}/get-security-menu-tree-header-list/${payload}`;
        return request("GET",url, "", "", headers)
            .then((res) => {

                if (res?.data?.status === 200) {
                    successData(res)
                    dispatch(menuHeaderTreeSuccess(res));
                }
                else {
                    errorMSg(res)
                }
            }).catch((error) => {
                errorMSg(error?.res);
                dispatch(menuHeaderTreeFailure("Something went wrong"));
                throw error;
            });
    };
};

export const menuTreeListAPI = (payload,successData,errorMSg, headers = {}) =>{  
    return (dispatch) =>{
        dispatch(menuTreeRequest());
        const url = `${REACT_APP_API_URL}/get-security-menu-tree-menu-list`;
        return request("POST",url,payload, "", headers)
        .then((res)=>{
            if(res?.data.status === 200){
                successData(res)
                dispatch(menuHeaderTreeSuccess(res));
            }else{
                errorMSg(res)
            }
        }).catch((err) =>{
            errorMSg(err?.res);
            dispatch(menuTreeFailure("Something went wrong"));
            throw err;
        })
    }
}

export const advocateUserMenuTreeListAPI = (payload,successData,errorMSg, headers = {}) =>{  
    return (dispatch) =>{
        dispatch(advocateUserMenuTreeRequest());
        const url = `${REACT_APP_API_URL}/get-advocate-user-menu-tree`;
        return request("POST",url,payload, "", headers)
        .then((res)=>{
            if(res?.data.status === 200){
                successData(res)
                dispatch(advocateUserMenuTreeSuccess(res));
            }else{
                errorMSg(res)
            }
        }).catch((err) =>{
            errorMSg(err?.res);
            dispatch(advocateUserMenuTreeFailure("Something went wrong"));
            throw err;
        })
    }
}

export const securityMenuTreeMasterListAPI = (payload,successData,errorMSg, headers = {}) =>{  
    return (dispatch) =>{
        dispatch(securityMenuTreeMasterListRequest());
        const url = `${REACT_APP_API_URL}/get-security-menu-tree-master-list`;
        return request("POST",url,payload, "", headers)
        .then((res)=>{
            if(res?.data.status === 200){
                successData(res)
                dispatch(securityMenuTreeMasterListSuccess(res));
            }else{
                errorMSg(res)
            }
        }).catch((err) =>{
            errorMSg(err?.res);
            dispatch(securityMenuTreeMasterListFailure("Something went wrong"));
            throw err;
        })
    }
}

export const addSecurityMenuTreeDetailAPI = (payload, successData, errorMSg, headers = {}) => {
    return dispatch => {
      dispatch(addMenuTreeDetailRequest());
      return request(
        'POST',
        `${REACT_APP_API_URL + '/add-security-menu-tree-details'}`,
        payload,
        "",
        headers
      )
        .then(res => {
          if (res?.data.status === 200) {
            successData(res);
            dispatch(addMenuTreeDetailSuccess(res));
          } else {
            errorMSg(res);
          }
        })
        .catch(err => {
          errorMSg(err?.res);
          dispatch(addMenuTreeDetailFailure('Something went wrong'));
          throw err;
        });
    }
  }

  export const viewSecurityMenuTreeDetailAPI = (payload, successData, errorMSg, headers = {}) => {
    return dispatch => {
      dispatch(viewMenuTreeDetailRequest());
      return request(
        'POST',
        `${REACT_APP_API_URL + '/view-security-menu-tree-detail'}`,
        payload,
        "",
        headers
      )
        .then(res => {
          if (res?.data.status === 200) {
            successData(res);
            dispatch(viewMenuTreeDetailSuccess(res));
          } else {
            errorMSg(res);
          }
        })
        .catch(err => {
          errorMSg(err?.res);
          dispatch(viewMenuTreeDetailFailure('Something went wrong'));
          throw err;
        });
    }
  }

  export const updateSecurityMenuTreeDetailAPI = (payload, successData, errorMSg, headers = {}) => {
    return dispatch => {
      dispatch(updateMenuTreeDetailRequest());
      return request(
        'POST',
        `${REACT_APP_API_URL + '/update-security-menu-tree-detail'}`,
        payload,
        "",
        headers
      )
        .then(res => {
          if (res?.data.status === 200) {
            successData(res);
            dispatch(updateMenuTreeDetailSuccess(res));
          } else {
            errorMSg(res);
          }
        })
        .catch(err => {
          errorMSg(err?.res);
          dispatch(updateMenuTreeDetailFailure('Something went wrong'));
          throw err;
        });
    }
  }

  export const deleteSecurityMenuTreeDetailAPI = (payload, successData, errorMsg, headers = {}) => {
    return dispatch => {
      dispatch(deleteMenuTreeDetailRequest());
      return request(
        'DELETE',
        `${REACT_APP_API_URL + '/delete-security-menu-tree-detail'}`,
        payload,
        "",
        headers
      )
        .then(res => {
          if (res?.data.status === 200) {
            successData(res);
            dispatch(deleteMenuTreeDetailSuccess(res));
          } else {
            errorMsg(res);
          }
        })
        .catch(err => {
          errorMsg(err?.res);
          dispatch(deleteMenuTreeDetailFailure('Something went wrong'));
          throw err;
        });
    };
  };

  export const getCommandKeyCombinationListAPI = (payload,successData,errorMSg, headers = {}) =>{  
    return (dispatch) =>{
        dispatch(getCommandKeyCombinationListRequest());
        const url = `${REACT_APP_API_URL}/get-key-combination-list`;
        return request("GET",url,"", "", headers)
        .then((res)=>{
            if(res?.data.status === 200){

                successData(res.data.data)
                dispatch(getCommandKeyCombinationListSuccess(res.data.data));
            }else{
                errorMSg(res)
            }
        }).catch((err) =>{
            errorMSg(err?.res);
            dispatch(getCommandKeyCombinationListFailure("Something went wrong"));
            throw err;
        })
    }
}