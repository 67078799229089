import NavbarTop from 'components/navbar/top/NavbarTop';
import TopNavRightSideNavItem from 'components/navbar/top/TopNavRightSideNavItem';
import React from 'react';
import { Row, Col } from 'react-bootstrap';

const Dashboard = () => {

  return (
    <>
      <Row className="g-3 mb-3">
        <Col md={12} >
          {/* <h4></h4> */}
          <div className='d-flex justify-content-between navbar-top-height  navbar-dashboard' ><div className='navbarTop-onTop'><NavbarTop /></div>
          <div className='dashboard-dropdown-log'>
          <TopNavRightSideNavItem /> </div>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default Dashboard;