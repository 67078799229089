import request from "components/Utils/Request";
export const CLIENT_TRANSACTION_DETAILS_LIST_REQUEST = "CLIENT_TRANSACTION_DETAILS_LIST_REQUEST";
export const CLIENT_TRANSACTION_DETAILS_LIST_SUCCESS = "CLIENT_TRANSACTION_DETAILS_LIST_SUCCESS";
export const CLIENT_TRANSACTION_DETAILS_LIST_FAILURE = "CLIENT_TRANSACTION_DETAILS_LIST_FAILURE";
export const CLIENT_TRANSACTION_DETAILS_REQUEST = "CLIENT_TRANSACTION_DETAILS_REQUEST";
export const CLIENT_TRANSACTION_DETAILS_SUCCESS = "CLIENT_TRANSACTION_DETAILS_SUCCESS";
export const CLIENT_TRANSACTION_DETAILS_FAILURE = "CLIENT_TRANSACTION_DETAILS_FAILURE";
const { REACT_APP_API_URL } = process.env;

export const clientTransactionDetailsListRequest = () => ({
	type: CLIENT_TRANSACTION_DETAILS_LIST_REQUEST
});
export const clientTransactionDetailsListSuccess = (payload) => ({
	type: CLIENT_TRANSACTION_DETAILS_LIST_SUCCESS,
	payload
});
export const clientTransactionDetailsListFailure = (payload) => ({
	type: CLIENT_TRANSACTION_DETAILS_LIST_FAILURE,
	payload
})

export const addClientTransactionDetailsRequest = () => ({
	type: CLIENT_TRANSACTION_DETAILS_REQUEST
});
export const AddClientTransactionDetailsSuccess = (payload) => ({
	type: CLIENT_TRANSACTION_DETAILS_SUCCESS,
	payload
});
export const addClientTransactionDetailsFailure = (payload) => ({
	type: CLIENT_TRANSACTION_DETAILS_FAILURE,
	payload
})


export const transactionDetailChangedAction = (payload) => {
    return {
        type: 'TRANSACTIONDETAILCHANGED',
        payload
    };
};

export const transactionDetailsAction = (payload) => {
    return {
        type: 'TRANSACTIONLIST',
        payload
    };
};

export const clientTransactionDetailsErrorAction = (payload) => {
    return {
      type: 'CLIENTTRANSACTIONDETAILSERROR',
      payload
    };
  };


export const clientTransactionListAPI = (payload, successData, errorMSg, headers = {}) => {
	return (dispatch) => {
		dispatch(clientTransactionDetailsListRequest());
		return request("POST", `${REACT_APP_API_URL + "/client-transaction-detail-list"}`, payload, "", headers)
			.then((res) => {
				if (res?.data.status === 200) {
                    var data = res?.data.data
					successData(res)
					dispatch(clientTransactionDetailsListSuccess(data));
				} else {
					errorMSg(res)
				}
			}).catch((err) => {
				errorMSg(err?.res);
				dispatch(clientTransactionDetailsListFailure("Something went wrong"));
				throw err;
			})
	}
}

export const addClientTransactionAPI = (payload, successData, errorMsg, headers = {}) => {
	return (dispatch) => {
		dispatch(addClientTransactionDetailsRequest());
		return request("POST", `${REACT_APP_API_URL + "/add-client-transaction-details"}`, payload, "", headers)
			.then((response) => {
				if (response?.data?.status === 200) {
					successData(response);
					dispatch(AddClientTransactionDetailsSuccess(response));
				} else {
					errorMsg(response);
				}

			}).catch((error) => {
				errorMsg(error?.response);
				dispatch(addClientTransactionDetailsFailure("Something Went Wrong"));
				throw error;
			});
	};
};