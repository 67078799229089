import {
    ADVOCATE_DEALING_REQUEST,
    ADVOCATE_DEALING_SUCCESS,
    ADVOCATE_DEALING_FAILURE,
    ADVOCATE_DEALING_LIST_REQUEST,
    ADVOCATE_DEALING_LIST_SUCCESS,
    ADVOCATE_DEALING_LIST_FAILURE
} from 'actions/AdvocateDealing/advocateDealingAction';

const initialState = {
    advocateDealingData: [],
    advocateDealingDetails: [],
};

export default function advocateDealingReducer(
    state = initialState, action
) {
    switch (action.type) {

        case ADVOCATE_DEALING_REQUEST:
            return {
                ...state
            };
        case ADVOCATE_DEALING_SUCCESS:
            return {
                ...state,
                advocateDealingDetails: action.payload
            };
        case ADVOCATE_DEALING_FAILURE:
            return {
                ...state,
                error: action.payload
            };
        case ADVOCATE_DEALING_LIST_REQUEST:
            return {
                ...state
            };
        case ADVOCATE_DEALING_LIST_SUCCESS:
            return {
                ...state,
                advocateDealingDetails: action.payload
            };
        case ADVOCATE_DEALING_LIST_FAILURE:
            return {
                ...state,
                error: action.payload
            };
        case 'ADVOCATEDEALINGDATA':
            if (!action.payload) {
                return initialState;
            } else {
                return {
                    ...state,
                    advocateDealingDetails: action.payload
                };
            }
        case 'ADVOCATEDEALINGDEATILS':
            if (!action.payload) {
                return initialState;
            } else {
                return {
                    ...state,
                    advocateDealingDetails: action.payload
                };
            }
        default:
            return state;
    }
}