import React from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { formChangedAction } from '../../actions/index';
import FalconComponentCard from 'components/common/FalconComponentCard';
import { filePlacementDataAction } from 'actions/AdvocateClientCase/FilePlacement/filePlacementAction';

export const FilePlacementDetail = () => {
  const dispatch = useDispatch();
  const resetFilePlacementData = () => {
    dispatch(
      filePlacementDataAction({
        'fileDescription': '',
        'fileNo': '',
        'rack': '',
        'almirah': '',
        'roomNo': '',
      })
    );
  };

  const filePlacementReducer = useSelector(
    state => state.rootReducer.filePlacementReducer
  );
  var filePlacementData = filePlacementReducer.filePlacementData;

  if (
    !filePlacementReducer.filePlacementData ||
    Object.keys(filePlacementReducer.filePlacementData).length <= 0
  ) {
    resetFilePlacementData();
  }

  const formChangedReducer = useSelector(
    state => state.rootReducer.formChangedReducer
  );
  var formChangedData = formChangedReducer.formChanged;

  const handleFieldChange = e => {
    dispatch(
      filePlacementDataAction({
        ...filePlacementData,
        [e.target.name]: e.target.value
      })
    );

    if (filePlacementData.encryptedCaseId) {
      dispatch(
        formChangedAction({
          ...formChangedData,
          filePlacementUpdate: true
        })
      );
    }
  }

  return (
    <>
      <Form className="details-form micro-form" id="UpdateFilePlacementDetailsForm">
        <Row className="mb-2 row-content-pt5px">
          <Col sm={12} lg={5} md={10} className="no-pd-card ">
            <FalconComponentCard className="farmer-card-row1">
              <FalconComponentCard.Body language="jsx">
                <Row>
                  <Col className="me-3 ms-3">
                    <Form.Group
                      as={Row}
                      className="mb-1"
                      controlId="formHorizontalEmail"
                    >
                      <Form.Label column sm={3} md={4} >
                        Client Name
                      </Form.Label>
                      <Col sm={9} md={8} >
                        <h4 className='name-label'>{filePlacementData.clientName}</h4>
                      </Col>
                    </Form.Group>
                    <Form.Group
                      as={Row}
                      className="mb-1"
                      controlId="formHorizontalEmail"
                    >
                      <Form.Label column sm={3} md={4}>
                        Case No.
                      </Form.Label>
                      <Col sm={9} md={8}>
                        <h4 className='name-label'>{filePlacementData.clientCaseNo}</h4>
                      </Col>
                    </Form.Group>
                    <Form.Group
                      as={Row}
                      className="mb-1"
                      controlId="formHorizontalEmail"
                    >
                      <Form.Label column sm={3} md={4}>
                        File No.
                      </Form.Label>
                      <Col sm={9}  md={8}>
                        <Form.Control
                          id="txtFileNo"
                          name="fileNo"
                          maxLength={20}
                          onChange={handleFieldChange}
                          value={filePlacementData.fileNo}
                          placeholder="File No"
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group
                      as={Row}
                      className="mb-1"
                      controlId="formHorizontalEmail"
                    >
                      <Form.Label column sm={3} md={4}>
                        Room No.
                      </Form.Label>
                      <Col sm={9}  md={8}>
                        <Form.Control
                          id="txtRoomNo"
                          name="roomNo"
                          maxLength={5}
                          onChange={handleFieldChange}
                          value={filePlacementData.roomNo}
                          placeholder="Room No"
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group
                      as={Row}
                      className="mb-1"
                      controlId="formHorizontalEmail"
                    >
                      <Form.Label column sm={3} md={4}>
                        Almirah No.
                      </Form.Label>
                      <Col sm={9}  md={8}>
                        <Form.Control
                          id="txtAlmirahNo"
                          name="almirah"
                          maxLength={5}
                          onChange={handleFieldChange}
                          value={filePlacementData.almirah}
                          placeholder="Almirah No"
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group
                      as={Row}
                      className="mb-1"
                      controlId="formHorizontalEmail"
                    >
                      <Form.Label column sm={3} md={4}>
                        Rack No.
                      </Form.Label>
                      <Col sm={9}  md={8}>
                        <Form.Control
                          id="txtRackNo"
                          maxLength={50}
                          name="rack"
                          placeholder="Rack No"
                          value={filePlacementData.rack}
                          onChange={handleFieldChange}
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group
                      as={Row}
                      className="mb-1"
                      controlId="formHorizontalEmail"
                    >
                      <Form.Label column sm={3} md={4}>
                        File Description
                      </Form.Label>
                      <Col sm={9} md={8}>
                        <Form.Control
                          id="txtDescription"
                          name="fileDescription"
                          as="textarea"
                          maxLength={50}
                          onChange={handleFieldChange}
                          value={filePlacementData.fileDescription}
                          placeholder="Description"
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
              </FalconComponentCard.Body>
            </FalconComponentCard>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default FilePlacementDetail;
