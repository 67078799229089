import React, { useState, useEffect } from 'react';
import {  Form,  Row, Col } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { formChangedAction } from 'actions';
import FalconComponentCard from 'components/common/FalconComponentCard.js';
import { countryDetailAction } from 'actions/CountryMaster/countryMasterAction.js';
import { courtTypeDataDetailAction } from 'actions/CourtTypeMaster/courtTypeMasterAction';

const CourtTypeMasterDetail = () => {
	const dispatch = useDispatch();

	const resetCourtTypeData = () => {
		dispatch(
			countryDetailAction({
				'courtTypeName': '',
				'courtTypeCode': ''
			})
		);
	}

	const courtTypeMasterReducer = useSelector((state) => state.rootReducer.courtTypeMasterReducer)
	var courtTypeData = courtTypeMasterReducer.courtTypeDetail;

	if (
		!courtTypeMasterReducer.courtTypeDetail ||
		(courtTypeMasterReducer.courtTypeDetail).length <= 0
	) {
		resetCourtTypeData();
	}
	const courtTypeMasterErrorReducer = useSelector((state) => state.rootReducer.courtTypeMasterErrorReducer)
	const courtTypeDetailsErr = courtTypeMasterErrorReducer.courtTypeDetailsError;

	const formChangedReducer = useSelector((state) => state.rootReducer.formChangedReducer)
	var formChangedData = formChangedReducer.formChanged;

	const handleFieldChange = e => {
		if (e.target.name == 'courtTypeName') {
			dispatch(
				courtTypeDataDetailAction({
					...courtTypeData,
					courtTypeName: e.target.value,
				})
			);
		}
		if (courtTypeData.encryptedCourtTypeCode) {
			dispatch(
				formChangedAction({
					...formChangedData,
					courtTypeUpdate: true
				})
			);
		} else {
			dispatch(
				formChangedAction({
					...formChangedData,
					courtTypeAdd: true
				})
			);
		}
	};
	return (
		<>
		{courtTypeData &&(
			<FalconComponentCard className="no-pb mb-1">
				<FalconComponentCard.Body language="jsx">
					<Form className="details-form"
						id="addCourtTypeDetailsForm">
						<Row>
							<Col md="4">
								<Form.Group as={Row} className="mb-1" controlId="formPlaintextPassword">
									<Form.Label column sm="4">
										Court Type Code
									</Form.Label>
									<Col sm="8">
										<Form.Control id="txtCourtTypeCode" name="courtTypeCode" maxLength={5} placeholder="Court Type Code"
											value={courtTypeData.courtTypeCode}
											disabled />
									</Col>
								</Form.Group>
							</Col>
							<Col md="4">
								<Form.Group as={Row} className="mb-1" controlId="formPlaintextPassword">
									<Form.Label column sm="4">
										Court Type Name<span className="text-danger">*</span>
									</Form.Label>
									<Col sm="8">
										<Form.Control id="txtCourtTypeName" maxLength={50} name="courtTypeName" placeholder="Court Type Name"
											value={courtTypeData.courtTypeName}
											onChange={(e) => handleFieldChange(e)}
										/>
										{Object.keys(courtTypeDetailsErr.courtTypeNameErr).map(key => {
											return (
												<span className="error-message">
													{courtTypeDetailsErr.courtTypeNameErr[key]}
												</span>
											);
										})}
									</Col>

								</Form.Group>
							</Col>
						</Row>
					</Form>
				</FalconComponentCard.Body>
			</FalconComponentCard>)}
		</>
	)
}

export default CourtTypeMasterDetail