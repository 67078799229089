import {
    CLIENT_TRANSACTION_DETAILS_LIST_REQUEST,
    CLIENT_TRANSACTION_DETAILS_LIST_SUCCESS,
    CLIENT_TRANSACTION_DETAILS_LIST_FAILURE,
    CLIENT_TRANSACTION_DETAILS_REQUEST,
    CLIENT_TRANSACTION_DETAILS_SUCCESS,
    CLIENT_TRANSACTION_DETAILS_FAILURE
} from "actions/ClientTransactionDetails/clientTransactionDetailsAction";

const initialState = {
    transactionDetails: [],
};

export default function clientTransactionDetailsReducer(state = initialState, action) {
    switch (action.type) {
        case 'TRANSACTIONLIST':
            if (!action.payload) {
                return initialState
            }
            else if (Array.isArray(action.payload)) {
                return {
                    ...state,
                    transactionDetails: action.payload
                }
            }
            else {
                return {
                    ...state,
                    transactionDetails: [...state.transactionDetails, action.payload]
                }
            }
        case CLIENT_TRANSACTION_DETAILS_LIST_REQUEST:
            return {
                ...state,
            };
        case CLIENT_TRANSACTION_DETAILS_LIST_SUCCESS:
            return {
                ...state,
                transactionDetails: action.payload
            };
        case CLIENT_TRANSACTION_DETAILS_LIST_FAILURE:
            return {
                ...state,
                error: action.payload
            };
        case CLIENT_TRANSACTION_DETAILS_REQUEST:
            return {
                ...state,
            };

        case CLIENT_TRANSACTION_DETAILS_SUCCESS:
            return {
                ...state,
                transactionDetails: action.payload
            };
        case CLIENT_TRANSACTION_DETAILS_FAILURE:
            return {
                ...state,
                error: action.payload
            };
        default:
            return state;
    }
}