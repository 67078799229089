

const initialState = {
    countryDetailsError: {
      countryNameErr: {},
    }
  };
  
  const countryMasterErrorReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'COUNTRYDETALERROR':
        if (!action.payload) {
          return initialState;
        } else {
          return {
            ...state,
            countryDetailsError: action.payload
          };
        }
      default:
        return state;
    }
  };
  
  export default countryMasterErrorReducer;