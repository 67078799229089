import {
  COURTMASTER_LIST_REQUEST,
  COURTMASTER_LIST_SUCCESS,
  COURTMASTER_LIST_FAILURE,
  GET_COURTMASTER_LIST_FAILURE,
  GET_COURTMASTER_LIST_REQUEST,
  GET_COURTMASTER_LIST_SUCCESS,
} from 'actions/CourtMaster/courtMasterAction';
const initialState = {
  courtMasterList: [],
  getCourtMasterList: [],
  courtMasterDetail: {},
  courtMasterData: {}
};

export default function courtMasterReducer(state = initialState, action) {
  switch (action.type) {
    case COURTMASTER_LIST_REQUEST:
      return {
        ...state
      };
    case COURTMASTER_LIST_SUCCESS:
      return {
        ...state,
        courtMasterList: action.payload
      };
    case COURTMASTER_LIST_FAILURE:
      return {
        ...state,
        error: action.payload
      };
    case GET_COURTMASTER_LIST_REQUEST:
      return {
        ...state
      };
    case GET_COURTMASTER_LIST_SUCCESS:
      return {
        ...state,
        getCourtMasterList: action.payload
      };
    case GET_COURTMASTER_LIST_FAILURE:
      return {
        ...state,
        error: action.payload
      };
    case 'COURTMASTERDETAIL':
      if (!action.payload) {
        return initialState;
      } else {
        return {
          ...state,
          courtMasterDetail: action.payload
        };
      }
    case 'COURTMASTERDATA':
      if (!action.payload) {
        return initialState;
      } else {
        return {
          ...state,
          courtMasterData: action.payload
        };
      }
    default:
      return state;
  }
}