import request from 'components/Utils/Request';
const { REACT_APP_API_URL } = process.env;
export const ADD_COURT_TYPE_MASTER_REQUEST = 'ADD_COURT_TYPE_MASTER_REQUEST';
export const ADD_COURT_TYPE_MASTER_SUCCESS = 'ADD_COURT_TYPE_MASTER_SUCCESS';
export const ADD_COURT_TYPE_MASTER_FAILURE = 'ADD_COURT_TYPE_MASTER_FAILURE';

export const UPDATE_COURT_TYPE_MASTER_REQUEST = 'UPDATE_COURT_TYPE_MASTER_REQUEST';
export const UPDATE_COURT_TYPE_MASTER_SUCCESS = 'UPDATE_COURT_TYPE_MASTER_SUCCESS';
export const UPDATE_COURT_TYPE_MASTER_FAILURE = 'UPDATE_COURT_TYPE_MASTER_FAILURE';

export const GET_COURT_TYPE_MASTER_LIST_REQUEST = 'GET_COURT_TYPE_MASTER_LIST_REQUEST';
export const GET_COURT_TYPE_MASTER_LIST_SUCCESS = 'GET_COURT_TYPE_MASTER_LIST_SUCCESS';
export const GET_COURT_TYPE_MASTER_LIST_FAILURE = 'GET_COURT_TYPE_MASTER_LIST_FAILURE';
export const AddCourtTypeMasterRequest = () => ({
    type: ADD_COURT_TYPE_MASTER_REQUEST
  });
  export const AddCourtTypeMasterSuccess = payload => ({
    type: ADD_COURT_TYPE_MASTER_SUCCESS,
    payload
  });
  export const AddCourtTypeMasterFailure = payload => ({
    type: ADD_COURT_TYPE_MASTER_FAILURE,
    payload
  });

  export const getCourtTypeMasterListRequest = () => ({
    type: GET_COURT_TYPE_MASTER_LIST_REQUEST
  });
  export const getCourtTypeMasterListSuccess = payload => ({
    type: GET_COURT_TYPE_MASTER_LIST_SUCCESS,
    payload
  });
  export const getCourtTypeMasterListFailure = payload => ({
    type: GET_COURT_TYPE_MASTER_LIST_FAILURE,
    payload
  });

  export const updateCourtTypeMasterRequest = () => ({
    type: UPDATE_COURT_TYPE_MASTER_REQUEST
  });
  export const updateCourtTypeMasterSuccess = payload => ({
    type: UPDATE_COURT_TYPE_MASTER_SUCCESS,
    payload
  });
  export const updateCourtTypeMasterFailure = payload => ({
    type: UPDATE_COURT_TYPE_MASTER_FAILURE,
    payload
  });

  export const courtTypeDataDetailAction = payload => {
    return {
      type: 'COURTTYPEMASTERDETAIL',
      payload
    };
  };
  
  export const courtTypeDetailErrorAction = payload => {
    return {
      type: 'COURTTYPEDETAILSERROR',
      payload
    };
  };
  
  export const addCourtTypeMasterAPI = (payload, successData, errorMSg, headers = {}) => {
    return dispatch => {
      dispatch(AddCourtTypeMasterRequest());
      return request(
        'POST',
        `${REACT_APP_API_URL + '/add-court-type-master'}`,
        payload,
        "",
        headers
      )
        .then(res => {
          if (res?.data.status === 200) {
            successData(res);
            dispatch(AddCourtTypeMasterSuccess(res));
          } else {
            errorMSg(res);
          }
        })
        .catch(err => {
          errorMSg(err?.res);
          dispatch(AddCourtTypeMasterFailure('Something went wrong'));
          throw err;
        });
    }
  }

  export const updateCourtTypeMasterAPI = (payload, successData, errorMSg, headers = {}) => {
    return dispatch => {
      dispatch(updateCourtTypeMasterRequest());
      return request(
        'POST',
        `${REACT_APP_API_URL + '/update-court-type-master'}`,
        payload,
        "",
        headers
      )
        .then(res => {
          if (res?.data.status === 200) {
            successData(res);
            dispatch(updateCourtTypeMasterSuccess(res));
          } else {
            errorMSg(res);
          }
        })
        .catch(err => {
          errorMSg(err?.res);
          dispatch(updateCourtTypeMasterFailure('Something went wrong'));
          throw err;
        });
    }
  }

  export const getCourtTypeMasterListAPI = (payload, successData, errorMSg, headers = {}) => {
    return dispatch => {
      dispatch(getCourtTypeMasterListRequest());
      return request(
        'POST',
        `${REACT_APP_API_URL + '/court-type-master-list'}`,
        payload,
        "",
        headers
      )
        .then(res => {
          if (res?.data.status === 200) {
            successData(res);
            dispatch(getCourtTypeMasterListSuccess(res.data.data));
          } else {
            errorMSg(res);
          }
        })
        .catch(err => {
          errorMSg(err?.res);
          dispatch(getCourtTypeMasterListFailure('Something went wrong'));
          throw err;
        });
    };
  };