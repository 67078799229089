import React from 'react';
import { useEffect, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { clientListAPI } from 'actions/ClientRegistration/ClientRegistrationAction';
import FalconComponentCard from 'components/common/FalconComponentCard';
import { courtMasterListAPI } from 'actions/CourtMaster/courtMasterAction';
import { formChangedAction } from 'actions';
import Moment from "moment";
import {
  caseNextDateDataAction,
  getClientCaseMasterListAPI
} from 'actions/CaseNextDate/caseNextDateAction';
import { getAdvocateClientCaseMasterDetailAPI } from 'actions/AdvocateClientCase/AdvocateClientCaseAction';
import ActionModal from './ActionModal';
import {
  actionDataDetailAction,
  getActionDetailsListAPI
} from 'actions/ActionDetails/actionDetailsAction';

export const CaseNextDateDetail = () => {
  const dispatch = useDispatch();
  const [clientList, setClientList] = useState([]);
  const [courtMasterList, setCourtMasterList] = useState([]);
  const [clientCaseMasterList, setClientCaseMasterList] = useState([]);
  const selectedClientCode = localStorage.getItem('ClientCode');
  const selectedCaseNo = localStorage.getItem('CaseNo');
  const [actionDetailModal, setActionDetailModal] = useState(false);

  let token = localStorage.getItem('Token') || sessionStorage.getItem('Token');

  const headers = {
    Authorization: `Bearer ${JSON.parse(token).value}`
  };

  const resetCaseNextDateData = () => {
    dispatch(
      caseNextDateDataAction({
        clientCaseNo: '',
        clientName: '',
        mobileNo: '',
        caseNature: '',
        emailId: '',
        caseTitle: '',
        caseRegisterDate: '',
        courtLocationName: '',
        currentDate: '',
        currentCourtName: '',
        currentJudge: '',
        currentPurpose: '',
        currentAttendantAdvocate: '',
        currentCaseStatus: '',
        currentPoliceStation: '',
        currentFIRDate: '',
        currentFIRNo: '',
        newNextHearingDate: '',
        newCourtCode: '',
        newPurpose: '',
        newCourtNo: '',
        newJudge: '',
        newAdvocate: '',
        newCaseStatus: '',
        oppAdvocateName: '',
        oppAdvMob: '',
        fileNo: '',
        roomNo: '',
        almirahNo: '',
        rackNo: '',
        underSection: '',
        fileDescription: '',
        stateCode: '',
        advocateComment: '',
        currentCourtNo: '',
        clientComment: ''
      })
    );
    setClientCaseMasterList([]);
    setCourtMasterList([]);
  };

  const caseNextDateReducer = useSelector(
    state => state.rootReducer.caseNextDateReducer
  );
  var caseNextDateData = caseNextDateReducer.caseNextDateData;

  if (
    !caseNextDateReducer.caseNextDateData ||
    Object.keys(caseNextDateReducer.caseNextDateData).length <= 0
  ) {
    resetCaseNextDateData();
  }

  const caseNextDateDetailsErrorReducer = useSelector(
    state => state.rootReducer.caseNextDateDetailsErrorReducer
  );
  const caseNextDateDetailError =
    caseNextDateDetailsErrorReducer.caseNextDateDetailsError;

  const formChangedReducer = useSelector(
    state => state.rootReducer.formChangedReducer
  );
  var formChangedData = formChangedReducer.formChanged;

  useEffect(() => {
    getClientList(1);
  }, []);

  useEffect(() => {
    if (caseNextDateData.stateCode) {
      getCourtMasterList(caseNextDateData.stateCode);
    }
  }, [caseNextDateData.stateCode]);

  useEffect(() => {
    if (!selectedCaseNo) {
      resetCaseNextDateData();
    } else {
      dispatch(
        caseNextDateDataAction({
          ...caseNextDateData,
          clientCode: localStorage.getItem('ClientCode')
        })
      );
    }
  }, [selectedCaseNo]);

  const getClientList = async () => {
    const data = {
      pageNumber: 1,
      pageSize: 1,
      SearchText: '',
      EncryptedAdvocateCode: localStorage.getItem('EncryptedAdvocateCode'),
      isDropDown: true
    };

  await dispatch(
      clientListAPI(
        data,
        res => {
          let clientData = [];

          if (res.data.data.clientList.length > 0)
            res.data.data.clientList.forEach(client => {
              clientData.push({
                key: client.clientName,
                value: client.clientCode
              });
            });
          setClientList(clientData);
        },
        err => {
          setClientList([]);
          console.log(err?.data.message);
        },
        headers
      )
    );
  };

  const getAdvocateClientCaseDetail = async (clientCode, caseNo) => {
    var request = {
      EncryptedAdvocateCode: localStorage.getItem('EncryptedAdvocateCode'),
      ClientCode: clientCode ? clientCode : selectedClientCode,
      CaseNo: caseNo ? caseNo : selectedCaseNo
    };
  await dispatch(
      getAdvocateClientCaseMasterDetailAPI(
        request,
        res => {
          var caseDetail = res.data.data.caseDetail;
          dispatch(
            caseNextDateDataAction({
              ...caseNextDateData,
              emailId: caseDetail.clientEmailId,
              mobileNo: caseDetail.clientMobileNo,
              newCourtNo: caseDetail.currentCourtNo,
              newJudge: caseDetail.currentJudge,
              newAdvocate: caseDetail.currentAttendantAdvocate,
              newCaseStatus: caseDetail.currentCaseStatus,
              oppAdvMob: caseDetail.oppAdvocateMobile,
              newPurpose: caseDetail.currentPurpose,
              newCourtCode: caseDetail.currentCourtCode,
              ...caseDetail,
              clientCode: clientCode,
              clientCaseNo: caseDetail.caseNo,
              stateCode: caseDetail.stateCode
            })
          );
        },
        err => {
          toast.error(err?.data?.message || 'Error fetching client', {
            theme: 'colored',
            autoClose: 10000
          });
          dispatch(caseNextDateDataAction(undefined));
        },
        headers
      )
    );
  };

  const getClientCaseMasterList = async clientCode => {
    const data = {
      EncryptedAdvocateCode: localStorage.getItem('EncryptedAdvocateCode'),
      ClientCode: clientCode ? clientCode : selectedClientCode
    };
  await dispatch(
      getClientCaseMasterListAPI(
        data,
        res => {
          let caseMasterData = [];
          if (res.data && res.data.data.caseMasterList.length > 0) {
            res.data.data.caseMasterList.forEach(data => {
              caseMasterData.push({
                key: `${data.caseNo} --- ${data.caseTitle}`, 
                value: data.caseNo
              });
            });
            setClientCaseMasterList(caseMasterData);
          } else {
            setClientCaseMasterList([]);
          }
        },
        err => {
          setClientCaseMasterList([]);
        },
        headers
      )
    );
  };

  const getCourtMasterList = async selectStateCode => {
    const data = {
      stateCode: selectStateCode
    };
  await dispatch(
      courtMasterListAPI(
        data,
        res => {
          let courtData = [];
          if (res.data && res.data.data.courtList.length > 0) {
            res.data.data.courtList.forEach(data => {
              courtData.push({
                key: data.courtName,
                value: data.courtCode
              });
            });
            setCourtMasterList(courtData);
          } else {
            setCourtMasterList([]);
          }
        },
        err => {
          setCourtMasterList([]);
        },
        headers
      )
    );
  };

  const handleFieldChange = e => {
    if (e.target.name == 'clientCode') {
      const selectedOption = e.target.options[e.target.selectedIndex];
      const selectedKey = selectedOption.dataset.key || selectedOption.label;
      localStorage.setItem('ClientName', selectedKey);
      dispatch(
        caseNextDateDataAction({
          ...caseNextDateData,
          clientCode: e.target.value,
          clientName: selectedKey
        })
      );
      localStorage.setItem('ClientCode', e.target.value);
      localStorage.removeItem('CaseNo');
      getClientCaseMasterList(e.target.value);
    } else if (e.target.name == 'clientCaseNo') {
      dispatch(
        caseNextDateDataAction({
          ...caseNextDateData,
          clientCaseNo: e.target.value,
          clientCode: caseNextDateData.clientCode
        })
      );
      dispatch(actionDataDetailAction(undefined));
      localStorage.setItem('CaseNo', e.target.value);
      getAdvocateClientCaseDetail(caseNextDateData.clientCode, e.target.value);
      getCourtMasterList(caseNextDateData.stateCode);
      getActionDetailList(caseNextDateData.clientCode, e.target.value);
    } else {
      dispatch(
        caseNextDateDataAction({
          ...caseNextDateData,
          [e.target.name]: e.target.value
        })
      );
    }
    if (caseNextDateData) {
      dispatch(
        formChangedAction({
          ...formChangedData,
          caseNextDateAdd: true
        })
      );
    }
  };

  const getActionDetailList = async (ClientCode, caseNo) => {
    const data = {
      ClientCode: ClientCode ? ClientCode : selectedClientCode,
      CaseNo: caseNo ? caseNo : selectedCaseNo
    };
  await dispatch(
      getActionDetailsListAPI(
        data,
        res => {
          let listData = [];
          listData =
            res.data.data.length > 0 ? res.data.data.actionDetailsList : [];
          dispatch(actionDataDetailAction(listData));
        },
        err => {
          console.log(err?.data.message);
          dispatch(actionDataDetailAction(undefined));
        },
        headers
      )
    );
  };

  const handleToggleModal = () => {
    if (!selectedClientCode) {
      toast.error('Please select client', {
        theme: 'colored'
      });
    } else if (!selectedCaseNo) {
      toast.error('Please select case no', {
        theme: 'colored'
      });
    } else if (selectedCaseNo && selectedClientCode) {
      setActionDetailModal(prevState => !prevState);
    }
  };

  return (
    <>
      <ActionModal
        actionDetailModal={actionDetailModal}
        setActionDetailModal={setActionDetailModal}
      />
      <Form className="details-form micro-form" id="addCaseNextDateDetailsForm">
        <Row className="g-3 mb-2 row-content-pt5px">
          <Col sm={12} lg={12} className="no-pd-card">
            <FalconComponentCard className="farmer-card-row1">
              <div className="d-flex justify-content-between mt-2 mb-1 ms-3 me-3 align-items-center">
                <h5 className="">Add Case Next Date</h5>
                <Button variant="primary" onClick={handleToggleModal}>
                  Add Action
                </Button>
              </div>
              <FalconComponentCard.Body language="jsx">
                <Row className='ps-3'>
                  <Col className="no-pd-card" sm="12" md='4' lg="4" >
                    <Form.Group as={Row} className="mb-1">
                      <Form.Label column sm={4}>
                        Client Name<span className="text-danger">*</span>
                      </Form.Label>
                      <Col sm={8}>
                        <Form.Select
                          id="txtClientCode"
                          name="clientCode"
                          onChange={handleFieldChange}
                          value={caseNextDateData.clientCode}
                        >
                          <option value="">Select Client Name</option>
                          {clientList.map((option, index) => (
                            <option key={index} value={option.value}>
                              {option.key}
                            </option>
                          ))}
                        </Form.Select>
                        {Object.keys(caseNextDateDetailError.clientNameErr).map(
                          key => {
                            return (
                              <span className="error-message">
                                {caseNextDateDetailError.clientNameErr[key]}
                              </span>
                            );
                          }
                        )}
                      </Col>
                    </Form.Group>
                    <Form.Group
                      as={Row}
                      className="mb-1"
                      controlId="formHorizontalEmail"
                    >
                      <Form.Label column sm={4}>
                        Mobile No.
                      </Form.Label>
                      <Col sm={8}>
                        <Form.Control
                          id="txtMobileNoDetail"
                          name="mobileNo"
                          value={caseNextDateData.mobileNo}
                          placeholder="Mobile No."
                          disabled
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group
                      as={Row}
                      className="mb-1"
                      controlId="formHorizontalEmail"
                    >
                      <Form.Label column sm={4}>
                        Case Nature
                      </Form.Label>
                      <Col sm={8}>
                        <Form.Control
                          id="txtCaseNature"
                          name="caseNature"
                          value={caseNextDateData.caseNature}
                          placeholder="Case Nature"
                          disabled
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                  <Col className="no-pd-card" sm="12" md='4' lg="4" >
                    <Form.Group as={Row} className="mb-1">
                      <Form.Label column sm={4}>
                        Case No.<span className="text-danger">*</span>
                      </Form.Label>
                      <Col sm={8}>
                        <Form.Select
                          id="txtCaseId"
                          name="clientCaseNo"
                          onChange={handleFieldChange}
                          value={caseNextDateData.clientCaseNo}
                        >
                          <option value="">Select Case No</option>
                          {clientCaseMasterList.map((option, index) => (
                            <option key={index} value={option.value}>
                              {option.key}
                            </option>
                          ))}
                        </Form.Select>
                        {Object.keys(caseNextDateDetailError.caseNoErr).map(
                          key => {
                            return (
                              <span className="error-message">
                                {caseNextDateDetailError.caseNoErr[key]}
                              </span>
                            );
                          }
                        )}
                      </Col>
                    </Form.Group>
                    <Form.Group
                      as={Row}
                      className="mb-1"
                      controlId="formHorizontalEmail"
                    >
                      <Form.Label column sm={4}>
                        Email ID
                      </Form.Label>
                      <Col sm={8}>
                        <Form.Control
                          id="txtEmail"
                          name="emailId"
                          value={caseNextDateData.emailId}
                          placeholder="Email ID"
                          disabled
                        />
                      </Col>
                    </Form.Group>

                    <Form.Group
                      as={Row}
                      className="mb-1"
                      controlId="formHorizontalEmail"
                    >
                      <Form.Label column sm={4}>
                        Case Title
                      </Form.Label>
                      <Col sm={8}>
                        <Form.Control
                          id="txtCaseTitle"
                          name="caseTitle"
                          value={caseNextDateData.caseTitle}
                          placeholder="Case Title"
                          disabled
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                  <Col className="no-pd-card" sm="12" md='4' lg="4" >
                    <Form.Group
                      as={Row}
                      className="mb-1"
                      controlId="formHorizontalEmail"
                    >
                      <Form.Label column sm={4}>
                        Case Register Date
                      </Form.Label>
                      <Col sm={8}>
                        <Form.Control
                          id="txtCaseRegisterDate"
                          name="caseRegisterDate"
                         value={caseNextDateData.caseRegisterDate ? Moment(caseNextDateData.caseRegisterDate).format("DD-MM-YYYY") : ""}  
                          placeholder="Case Register Date"
                           disabled
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group
                      as={Row}
                      className="mb-1"
                      controlId="formHorizontalEmail"
                    >
                      <Form.Label column sm={4}>
                        Court Name
                      </Form.Label>
                      <Col sm={8}>
                        <Form.Control
                          id="txtCourtName"
                          name="courtLocationName"
                          value={caseNextDateData.courtLocationName}
                          placeholder="Court Name"
                          disabled
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group
                      as={Row}
                      className="mb-1"
                      controlId="formHorizontalEmail"
                    >
                      <Form.Label column sm={4}>
                        Under Section
                      </Form.Label>
                      <Col sm={8}>
                        <Form.Control
                          id="txtUnderSection"
                          name="underSection"
                          value={caseNextDateData.underSection}
                          placeholder="Under Section"
                          disabled
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
              </FalconComponentCard.Body>
            </FalconComponentCard>
          </Col>
        </Row>
        <Row className="g-3 mb-2 row-content-pt5px">
          <Col sm={4} lg={4} className="no-pd-card">
            <FalconComponentCard className="farmer-card-row1">
              <FalconComponentCard.Header
                title="Current Date Detail"
                light={false}
              />
              <FalconComponentCard.Body language="jsx">
                <Row>
                  <Col className="me-3 ms-3">
                    <Form.Group
                      as={Row}
                      className="mb-1"
                      controlId="formHorizontalEmail"
                    >
                      <Form.Label column sm={4}>
                        Current Date
                      </Form.Label>
                      <Col sm={8}>
                        <Form.Control
                          id="txtCurrentDate"
                          placeholder="Current Date"
                          name="currentDate"
                          value={caseNextDateData.currentDate ? Moment(caseNextDateData.currentDate).format("DD-MM-YYYY") : ""}  
                          disabled
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group
                      as={Row}
                      className="mb-1"
                      controlId="formHorizontalEmail"
                    >
                      <Form.Label column sm={4}>
                        Current Court
                      </Form.Label>
                      <Col sm={8}>
                        <Form.Control
                          id="txtCurrentCourt"
                          name="currentCourtName"
                          placeholder="Current Court"
                          value={caseNextDateData.currentCourtName}
                          disabled
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group
                      as={Row}
                      className="mb-1"
                      controlId="formHorizontalEmail"
                    >
                      <Form.Label column sm={4}>
                        Current Judge
                      </Form.Label>
                      <Col sm={8}>
                        <Form.Control
                          id="txtCurrentJudge"
                          name="currentJudge"
                          placeholder="Current Judge"
                          value={caseNextDateData.currentJudge}
                          disabled
                        />
                      </Col>
                    </Form.Group>

                    <Form.Group
                      as={Row}
                      className="mb-1"
                      controlId="formHorizontalEmail"
                    >
                      <Form.Label column sm={4}>
                        Current Court No.
                      </Form.Label>
                      <Col sm={8}>
                        <Form.Control
                          id="txtCurrentCourtNo"
                          name="currentCourtNo"
                          placeholder="Current Court No"
                          value={caseNextDateData.currentCourtNo}
                          disabled
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group
                      as={Row}
                      className="mb-1"
                      controlId="formHorizontalEmail"
                    >
                      <Form.Label column sm={4}>
                        Current Advocate
                      </Form.Label>
                      <Col sm={8}>
                        <Form.Control
                          id="txtCurrentAdvocate"
                          name="currentAttendantAdvocate"
                          placeholder="Current Advocate"
                          value={caseNextDateData.currentAttendantAdvocate}
                          disabled
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group
                      as={Row}
                      className="mb-1"
                      controlId="formHorizontalEmail"
                    >
                      <Form.Label column sm={4}>
                        Current Purpose
                      </Form.Label>
                      <Col sm={8}>
                        <Form.Control
                          id="txtCurrentPurpose"
                          name="currentPurpose"
                          placeholder="Current Purpose"
                          value={caseNextDateData.currentPurpose}
                          disabled
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group
                      as={Row}
                      className="mb-1"
                      controlId="formHorizontalEmail"
                    >
                      <Form.Label column sm={4}>
                        Case Status
                      </Form.Label>
                      <Col sm={8}>
                        <Form.Control
                          id="txtCurrentCaseStatus"
                          name="currentCaseStatus"
                          placeholder="Case Status"
                          value={caseNextDateData.currentCaseStatus}
                          disabled
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group
                      as={Row}
                      className="mb-1"
                      controlId="formHorizontalEmail"
                    >
                      <Form.Label column sm={4}>
                        Police Station
                      </Form.Label>
                      <Col sm={8}>
                        <Form.Control
                          id="txtCurrentPoliceStation"
                          name="currentPoliceStation"
                          placeholder="Police Station"
                          value={caseNextDateData.currentPoliceStation}
                          disabled
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group
                      as={Row}
                      className="mb-1"
                      controlId="formHorizontalEmail"
                    >
                      <Form.Label column sm={4}>
                        FIR Date
                      </Form.Label>
                      <Col sm={8}>
                        <Form.Control
                          placeholder="FIR Date"
                          id="dtFIRDate"
                          name="currentFIRDate"
                          value={caseNextDateData.currentFIRDate ? Moment(caseNextDateData.currentFIRDate).format("DD-MM-YYYY") : ""}  
                          disabled
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group
                      as={Row}
                      className="mb-1"
                      controlId="formHorizontalEmail"
                    >
                      <Form.Label column sm={4}>
                        FIR No.
                      </Form.Label>
                      <Col sm={8}>
                        <Form.Control
                          id="txtCurrentFirNo"
                          name="currentFIRNo"
                          placeholder="FIR No" 
                          value={caseNextDateData.currentFIRNo}
                          disabled
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
              </FalconComponentCard.Body>
            </FalconComponentCard>
          </Col>
          <Col sm={4} lg={4} className="no-pd-card">
            <FalconComponentCard className="farmer-card-row1">
              <FalconComponentCard.Header
                title="New Date Details"
                light={false}
              />
              <FalconComponentCard.Body language="jsx">
                <Row>
                  <Col className="me-3 ms-3">
                    <Form.Group
                      as={Row}
                      className="mb-1"
                      controlId="formHorizontalEmail"
                    >
                      <Form.Label column sm={4}>
                        New Date <span className="text-danger">*</span>
                      </Form.Label>
                      <Col sm={8}>
                        <Form.Control
                          id="txtNewNextHearingDate"
                          type="date"
                          name="newNextHearingDate"
                          onChange={handleFieldChange}
                          value={caseNextDateData.newNextHearingDate}
                        />
                        {Object.keys(
                          caseNextDateDetailError.newNextHearingDateErr
                        ).map(key => {
                          return (
                            <span className="error-message">
                              {
                                caseNextDateDetailError.newNextHearingDateErr[
                                  key
                                ]
                              }
                            </span>
                          );
                        })}
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-1">
                      <Form.Label column sm={4}>
                        New Court<span className="text-danger">*</span>
                      </Form.Label>
                      <Col sm={8}>
                        <Form.Select
                          id="txtNewCourtCode"
                          name="newCourtCode"
                          onChange={handleFieldChange}
                          value={caseNextDateData.newCourtCode}
                        >
                          <option value="">Select Court Name</option>
                          {courtMasterList.map((option, index) => (
                            <option key={index} value={option.value}>
                              {option.key}
                            </option>
                          ))}
                        </Form.Select>
                        {Object.keys(
                          caseNextDateDetailError.newCourtNameErr
                        ).map(key => {
                          return (
                            <span className="error-message">
                              {caseNextDateDetailError.newCourtNameErr[key]}
                            </span>
                          );
                        })}
                      </Col>
                    </Form.Group>
                    <Form.Group
                      as={Row}
                      className="mb-1"
                      controlId="formHorizontalEmail"
                    >
                      <Form.Label column sm={4}>
                        New Judge
                      </Form.Label>
                      <Col sm={8}>
                        <Form.Control
                          id="txtNewJudge"
                          name="newJudge"
                          maxLength={50}
                          onChange={handleFieldChange}
                          value={caseNextDateData.newJudge}
                          placeholder="New Judge"
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group
                      as={Row}
                      className="mb-1"
                      controlId="formHorizontalEmail"
                    >
                      <Form.Label column sm={4}>
                        New Court No.
                      </Form.Label>
                      <Col sm={8}>
                        <Form.Control
                          id="txtNewCourtNo"
                          name="newCourtNo"
                          maxLength={5}
                          onChange={handleFieldChange}
                          value={caseNextDateData.newCourtNo}
                          placeholder="New Court No"
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group
                      as={Row}
                      className="mb-1"
                      controlId="formHorizontalEmail"
                    >
                      <Form.Label column sm={4}>
                        New Advocate
                      </Form.Label>
                      <Col sm={8}>
                        <Form.Control
                          id="txtNewAdvocate"
                          name="newAdvocate"
                          maxLength={50}
                          onChange={handleFieldChange}
                          value={caseNextDateData.newAdvocate}
                          placeholder="New Attendant Advocate"
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-1">
                      <Form.Label column sm={4}>
                        New Purpose
                      </Form.Label>
                      <Col sm={8}>
                        <Form.Select
                          id="txtNewPurpose"
                          name="newPurpose"
                          onChange={handleFieldChange}
                          value={caseNextDateData.newPurpose}
                        >
                        <option value="">Select </option>
                        <option value="Admission">Admission</option>
                        <option value="Appearance">Appearance </option>
                        <option value="Argument">Argument</option>
                        <option value="Charge/Issue">Charge/Issue </option>
                        <option value="Evidence">Evidence </option>
                        <option value="Misc">Misc</option>
                        <option value="Rejoinder">Rejoinder </option>
                        <option value="Reply">Reply </option>
                        </Form.Select>
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-1">
                      <Form.Label column sm={4}>
                        Case Status
                      </Form.Label>
                      <Col sm={8}>
                        <Form.Select
                          id="txtNewCaseStatus"
                          name="newCaseStatus"
                          onChange={handleFieldChange}
                          value={caseNextDateData.newCaseStatus}
                        >
                          <option value="ONGOING" selected>
                            ONGOING{' '}
                          </option>
                          <option value="WON">WON </option>
                          <option value="LOST">LOST </option>
                          <option value="CLOSED">CLOSED </option>
                          <option value="DISPOSED">DISPOSED </option>
                        </Form.Select>
                      </Col>
                    </Form.Group>
                    <Form.Group
                      as={Row}
                      className="mb-1"
                      controlId="formHorizontalEmail"
                    >
                      <Form.Label column sm={4}>
                        Opp Adv Name
                      </Form.Label>
                      <Col sm={8}>
                        <Form.Control
                          id="txtOppAdvName"
                          name="oppAdvocateName"
                          maxLength={50}
                          onChange={handleFieldChange}
                          value={caseNextDateData.oppAdvocateName}
                          placeholder="Opp Adv Name"
                        />
                      </Col>
                    </Form.Group>

                    <Form.Group
                      as={Row}
                      className="mb-1"
                      controlId="formHorizontalEmail"
                    >
                      <Form.Label column sm={4}>
                        Opp Adv Mobile
                      </Form.Label>
                      <Col sm={8}>
                        <Form.Control
                          id="txtOppAdvMob"
                          name="oppAdvMob"
                          maxLength={10}
                          onChange={handleFieldChange}
                          value={caseNextDateData.oppAdvMob}
                          placeholder="Opp Adv Mob"
                        />
                        {Object.keys(
                          caseNextDateDetailError.newAdvocateMobileNoErr
                        ).map(key => {
                          return (
                            <span className="error-message">
                              {
                                caseNextDateDetailError.newAdvocateMobileNoErr[
                                  key
                                ]
                              }
                            </span>
                          );
                        })}
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
              </FalconComponentCard.Body>
            </FalconComponentCard>
          </Col>
          <Col sm={4} lg={4} className="no-pd-card">
            <FalconComponentCard className="farmer-card-row1">
              <FalconComponentCard.Header
                title="File Placement"
                light={false}
              />
              <FalconComponentCard.Body language="jsx">
                <Row>
                  <Col className="me-3 ms-3">
                    <Form.Group
                      as={Row}
                      className="mb-1"
                      controlId="formHorizontalEmail"
                    >
                      <Form.Label column sm={4}>
                        File No.
                      </Form.Label>
                      <Col sm={8}>
                        <Form.Control
                          id="txtFileNo"
                          name="fileNo"
                          maxLength={20}
                          onChange={handleFieldChange}
                          value={caseNextDateData.fileNo}
                          placeholder="File No"
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group
                      as={Row}
                      className="mb-1"
                      controlId="formHorizontalEmail"
                    >
                      <Form.Label column sm={4}>
                        Room No.
                      </Form.Label>
                      <Col sm={8}>
                        <Form.Control
                          id="txtRoomNo"
                          name="roomNo"
                          maxLength={5}
                          onChange={handleFieldChange}
                          value={caseNextDateData.roomNo}
                          placeholder="Room No"
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group
                      as={Row}
                      className="mb-1"
                      controlId="formHorizontalEmail"
                    >
                      <Form.Label column sm={4}>
                        Almirah No.
                      </Form.Label>
                      <Col sm={8}>
                        <Form.Control
                          id="txtAlmirahNo"
                          name="almirahNo"
                          maxLength={5}
                          onChange={handleFieldChange}
                          value={caseNextDateData.almirahNo}
                          placeholder="Almirah No"
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group
                      as={Row}
                      className="mb-1"
                      controlId="formHorizontalEmail"
                    >
                      <Form.Label column sm={4}>
                        Rack No.
                      </Form.Label>
                      <Col sm={8}>
                        <Form.Control
                          id="txtRackNo"
                          maxLength={50}
                          name="rackNo"
                          placeholder="Rack No"
                          value={caseNextDateData.rackNo}
                          onChange={handleFieldChange}
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group
                      as={Row}
                      className="mb-1"
                      controlId="formHorizontalEmail"
                    >
                      <Form.Label column sm={4}>
                        Description
                      </Form.Label>
                      <Col sm={8}>
                        <Form.Control
                          id="txtDescription"
                          name="fileDescription"
                          as="textarea"
                          maxLength={50}
                          onChange={handleFieldChange}
                          value={caseNextDateData.fileDescription}
                          placeholder="Description"
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
              </FalconComponentCard.Body>
            </FalconComponentCard>
          </Col>
        </Row>
        <Row className="g-3 mb-2 row-content-pt5px">
          <Col sm={12} lg={12} className="no-pd-card">
            <FalconComponentCard className="farmer-card-row1">
              <FalconComponentCard.Header title="Comments" light={false} />
              <FalconComponentCard.Body language="jsx">
                <Row>
                  <Col className="me-3 ms-3">
                    <Form.Group
                      as={Row}
                      className="mb-1"
                      controlId="formHorizontalEmail"
                    >
                      <Form.Label column sm={2}>
                        Advocate Comment
                      </Form.Label>
                      <Col sm={10}>
                        <Form.Control
                          id="txtAdvocateComment"
                          as="textarea"
                          name="advocateComment"
                          maxLength={200}
                          onChange={handleFieldChange}
                          value={caseNextDateData.advocateComment}
                          placeholder="Advocate Comment maximum size 200"
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group
                      as={Row}
                      className="mb-1"
                      controlId="formHorizontalEmail"
                    >
                      <Form.Label column sm={2}>
                        Client Comment
                      </Form.Label>
                      <Col sm={10}>
                        <Form.Control
                          id="txtClientComment"
                          as="textarea"
                          maxLength={200}
                          onChange={handleFieldChange}
                          name="clientComment"
                          value={caseNextDateData.clientComment}
                          placeholder="Client Comment send to Client EMail"
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
              </FalconComponentCard.Body>
            </FalconComponentCard>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default CaseNextDateDetail;