import { GET_LITIGATION_TYPES_MASTER_LIST_REQUEST,
    GET_LITIGATION_TYPES_MASTER_LIST_SUCCESS,
    GET_LITIGATION_TYPES_MASTER_LIST_FAILURE

 } from "actions/LitigationTypesMaster/litigationTypesMasterAction";
 const initialState = {
    litigationList: [],
  };

  export default function litigationTypesMasterReducer(
    state = initialState,
    action
  ) {
    switch (action.type) {
      case GET_LITIGATION_TYPES_MASTER_LIST_REQUEST:
        return {
          ...state
        };
      case GET_LITIGATION_TYPES_MASTER_LIST_SUCCESS:
        return {
          ...state,
          litigationList: action.payload
        };
      case GET_LITIGATION_TYPES_MASTER_LIST_FAILURE:
        return {
          ...state,
          error: action.payload
        };
      default:
        return state;
    }
  }